import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    TextField,
    NativeSelect,
    Grid
} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 250,
      maxHeigth: 250,
      minWidth:  400,
      maxWidth:  400
  },
  formControl: {
      marginTop: "25px",
  },
  
})

class FormTaxaImpactante extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            descricao: this.props.data ? this.props.data.descricao || "" : "",
            porcentagem: this.props.data ? this.props.data.porcentagem || 0 : 0,
            status: this.props.data.status,
            impacto: this.props.data ? this.props.data.porcentagem > 0 ? 1 : -1 : 1,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeImpacto = this.handleChangeImpacto.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    handleChangeImpacto(e){
        let value = this.state.porcentagem
        if(value > 0 && e.target.value < 0){
            value *= -1
        }else if(value < 0 && e.target.value > 0){
            value *= -1
        }
        this.setState({
            impacto: e.target.value,
            porcentagem: value
        })
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth >
                <TextField
                    id="descricao"
                    label="Descrição"
                    name="descricao"
                    fullWidth
                    type="text"
                    value={this.state.descricao}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
           <FormControl fullWidth className={classes.formControl}>
               <Grid container>
                   <Grid item xs={7} style={{marginRight: 10}}>
                    <TextField
                        id="porcentagem"
                        label="Porcentagem"
                        name="porcentagem"
                        fullWidth
                        type="number"
                        value={this.state.porcentagem}
                        onChange={this.handleChange}
                        >
                    </TextField>
                   </Grid>
                   <Grid item xs={4} fullWidth>
                        <FormControl>
                            <InputLabel impacto required>
                                Impacto
                            </InputLabel>
                            <Select
                                value={this.state.impacto}
                                onChange={this.handleChangeImpacto}
                                label="Impacto"
                                required
                                fullWidth
                                name="impacto"
                               >
                                <MenuItem value={1}>Positivo</MenuItem>
                                <MenuItem value={-1}>Negativo</MenuItem>
                            </Select>
                        </FormControl>
                   </Grid>
               </Grid>
           </FormControl> 
            
           <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <NativeSelect
                    fullWidth
                    value={this.state.status}
                    name='status'
                    onChange={this.handleChange}
                    >
                    <option value={1}>Ativo</option>
                    <option value={0}>Inativo</option>
                </NativeSelect>
            </FormControl> 
           
         </form>
    )
        
    }
}

export default withStyles(styles)(FormTaxaImpactante)