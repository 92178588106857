import React from 'react'
import {
  ListItem, Checkbox, ListItemText,
  ListItemSecondaryAction, TextField, List, Grid, Typography, Container, FormControlLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import { makeProgressDialog } from '../dialogs/DialogRefInterface';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { centroProdutorService } from '../../services/centro_produtor_service'
import { folhaService } from '../../services/folha_service';
import { formatName } from '../../util/utils'
import CustomDatePicker from '../default/CustomDatePicker';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeight: '800px',
    maxHeight: '800px',
    minWidht: '800px',
    maxWidht: '800px'
  },
})

function createValue(nome, numero, data_inicial) {
  return { nome, numero, data_inicial }
}

class FormFolha extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open || false,
      selected: [],
      bloqued: true,
      date: "",
      all: false,
      rows: []
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeAll = this.handleChangeAll.bind(this)
    this.handleChangeBloqued = this.handleChangeBloqued.bind(this)
    this.handleChangeRowDate = this.handleChangeRowDate.bind(this)
    this.handleMinimize = this.handleMinimize.bind(this)
    this.handleGetResponse = this.handleGetResponse.bind(this)
    this.handleCreateResponse = this.handleCreateResponse.bind(this)
  }

  componentDidMount() {
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    const strDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + date
    this.setState({
      date: strDate
    });

    if (this.state.bloqued) {
      this.state.rows.map(value => {
        value.data_inicial = strDate
      })
    }
    centroProdutorService.getCentroProdutores({ produtor_id: -1 }, this.handleGetResponse)
  }

  handleGetResponse(response) {
    if (validateShowResponseToast(response, "", false)) {
      let lRows = []
      response.data.data.map(value => {
        lRows.push(createValue(value.produtor.nome, value.numero, ""))
      })
      this.setState({
        rows: lRows.map(lr => { 
          lr.data_inicial = this.state.date
          return lr
        })
      })
    }
  }

  handleCreateResponse(response) {
    if (validateShowResponseToast(response)) {
      if (this.props.handleFormState) {
        this.props.handleFormState(response.data)
      }
    }
  }

  action() {
    let selectedRows = this.state.rows.filter(rw => {
      if (this.state.selected.indexOf(rw.numero) > -1) {
        return rw
      }
    });

    folhaService.createFolhas({ data: selectedRows }, this.handleCreateResponse)
  }

  handleCancel(e) {
    this.setState({ open: false, all: false, bloqued: true, selected: [] })
  }

  handleMinimize(e) {
    this.setState({ open: false })
  }

  open(e) {
    this.setState({ open: true })
  }

  handleSave(e) {
    makeProgressDialog(undefined, "Salvando...", undefined).current.open()
  }

  handleChangeDate(e) {
    this.setState({ date: e.target.value })
    if (this.state.bloqued) {
      this.state.rows.map(value => {
        value.data_inicial = e.target.value
      })
      this.forceUpdate()
    }
  }

  handleChangeAll(e) {
    this.setState({ all: e.target.checked })
    if (e.target.checked) {
      const newSelecteds = this.state.rows.map(n => n.numero);
      this.setState({ selected: newSelecteds })
      return;
    }
    this.setState({ selected: [] });
  }

  handleChangeBloqued(e) {
    this.setState({ bloqued: e.target.checked })
    if (e.target.checked) {
      this.state.rows.map(value => {
        value.data_inicial = this.state.date
      })
      this.forceUpdate()
    }
  }

  handleChangeRowDate(e, value) {
    value.data_inicial = e.target.value
    this.forceUpdate()
  }

  handleToggle(event, value) {
    event.stopPropagation()
    const selectedIndex = this.state.selected.indexOf(value);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  render() {
    const { classes } = this.props
    const style = { marginBottom: 20, minWidth: 140, maxWidth: 140 }
    const headStyle = { checkbox: { marginBottom: "30px", marginTop: "20px" } }
    return (
      <form className={classes.form} noValidate onSubmit={e => { e.preventDefault() }}>
        <Container>
          <FormControlLabel
            control={
              <Checkbox checked={true}
                checked={this.state.all}
                onChange={this.handleChangeAll}
                style={{ ...headStyle.checkbox, marginLeft: '15px' }} />}
            label="Selecionar Todos"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.bloqued}
                onChange={this.handleChangeBloqued}
                style={{ ...headStyle.checkbox, marginLeft: '30px' }} />}
            label="Bloquear"
          />
          <CustomDatePicker
            disableToolbar
            fullWidth
            label="Data de Abertura"
            variant="inline"
            format="DD/MM/YYYY"
            name="date"
            value={this.state.date}
            style={{ ...headStyle.checkbox, marginLeft: '30px', maxWidth: 160 }}
            onChange={this.handleChangeDate}
          />
         
        </Container>
        <Grid item xs={12}>
          <Grid container justify="flex-start" style={{ marginLeft: "10px", color: "blue" }}>
            {["Produtor", "Centro Produtor", "Data Início"].map(value => (
              <Grid key={value} item xs={3} style={{ marginLeft: "40px" }}>
                <Typography>
                  {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Container>
          <List> {this.state.rows.map(value => {
            const labelId = `checkbox-list-label-${value.numero}`;
            return (
              <ListItem
                style={style}
                key={value.numero}
                role={undefined} button
                onClick={(e) => this.handleToggle(e, value.numero)}>
                <Checkbox
                  edge="start"
                  checked={this.state.selected.indexOf(value.numero) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                <ListItemText id={labelId} primary={`${formatName(value.nome)}`} />
                <ListItemText id={labelId} primary={`${value.numero}`} style={{ marginLeft: "220px" }} />
                <ListItemSecondaryAction>
                  <CustomDatePicker
                    disableToolbar
                    fullWidth
                    label="Data de Abertura"
                    variant="inline"
                    format="DD/MM/YYYY"
                    disabled={this.state.bloqued}
                    value={value.data_inicial}
                    style={style}
                    onChange={(e) => { this.handleChangeRowDate(e, value) }}
                  />
                  
                </ListItemSecondaryAction>
              </ListItem>

            );
          })}
          </List>
        </Container>
      </form>)

  }


}

export default withStyles(styles)(FormFolha)