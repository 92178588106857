import React from 'react'
import {
    AttachFileRounded,
    ListRounded,
    EditRounded,
} from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { headRowsFormAnalises, headRowsFormAnalisesSimple } from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import {
    makeActionFormDialog,
    makeProgressDialog,
    closeProgressDialog,
} from '../dialogs/DialogRefInterface'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import Paginate from '../table/Paginate'
import { analiseService } from '../../services/analise_service'
import { makeAnaliseFromTxt, makeAnaliseByDescription } from './util/analise_utils'
import FormEditAnalise from './FormEditAnalise'
import { centroProdutorService } from '../../services/centro_produtor_service'
import { toast } from 'react-toastify'
import { NativeSelect, Grid, InputLabel, FormControl } from '@material-ui/core';
import string_similarity from 'string-similarity'
import CustomDatePicker from '../default/CustomDatePicker'

class TableFormAnalise extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
            headRows: headRowsFormAnalisesSimple,
            data: [],

            data_analise: moment().format('YYYY-MM-DD'),
            descricao: "",
            compara: 1
        }

        this.handleGetFilters = this.handleGetFilters.bind(this)
        this.handleGetCPResponse = this.handleGetCPResponse.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOnLoadFile = this.handleOnLoadFile.bind(this)
        this.handleCreateResponse = this.handleCreateResponse.bind(this)

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCompara = this.handleChangeCompara.bind(this)
        this.handleChangeDataAnalise = this.handleChangeDataAnalise.bind(this)

        this.fileReader = null;
    }

    // Necessario para o acesso pelo ObjectListDialog
    action() {
        let requestData = { data: this.state.paginate.rows, data_analise: this.state.data_analise, descricao: this.state.descricao }
        analiseService.createAnalise(requestData, this.handleCreateResponse)
    }

    compare = () => {
        const data = this.state.data
        const rows = this.state.paginate.rows.map(row => {
            row.numero_cp = 0
            return row
        })
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < rows.length; j++) {
                if (this.state.compara == 1) {
                    if (parseInt(rows[j].filial) > 0 && parseInt(rows[j].filial) === parseInt(data[i].numero)) {
                        rows[j].numero_cp = data[i].numero
                        rows[j].nome_produtor = data[i].nome_produtor
                    } else if (parseInt(rows[j].produtor) === parseInt(data[i].numero)) {
                        rows[j].numero_cp = data[i].numero
                        rows[j].nome_produtor = data[i].nome_produtor
                    }
                } else {
                    if (string_similarity.compareTwoStrings(rows[j].cpf_produtor,
                                                            data[i].produtor.cpf) > 0.85) {
                        rows[j].numero_cp = data[i].numero
                        rows[j].nome_produtor = data[i].nome_produtor
                    }

                }
            }
        }
        this.state.paginate.setAttribute('rows', rows)
    }

    componentWillMount() {
        this.setState({
            data_analise: moment().format('YYYY-MM-DD'),
            descricao: `Analise de ${moment(this.state.data_analise).format('DD/MM/YYYY')}`
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeCompara(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            this.compare()
        });
    }

    handleChangeDataAnalise(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            descricao: `Analise de ${moment(value).format('DD/MM/YYYY')}`
        });

    }

    handleForceUpdate = () => {
        this.forceUpdate()
        if (this.state.paginate.objectTableRef.current) {
            this.state.paginate.objectTableRef.current.updateTable()
        }
    }

    handleOnLoadFile(e) {
        const content = this.fileReader.result
        var lines = content.split(/[\r\n]+/g)
        let cols = []
        let cont = 0
        let analises = []
        lines.forEach(function (line) {
            if (cont > 0) {
                const values = line.split(";")
                let analise = makeAnaliseByDescription(values, cols)
                if (analise.produtor) {
                    let dataResultado = analise.data_resultado.split("\/")
                    analise.data_resultado = `${dataResultado[2]}-${dataResultado[1]}-${dataResultado[0]}`
                    analises.push(analise)
                }
            }else{
                cols = line.split(';')
            }
            cont++
        });
        if (analises.length > 0) {
            this.state.paginate.setAttribute('rows', analises)
            this.handleChangeDataAnalise({ target: { name: 'data_analise', value: analises[0].data_resultado } })
        }
        centroProdutorService.getCentroProdutores({ produtor_id: -1 }, this.handleGetCPResponse)
    }

    handleInputChange(e) {
        e.stopPropagation()
        e.preventDefault()
        var file = e.target.files[0]
        if (file) {
            this.fileReader = new FileReader()
            this.fileReader.onloadend = this.handleOnLoadFile
            this.fileReader.onerror = (e) => {
                toast.error("Arquivo inválido")
            }
            this.fileReader.readAsText(file, 'ISO-8859-1')
        } else {
            toast.error("Arquivo inválido")
        }
    }

    selectedToolActions = []

    toolActions = [
        {
            title: 'Subir Análise',
            label: 'Subir Análise',
            MaterialIcon: AttachFileRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.refs.fileUploader.click()
            },
        },
        {
            title: "Ver",
            label: 'Ver',
            MaterialIcon: ListRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if (this.state.headRows === headRowsFormAnalises) {
                    this.setState({
                        headRows: headRowsFormAnalisesSimple,

                    })
                } else {
                    this.setState({
                        headRows: headRowsFormAnalises,
                    })
                }
            },
        },
    ]

    actions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormEditAnalise, "Editar Análise", undefined, selected, "Salvar", (data) => {
                    selected.numero_cp = data.numero_cp
                    this.state.paginate.updateRow('nome_produtor', selected)
                }).current.open()
            },
        },
    ]

    handleCreateResponse(response) {
        const total = response ? response.data.total || 0 : 0
        const salvos = response ? response.data.salvos || 0 : 0
        if (validateShowResponseToast(response, "Análise", true, true, `${salvos} de ${total} Analises Salvas`)) {

        }
    }

    handleGetCPResponse(response) {
        makeProgressDialog(undefined, "Aguarde...").current.open()
        if (validateShowResponseToast(response)) {
            this.setState({
                data: response.data.data
            }, () => {
                this.compare()
            })
        }
        closeProgressDialog()
    }

    handleGetFilters(filters) {
        makeProgressDialog().current.open()
        this.state.paginate.setAttribute('isNew', true)

    }


    render() {
        return (
            <div>
                <input
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: "none" }}
                    accept=".csv"
                    onChange={this.handleInputChange}
                />
                <Grid container justify="flex-start">
                    <CustomDatePicker
                        disableToolbar
                        label="Data da Análise"
                        variant="inline"
                        format="DD/MM/YYYY"
                        name="data_analise"
                        value={this.state.data_analise}
                        onChange={this.handleChange}
                    />
                    <TextField
                        style={{ marginLeft: '20px' }}
                        id="descricao"
                        label="Descricao"
                        type="text"
                        name="descricao"
                        value={this.state.descricao}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl style={{ marginLeft: '20px' }}>
                        <InputLabel htmlFor="">Comparar</InputLabel>
                        <NativeSelect
                            value={this.state.compara}
                            name='compara'
                            onChange={this.handleChangeCompara}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <option value={0}>CPF</option>
                            <option value={1}>Número</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <ObjectTable
                    rowId='nome_produtor'
                    tableName='Análises'
                    headRows={this.state.headRows}
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={40}
                    rowsOptions={[20, 40, 80]}

                    ref={this.state.paginate.objectTableRef}
                    rows={this.state.paginate.rows}
                    count={this.state.paginate.count}
                    handleLoadPage={this.state.paginate.handleLoadPage}
                    perPage={this.state.paginate.perPage}
                    from={this.state.paginate.from}
                    to={this.state.paginate.to}
                />
            </div>
        )
    }
}

export default TableFormAnalise