import jsPDF, { makeTable } from './jspdf_override'
import moment from 'moment'
import { formatDecimal } from '../../util/utils';
import { calculateMediaGeometrica } from '../../util/relatorio_util';

const defaultParams = {
	initialLine: 0,

	smallVerticalSpace: 2,
	mediumVerticalSpace: 5,
	bigVerticalSpace: 10,
	
	lineVerticalSapace: 2,
	lineHorizontalSpace: 2,

	smallHorizontalSpace: 2,
	mediumHorizontalSpace: 5,
	bigHorizontalSpace: 10,

	tableSize: 20,
	smallFont: 8,
	mediumFont: 12,
	bigFont: 24,
	fontColorPrimary: [0,0,0],
	fontColorSecondary: [0,0,255],
	fontColorTertiary: [255, 0, 0],
	
	headerTitle: "LATICÍNIOS PALMITAL LTDA", 
	subtitle: 'PROGRAMA NACIONAL DE QUALIDADE DO LEITE',
    labelFornecedor: 'Fornecedor :',
	labelMatricula: 'Matrícula Nº :',
}

export function fillCols(cols, subCols){
    let retCols = cols.map(col => {
        return {content: col.content, colSpan: col.colSpan, styles: {halign: 'center', fillColor: [22, 160, 133]}}
    })
    return [
        retCols,
        subCols,
    ]
}

export const makeCols = (gordura=3.4, proteina=3.2, esd=8.4, ccs=500, ufc_curva=300) => {
    return [
        {content: ``, colSpan: 1,}, 
        {content: `Gordura>${formatDecimal(gordura)}%`, colSpan: 2}, 
        {content: `Proteina>${formatDecimal(proteina)}%`, colSpan: 2},
        {content: `Criosc.`, colSpan: 1},
        {content: `Nul`, colSpan: 1},
        {content: `ESD>${formatDecimal(esd)}%`, colSpan: 1},
        {content: `CCS<${formatDecimal(ccs, 0)}`, colSpan: 2},
        {content: `CBT<${formatDecimal(ufc_curva, 0)}`, colSpan: 2},
        {content: ``, colSpan: 1}, 

    ]
}

export const makeSubCols = () => {
    return ['Mês', 'Result.', 'Bônus', 'Result.', 'Bônus', '(ºH)', '(mg/dl)', 'Result.', 'Result.', 'Bônus', 'Result.', 'Bônus', 'Total']
}

function formatValues(original, modificado, decimal=2) {
    if(modificado === null || modificado === undefined) { return formatDecimal(original, decimal)}
    if(original !== modificado) return `${formatDecimal(original, decimal)}->${formatDecimal(modificado, decimal)}`
    return formatDecimal(original, decimal)
}

export function makeRows(analises){
    let retRows = []
    let mediasGeometricas = ["Média Geométrica", 1, '----', 1, '----', 1, 1, 1, 1, '----', 1, '----', '----']
    analises.map(analise => {
        console.log('teste', analise)
        let bonGordura, bonProteina, bonCCS, bonCBT
        let gorduraModificado, proteinaModificado, ccsModificado, cbtModificado
        analise.bonificacoes.map(bon => {
            if(bon.elemento === 'ccs'){
                bonCCS = bon.valor_bonus
                ccsModificado = bon.taxa_elemento
            }else if(bon.elemento === 'ufc_curva'){
                bonCBT = bon.valor_bonus
                cbtModificado = bon.taxa_elemento
            }else if(bon.elemento === 'proteina'){
                bonProteina = bon.valor_bonus
                proteinaModificado = bon.taxa_elemento
            }
            else if(bon.elemento === 'gordura'){
                bonGordura = bon.valor_bonus
                gorduraModificado = bon.taxa_elemento
            }
        })
        mediasGeometricas[1] *= Number(analise.gordura)
        mediasGeometricas[3] *= Number(analise.proteina)
        mediasGeometricas[5] *= Number(analise.crioscopia)
        mediasGeometricas[6] *= Number(analise.urea)
        mediasGeometricas[7] *= Number(analise.esd)
        mediasGeometricas[8] *= Number(analise.ccs)
        mediasGeometricas[10] *= Number(analise.ufc_curva)    
        const bonTotal =  analise.bonificacoes.reduce((sum, val) => sum + Number(val.valor_bonus), 0)
        retRows.push([
            moment(analise.data_resultado).format('MM/YY'),
            formatValues(analise.gordura, gorduraModificado),
            formatDecimal(bonGordura),
            formatValues(analise.proteina, proteinaModificado),
            formatDecimal(bonProteina, 2),
            formatDecimal(analise.crioscopia * -1, 3),
            formatDecimal(analise.urea),
            formatDecimal(analise.esd),
            formatValues(analise.ccs, ccsModificado, -1),
            formatDecimal(bonCCS),
            formatValues(analise.ufc_curva, cbtModificado, -1),
            formatDecimal(bonCBT),
            formatDecimal(bonTotal)
        ])
    })
    mediasGeometricas[1] = calculateMediaGeometrica(mediasGeometricas[1], analises)
    mediasGeometricas[3] = calculateMediaGeometrica(mediasGeometricas[3], analises)
    mediasGeometricas[5] = calculateMediaGeometrica(mediasGeometricas[5], analises) 
    mediasGeometricas[6] = calculateMediaGeometrica(mediasGeometricas[6], analises)
    mediasGeometricas[7] = calculateMediaGeometrica(mediasGeometricas[7], analises)
    mediasGeometricas[8] = calculateMediaGeometrica(mediasGeometricas[8], analises)
    mediasGeometricas[10] = calculateMediaGeometrica(mediasGeometricas[10], analises)

    retRows.push(mediasGeometricas)
    return retRows
}

export function template_analise(data=[], colsData=[], contRows=10, pdfOrientation='p', configParams={}){
    let params = defaultParams;
	for(const key in configParams){
		params[key] = configParams[key]
    }
	let doc = new jsPDF(pdfOrientation, 'mm', 'a4')
	doc.setFont('times')
    let cont = 0
    let initVerticalLine = params.initialLine + params.bigVerticalSpace
    let horizontalLine   = params.smallHorizontalSpace
    let tablePerPage = 2
    if(contRows > 14) tablePerPage = 1
    else if(contRows < 14 && contRows >= 5) tablePerPage = 2
    else if(contRows < 5) tablePerPage = 3
    data.map(dt => {
        if(cont > 0 && cont % tablePerPage === 0){
            doc.addPage()
            initVerticalLine = params.initialLine + params.bigVerticalSpace
            horizontalLine   = params.smallHorizontalSpace
        }else if(cont > 0){
            initVerticalLine += params.bigVerticalSpace * 2
        }
        if(dt[0]){
          
            // Titulo e subtitulo
            doc.setFontSize(params.bigFont)
            doc.setTextColor(...params.fontColorPrimary)
            doc.writeText(params.headerTitle ,{align: 'center'}, 0, initVerticalLine)	
            doc.setFontSize(params.mediumFont)
            initVerticalLine += params.mediumVerticalSpace
            doc.writeText(params.subtitle ,{align: 'center'}, 0, initVerticalLine)
            initVerticalLine += params.mediumVerticalSpace
            doc.makeHorizontalLine(initVerticalLine)
            // Fim Titulo
    
            // Cabecalho
            initVerticalLine += params.bigHorizontalSpace
            doc.setFontType('normal')
            horizontalLine = doc.writeText(params.labelFornecedor, {align: 'left'}, 0, initVerticalLine)
            doc.setFontSize(params.mediumFont).setTextColor(...params.fontColorSecondary)
            horizontalLine = doc.writeText(dt[0].nome_produtor, {}, horizontalLine + params.mediumHorizontalSpace, initVerticalLine)
            doc.setTextColor(...params.fontColorPrimary)
            horizontalLine = doc.writeText(params.labelMatricula, {}, horizontalLine + params.bigHorizontalSpace, initVerticalLine)
            doc.setTextColor(...params.fontColorSecondary)
            doc.writeText(String(dt[0].centro_produtor.numero), {}, horizontalLine + params.mediumHorizontalSpace, initVerticalLine)
            initVerticalLine += params.mediumVerticalSpace
            doc.makeHorizontalLine(initVerticalLine)
            // Fim Cabecalho
            let values = {gordura: 3.4, proteina: 3.2, esd: 8.4, ccs: 500, ufc_curva: 300}
            let simbols = {gordura: '', proteina: '', esd: '', ccs: '', ufc_curva: ''}
            colsData.map(dt => {
                values[dt.elemento] = dt.valor
                simbols[dt.elemento] = dt.simbolo
            })
            initVerticalLine += params.mediumVerticalSpace
            initVerticalLine += makeTable(doc, fillCols(makeCols(values.gordura, values.proteina, values.esd,
                values.ccs, values.ufc_curva), makeSubCols()), makeRows(dt), initVerticalLine, 8, 
                {
                    1: {simbol: simbols.gordura,value: values.gordura},
                    3: {simbol: simbols.proteina,value: values.proteina},
                    7: {simbol: simbols.esd ,value: values.esd},
                    8: {simbol: simbols.ccs ,value: values.ccs},
                    10: {simbol: simbols.ufc_curva ,value: values.ufc_curva},
                })
            cont++
        }
    })
  
	return doc
}