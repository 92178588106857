
import React from 'react'
import { 
    Container,
    Grid,
    TextField,
    Button,
    Paper,
    IconButton,
    Typography
} from '@material-ui/core'
import { EditRounded } from '@material-ui/icons'
import { definicoesService } from '../../services/definicoes_service'
import {validateShowResponseToast } from '../../helpers/toast_alerts'


class FormUrls extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            coleta_url: '',
            analise_url: '',
            chave_coleta: '',
            chave_analise: '',
            editing: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
        this.handleEdit   = this.handleEdit.bind(this)
        this.handleGetUrls = this.handleGetUrls.bind(this)
        this.handleUpdateUrls = this.handleUpdateUrls.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleGetUrls(response){
        if(validateShowResponseToast(response, undefined, false)){
            if(response.data[0]){
                this.setState({
                    coleta_url: response.data[0].coleta_url,
                    analise_url: response.data[0].analise_url,
                    chave_coleta: response.data[0].chave_coleta,
                    chave_analise: response.data[0].chave_analise,
                })
            }
        }
    }

    handleUpdateUrls(response){
        if(validateShowResponseToast(response, 'URLs', true, true, "URLs foram salvas")){
            this.setState({
                editing: false
            })
        }
    }

    componentWillMount(){
        definicoesService.getUrlDados(this.handleGetUrls, undefined)
    }

    action(){
        definicoesService.createUpdateUrlDados(JSON.stringify(this.state), this.handleUpdateUrls)
    }

    handleClick(e){
        this.action()
    }

    handleAction(e){
        if(e.key == 'Enter'){
            this.action()
        }
    }

    handleEdit(e){
        this.setState({
            editing: !this.state.editing

        })
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    render(){
        return (
            <Paper>
                <Typography>
                    URLs de Acesso aos Dados
                </Typography>
                <Grid
                    container
                    direction="column-reverse"
                    justify="flex-start"
                    alignItems="flex-end">
                    <IconButton onClick={this.handleEdit}>
                        <EditRounded color={ this.state.editing ? 'secondary' : 'primary'} /> 
                    </IconButton>
                </Grid>
            <Grid item xs={12}   style={{marginTop: '20px'}}>
                    <form className={{display: 'flex',flexWrap: 'wrap',}}
                     noValidate 
                     onSubmit={e => { e.preventDefault()}}
                     >
                    <Container style={{marginTop: 20}} >
                        <TextField
                            disabled={ ! this.state.editing}
                            onKeyDown={this.handleAction}
                            id="coleta_url"
                            label="URL das coletas"
                            name="coleta_url"
                            fullWidth
                            type="text"
                            value={this.state.coleta_url}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Container>
                    <Container style={{marginTop: 20}} >
                        <TextField
                            disabled={ ! this.state.editing}
                            onKeyDown={this.handleAction}
                            id="chave_coleta"
                            label="Chave de acesso as coletas (API)"
                            name="chave_coleta"
                            fullWidth
                            type="text"
                            value={this.state.chave_coleta}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Container>
                    <Container style={{marginTop: 20}} >
                        <TextField
                            disabled={ ! this.state.editing}
                            onKeyDown={this.handleAction}
                            id="analise_url"
                            label="URL das análises"
                            name="analise_url"
                            fullWidth
                            type="text"
                            value={this.state.analise_url}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Container>
                    <Container style={{marginTop: 20}} >
                        <TextField
                            disabled={ ! this.state.editing}
                            onKeyDown={this.handleAction}
                            id="chave_analise"
                            label="Chave de acesso as análises (API)"
                            name="chave_analise"
                            fullWidth
                            type="text"
                            value={this.state.chave_analise}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Container>
                    
                    </form>
                    <Grid  style={{marginTop: 20}} >
                        <Button
                            onClick={this.handleClick}
                            fullWidth
                            variant="contained"
                            color="primary"
                            >
                            Salvar URLs
                        </Button>
                    </Grid>
            </Grid>
            </Paper>
        )
        
    }
}

export default FormUrls
                
                

              
                