import { getAxiosInstance } from '../services/service'

export const definicoesService = {
    getDefinicoes,
    updateDefinicoes,
    createUpdateUrlDados,
    getUrlDados
}

async function getDefinicoes(handleReponse=undefined, handleAction=undefined){
    let response = undefined
    response = await getAxiosInstance('/laticinio/definicao/status/-1').get().catch(e => {
        response = e.response
    })
    if(handleReponse){
        handleReponse(response)
    } 
}

async function updateDefinicoes(jsonData, handleReponse=undefined, handleAction=undefined){
    let response = undefined
    response = await getAxiosInstance('/laticinio/definir/definicao').put(undefined, jsonData).catch(e =>{
        response = e.response
    })
    if(handleReponse) handleReponse(response)
}

async function createUpdateUrlDados(jsonData, handleReponse=undefined, handleAction=undefined){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/definir/urlDados').post(undefined, jsonData).catch(e =>{
        errorResponse = e.response
    })
    if(handleReponse) handleReponse(response || errorResponse)

    
}

async function getUrlDados(handleReponse=undefined, handleAction=undefined){
    let response = undefined
    response = await getAxiosInstance('/laticinio/urlDados/status/1').get().catch(e => {
        response = e.response
    })
    if(handleReponse){
        handleReponse(response)
    } 
}