import React from 'react'
import FormUrls from './FormUrls'
import Container from '@material-ui/core/Container'
import FormAtualizacao from './FormAtualizacao';
import { Divider } from '@material-ui/core';



class Definicao extends React.Component{
    render(){
        return (
            <Container>
                <FormUrls></FormUrls>
                <Divider variant="fullWidth" style={{marginTop: 100}}></Divider>
                <FormAtualizacao></FormAtualizacao>
            </Container>
        )
    }
}

export default Definicao