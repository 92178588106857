import { adminAuthHeader } from '../helpers/auth_header';
import { BASE_URL } from '../constants/urls'
import { getAxiosInstance } from '../services/service'

require('dotenv').config()


export const adminService = {
    login,
    logout,
    getAll,
    getLaticinios,
    aprovarLaticinio
};

async function getLaticinios(handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/admin/laticinios', adminAuthHeader().Authorization).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function aprovarLaticinio(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/admin/laticinios/aprovar', 
        adminAuthHeader().Authorization).post(undefined, data).catch(error => {
            errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: adminAuthHeader()
    };

    const response = await fetch(`${BASE_URL}/admin`, requestOptions);
    return handleResponse(response); 
}

async function login(usuario, senha) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario, senha })
    };
    const response = await fetch(`${BASE_URL}/admin/login`, requestOptions);
    const admin = await handleResponse(response);
    // login successful if there's a user in the response
    if (admin) {
        // store user details and basic auth credentials in local storage 
        // to keep user logged in between page refreshes
        admin.authdata = window.btoa(usuario + ':' + senha);
        localStorage.setItem('admin', JSON.stringify(admin));
    }
    return admin;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('admin');
}


