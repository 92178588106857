import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    TextField,
    NativeSelect,
    Grid
} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: 200,
      maxHeight: 200,
      minWidht:  300,
      maxWidht:  300
  },
  formControl: {
      marginTop: 25,
  },
  
})

class FormMeioPagamento extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            descricao: this.props.data ? this.props.data.descricao || "" : "",
            status: this.props.data.status,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth >
                <TextField
                    id="descricao"
                    label="Descrição"
                    name="descricao"
                    fullWidth
                    type="text"
                    value={this.state.descricao}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
          
           <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <NativeSelect
                    fullWidth
                    value={this.state.status}
                    name='status'
                    onChange={this.handleChange}
                    >
                    <option value={1}>Ativo</option>
                    <option value={0}>Inativo</option>
                </NativeSelect>
            </FormControl> 
           
         </form>
    )
        
    }
}

export default withStyles(styles)(FormMeioPagamento)