import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { userService } from '../../services/user_service';
import { toast } from 'react-toastify'
import { adminService } from '../../services/admin_service';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: ''
  };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillUpdate(nextProps, nextState){
      if(nextState.error !== this.state.error){
        this.setState({error : ''})
      }
  }


  login(e){
    this.setState({ submitted: true });
    const { username, password, returnUrl } = this.state;
    // stop here if form is invalid
    if (!(username && password)) {return;}
    this.setState({ loading: true });
    adminService.login(username, password).then(
            admin => {
                const { from } = this.props.location.state || { from: { pathname: "/admin/dashboard/laticinios" } };
                this.props.history.push(from);
            },
            error => {
              this.setState({ error, loading: false })
              toast.error("Falha ao realizar login! Verifique Usuário e Senha")
            }
        );
       

  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
 }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
       this.login(e)
    }
}

  handleClick(e) {
    e.preventDefault();
    this.login(e)
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;
    const {classes} = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login de Administrador
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuário"
              name="username"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              value={username}
              autoComplete=""
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              value={password}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembrar-me"
            />
            <Button
              fullWidth
              onClick={this.handleClick}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(FormLogin);
