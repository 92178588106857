import React from "react";
import {
  ClearRounded,
  AddCircleOutlineRounded,
  PrintRounded,
} from "@material-ui/icons";

import { headRowsAnalisesSimple, headRowsAnalises } from "../table/HeadRows";
import ObjectTable from "../table/ObjectTable";
import { analiseService } from "../../services/analise_service";
import {
  makeActionFormDialog,
  closeProgressDialog,
  makeProgressDialog,
} from "../dialogs/DialogRefInterface";
import FilterRelatorioAnalise from "./FilterRelatorioAnalise";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { template_analise } from "../templates_pdf/template_analise";
import FormConfirmationAnalise from "./FormConfirmationAnalise";
import moment from "moment";
import { laticinioService } from "../../services/laticinio_service";

class TableRelatorioAnalise extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      limit: 10,
      headRows: headRowsAnalisesSimple,
    };

    this.objectTableRef = React.createRef();
  }

  makeRows = (rows) => {
    let retRows = [];
    rows.map((rw) => {
      rw.map((value) => {
        retRows.push(value);
      });
    });
    return retRows;
  };

  handleForceUpdate = () => {
    this.forceUpdate();
  };

  selectedToolActions = [
    {
      title: "Limpar Seleção",
      label: "Adicionar",
      MaterialIcon: ClearRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.updateTable({ selected: [] });
      },
      color: "secondary",
    },
  ];

  toolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        analiseService.getDefaultBonificacao([], (response) => {
          if (validateShowResponseToast(response, null, false)) {
            makeActionFormDialog(
              FormConfirmationAnalise,
              "Valores da Análise",
              null,
              response.data,
              "Concluir",
              (data) => {
                laticinioService.getLaticinio((response) => {
                  if (validateShowResponseToast(response, "", false)) {
                    template_analise(
                      this.state.rows,
                      data.data,
                      this.state.limit,
                      "p",
                      {
                        headerTitle: response.data.nome,
                      }
                    ).save(
                      `relatorio_analises_${moment().format("DD_MM_YYYY")}`
                    );
                  }
                });
              }
            ).current.open();
          }
        });
      },
      color: "primary",
    },
    {
      title: "Criar Relatório",
      label: "Criar",
      MaterialIcon: AddCircleOutlineRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FilterRelatorioAnalise,
          "Relatório Análises",
          null,
          null,
          "Gerar",
          (data) => {
            makeProgressDialog(null, "Gerando Relatório...").current.open();
            analiseService.getAllAnalises(
              { data: data.selected, limit: data.limit },
              (response) => {
                if (validateShowResponseToast(response)) {
                  this.setState({
                    rows: response.data,
                    limit: data.limit,
                  });
                } else {
                  closeProgressDialog();
                }
              },
              closeProgressDialog
            );
          }
        ).current.open();
      },
    },
  ];
  actions = [];

  render() {
    return (
      <div>
        <input
          type="file"
          id="file"
          ref="fileUploader"
          style={{ display: "none" }}
          accept=".txt"
          onChange={this.handleInputChange}
        />
        <ObjectTable
          rowId="id"
          tableName="Relatório Análises"
          headRows={this.state.headRows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.toolActions}
          rowSize={40}
          rowsOptions={[20, 40, 80]}
          ref={this.objectTableRef}
          rows={this.makeRows(this.state.rows) || []}
        />
      </div>
    );
  }
}

export default TableRelatorioAnalise;
