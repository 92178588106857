import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, InputLabel,FormControl, Checkbox, FormControlLabel,
  Select, MenuItem} from '@material-ui/core'
import { pagamentoService } from '../../services/pagamento_service';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { toast } from 'react-toastify';


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: 300,
      maxHeight: 300,
      minWidht:  300,
      maxWidht:  300
  },
  formControl: {
    minWidht:  300,
    maxWidht:  300
  },
})

class FormFolhaPagamento extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tipo_pagamento_id: 0,
            valor: 0,
            restante: 0,
            tipos: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this)
        this.handleChangeRestante = this.handleChangeRestante.bind(this)
    }

    componentDidMount(){
      pagamentoService.getMeiosPagamento((response)=>{
        if(validateShowResponseToast(response, null, false)){
          this.setState({
            tipos: response.data
          })
        }
      }, null, 1)
    }

     // Necessario em todos os form abertos em ActionFormDialog
     action(){
      if(this.props.handleFormState){
          if(this.state.tipo_pagamento_id > 0){
            this.props.handleFormState(this.state)
          }else{
            toast.warn("Favor preencher o tipo de pagamento")
          }
      }
    }

    handleChangeRestante(e){
        this.setState({
            restante: e.target.checked ? 1 : 0
        })
    }
 
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeCurrency(e){
       const {name, value} = e.target
       this.setState({[name]: value})
    }

    handleSelectChange(e) {
        this.setState({
           valor_impactante_id: e.target.value,
        });
    }

    render(){
      const {classes} = this.props
      return(
        <form className={classes.form} 
          noValidate 
          onSubmit={e => { e.preventDefault()}}
      >
          <FormControl  fullWidth className={classes.formControl}>
          <FormControlLabel
              control={
                <Checkbox
                  onChange={this.handleChangeRestante}
                  color="secondary"
                />
              }
              label="Pagar restante"
            />
         </FormControl>
         <FormControl fullWidth style={{marginTop: 20}}>
            <InputLabel impacto required>
                Opção de Pagamento
            </InputLabel>
            <Select
                value={this.state.tipo_pagamento_id}
                onChange={this.handleChange}
                label="Opção de Pagamento"
                required
                fullWidth
                name="tipo_pagamento_id"
                >
                {this.state.tipos.map(tipo =>{
                  return (<MenuItem value={tipo.id}>{tipo.descricao}</MenuItem>)
                })}
            </Select>
        </FormControl>
          <TextField
              style={{marginTop: 20}}
              required
              disabled={this.state.restante == 1 ? true : false}
              fullWidth
              className={classes.formControl}
              name="valor"
              label="Valor (R$)"
              type="number"
              id="valor"
              onChange={this.handleChange}
              value={this.state.valor}
          >
          </TextField>
          

       </form>
    )
        
    }
}

export default withStyles(styles)(FormFolhaPagamento)