import React from 'react'
import {DialogContent, DialogActions, Button, DialogTitle, Dialog, Slide, IconButton, Box} from '@material-ui/core'
import {ClearRounded} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import {
  BTN_SALVAR
} from "../../constants/msgs"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  dialog: {
      minWidth: 350,
      minHeight: 100,

  }
})

class ActionFormDialog extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          ObjectForm : this.props.ObjectForm ? this.props.ObjectForm : "<div/>",
          open : this.props.open ? this.props.open : false,
          title : this.props.title || "",
          centroProdutores : props.centroProdutores || [],
          btn_title : props.btn_title || BTN_SALVAR,
          handleAfterClose : this.props.handleAfterClose || undefined,
          handleAction : this.props.handleAction || undefined,
          handleFormState : this.props.handleFormState || undefined
        }
        this.handleClose = this.handleClose.bind(this)

        this.formRef = null;

        this.setFormRef = element => {
          this.formRef = element;
        };
    }


    update_ref_state(ObjectForm, title="", handleAfterClose=undefined, data={}, btn_title, handleFormState=undefined){
        this.setState({
          title:title, 
          handleAfterClose:handleAfterClose, 
          data: data,
          ObjectForm: ObjectForm,
          btn_title: btn_title || BTN_SALVAR,
          handleFormState : handleFormState || undefined
        })
    }

    handleClose(e){
        this.setState({open: false})
    }

    open(){
        this.setState({open: true})
    }

    action(){
      if(this.formRef){
        if(this.formRef.action){
          this.formRef.action()
        }
      }
    }
   
    render(){
      const {classes} = this.props
      return (
            <Dialog
              maxWidth={false}
              disableBackdropClick={true}
              disableEscapeKeyDown={false}
              TransitionComponent={Transition}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
          
              <Box display="flex" flexDirection="row-reverse" bgcolor="background.paper">
                <Box>
                  <DialogActions >
                    <IconButton aria-label="Sair" onClick={this.handleClose}>
                      <ClearRounded color='secondary'/>
                    </IconButton>
                  </DialogActions>
                </Box>
                <Box display="flex" justifyContent="center"  flexGrow={1}> 
                    <DialogTitle id="form-dialog-title">{
                      `${this.state.title}`
                    }
                    </DialogTitle>
                </Box>
              </Box>
           
              <DialogContent className={classes.dialog}>
                { this.state.ObjectForm ?
                  (< this.state.ObjectForm 
                    handleFormState={this.state.handleFormState}
                    ref={this.setFormRef}
                    data={this.state.data}
                  />) :
                  (
                    <div></div>
                  )
                }
                
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => {this.action()}} color="primary">
                  {this.state.btn_title}
                </Button>
              </DialogActions>
            </Dialog>      
        )
        
    }


}

export default withStyles(styles)(ActionFormDialog)