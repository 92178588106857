
import React from 'react'
import { 
    InputLabel, 
    Container,
    Grid,
    NativeSelect,
    TextField
} from '@material-ui/core'


class FilterProdutor extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nome: '',
            status: 1,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
    }

    action(){
        if(this.props.handleFormState) this.props.handleFormState(this.state)
    }

    handleAction(e){
        if(e.key == 'Enter'){
            this.action()
        }
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    render(){
        const style={  marginBottom: 20, minWidth: 150}
        return (
            <Grid item xs={12}   style={{marginTop: '20px'}}>
                    <form className={{display: 'flex',flexWrap: 'wrap',}}
                     noValidate 
                     onSubmit={e => { e.preventDefault()}}
                     >
                    <Container style={{marginTop: 20}} >
                        <InputLabel shrink htmlFor="native-label-placeholder">
                            Produtor
                        </InputLabel>
                        <TextField
                            onKeyDown={this.handleAction}
                            id="nome"
                            label="Nome do Produtor"
                            name="nome"
                            fullWidth
                            type="text"
                            value={this.state.nome}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Container>
                    <Container style={{marginTop: 20}} >
                        <InputLabel shrink htmlFor="native-label-placeholder">
                            Situação
                        </InputLabel>
                        <NativeSelect
                            fullWidth
                            value={this.state.status}
                            name='status'
                            onChange={this.handleChange}
                            style={style}
                            >
                            <option value={-1}>TODOS</option>
                            <option value={1}>Ativo</option>
                            <option value={0}>Inativo</option>
                        </NativeSelect>
                    </Container>
                    
                    </form>
            </Grid>
        )
        
    }
}

export default (FilterProdutor)
                
                

              
                