import { toast } from 'react-toastify'

function _makeErrorsMessage(response){
    let errors = {}
    if(response){
        if(response.data)
          errors = response.data.errors || {}
    }
    let strRet = ""
    for(let er in errors){
        for (let i = 0; i < errors[er].length; i++) {
            strRet = strRet.concat(`${er}: ${errors[er][i]} \r\n`)
        }
    }
    if(response.data.message && strRet === ""){
        strRet = response.data.message
    }
    return strRet
}

export function validateShowResponseToast(response, model=undefined, isShowSuccess=true, isShowFail=true,  successMsg=undefined){
    if(response){
        if(response.status === 200){
            if(isShowSuccess) toast.success(successMsg || "Operação realizada com sucesso!")
            return true
        }else if(response.status === 201){
            if(isShowSuccess) toast.success(`${model || ''} criado com sucesso!`)
            return true
        }else if(response.status === 400){
            if(isShowFail) toast.warn(`${_makeErrorsMessage(response)}`)
            return false
        }else if(response.status === 401){
            if(isShowFail) toast.warn("Você não tem autorização para realizar esse procedimento!")
            return false
        }else{
            if(isShowFail) toast.error("Erro interno! Se persistir contate o suporte técnico")
            return false
        }
    }else{
        if(isShowFail) toast.error("Falha ao acessar o Servidor! Verifique a conexão com a internet!")
        return false;
    }
}