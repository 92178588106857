import { getAxiosInstance } from '../services/service'

export const centroProdutorService = {
    getCentroProdutores,
    createCentroProdutor,
    udpateCentroProdutor
}

async function getCentroProdutores(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance("/produtor/centroProdutores").post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function createCentroProdutor(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance("/produtor/centroProdutor").post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function udpateCentroProdutor(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/produtor/centroProdutor/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}


