import React from 'react'
import { 
    SearchRounded,
    AddRounded,
    RemoveRedEyeRounded,
    EditRounded,
    ClearRounded,
} from '@material-ui/icons'

import { headRowsAnaliseFile } from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import {
    makeActionFormDialog,
    makeProgressDialog,
    makeObjectListDialog,
    makeAlertDialog,
} from '../dialogs/DialogRefInterface'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import Paginate from '../table/Paginate'
import TableFormAnalise from './TableFormAnalise';
import { analiseService } from '../../services/analise_service';
import moment from 'moment'
import FormAnaliseFile from './FormAnaliseFile';
import TableAnalise from './TableAnalise';
import FilterAnaliseFile from './FilterAnaliseFile';



class TableAnaliseFile extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
        }

        this.handleGetFilters = this.handleGetFilters.bind(this)
        this.handleCreateResponse = this.handleCreateResponse.bind(this)
        this.handleUpdateResponse = this.handleUpdateResponse.bind(this)
    }



    handleForceUpdate = () => {
        this.forceUpdate()
        if(this.state.paginate.objectTableRef.current){
            this.state.paginate.objectTableRef.current.updateTable()
        }
    }

  
    componentWillMount(){
        analiseService.getAnalisesFile(
            {data_inicio: moment().subtract(1, 'year'), data_final: moment(), status: 1},
            this.state.paginate.handleGet)
    }

    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 

    toolActions = [
       
        {
            title: 'Pesquisar', 
            label: 'Pesquisar',
            MaterialIcon: SearchRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterAnaliseFile, "Pesquisar Análises", 
                undefined, undefined, "Pesquisar", this.handleGetFilters).current.open()
            },
        },
        {
            title: 'Adicionar Análise', 
            label: 'Adicionar Análise',
            MaterialIcon: AddRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeObjectListDialog(TableFormAnalise, "Adicionar Análises", 
                undefined, undefined, undefined, (data) => {
                    analiseService.getAnalisesFile(
                        {data_inicio: moment().subtract(1, 'year'), data_final: moment(), status: 1},
                        this.state.paginate.handleGet)
                }).current.open()

            },
        },
       
    ]

    actions = [
        {
            title: 'Ver Análises', 
            label: 'Ver Análises',
            MaterialIcon: RemoveRedEyeRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeObjectListDialog(TableAnalise, "Análises", undefined, selected,
                undefined, undefined).current.open()
            },
        },
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormAnaliseFile, "Editar Análise", undefined,
                    selected, "Salvar", (data) => {
                        if(data.status == 0){
                            makeAlertDialog(() => {
                                    analiseService.updateAnalise(data, this.handleUpdateResponse)
                                },
                                "Atenção",
                                "Alterar o Status da Análise para INATIVO fará com que ela não seja aplicada nas próximas folhas de pagamento"
                            ).current.open()
                        }else{
                            analiseService.updateAnalise(data, this.handleUpdateResponse)
                        }
                    }).current.open()
            },
        },
    ]

    handleCreateResponse(response){
      
    }

    handleUpdateResponse(response){
        if(validateShowResponseToast(response)){
            this.state.paginate.updateRow('id', response.data)
        }
    }

    handleGetFilters(filters){
        makeProgressDialog().current.open()
        this.state.paginate.setAttribute('isNew', true)
        analiseService.getAnalisesFile(filters, this.state.paginate.handleGet)
    }

    render(){
        return(
            <div>
                <input 
                    type="file" 
                    id="file" 
                    ref="fileUploader"
                    style={{display: "none"}}
                    accept=".txt"
                    onChange={this.handleInputChange}
                />
                <ObjectTable 
                    rowId='id'
                    tableName='Análises' 
                    headRows={headRowsAnaliseFile} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={40}
                    rowsOptions={[20, 40, 80]}
                
                    ref={this.state.paginate.objectTableRef}
                    rows={this.state.paginate.rows}
                    count={this.state.paginate.count}
                    handleLoadPage={this.state.paginate.handleLoadPage}
                    perPage={this.state.paginate.perPage}
                    from={this.state.paginate.from}
                    to={this.state.paginate.to}
                />
            </div>
            )
    }
}

export default TableAnaliseFile