import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Paper from "@material-ui/icons/PausePresentationSharp";
import PeopleIcon from "@material-ui/icons/People";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Settings from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Collapse, List } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  SupervisedUserCircleRounded,
  AttachMoneyRounded,
  CallMadeRounded,
  TrendingUpRounded,
  LocalOfferRounded,
  Ballot,
  AccountBalanceWalletRounded,
  Receipt,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function switch_route(browserHistory, route) {
  browserHistory.push(route);
}

export const MainListItems = (browserHistory) => {
  const [open, setOpen] = React.useState(false);
  const [openRelatorio, setOpenRelatorio] = React.useState(false);
  const classes = useStyles();
  return (
    <div >
      <ListItem
        button
        onClick={() => switch_route(browserHistory, "/dashboard")}
      >
        <ListItemIcon>
          <DashboardIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Principal" />
      </ListItem>
      <ListItem button onClick={() => browserHistory.push("/dashboard/folhas")}>
        <ListItemIcon>
          <Paper color="primary" />
        </ListItemIcon>
        <ListItemText primary="Folhas" />
      </ListItem>
      <ListItem
        button
        onClick={() => browserHistory.push("/dashboard/produtores")}
      >
        <ListItemIcon>
          <PeopleIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Produtores" />
      </ListItem>
      <ListItem
        button
        onClick={() => browserHistory.push("/dashboard/coletas")}
      >
        <ListItemIcon>
          <AssignmentIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Coletas" />
      </ListItem>
      <ListItem
        button
        onClick={() => browserHistory.push("/dashboard/analises")}
      >
        <ListItemIcon>
          <Ballot color="primary" />
        </ListItemIcon>
        <ListItemText primary="Análises" />
      </ListItem>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <InboxIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Outros" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/bonificacoes")}
          >
            <ListItemIcon>
              <AttachMoneyRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Bonificações" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/precos")}
          >
            <ListItemIcon>
              <LocalOfferRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Preço Base" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/valores")}
          >
            <ListItemIcon>
              <CallMadeRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Valores" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/taxas")}
          >
            <ListItemIcon>
              <TrendingUpRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Taxas" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/pagamentos")}
          >
            <ListItemIcon>
              <AccountBalanceWalletRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Meios Pagamento" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/usuarios")}
          >
            <ListItemIcon>
              <SupervisedUserCircleRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={() => setOpenRelatorio(!openRelatorio)}>
        <ListItemIcon>
          <Receipt color="primary" />
        </ListItemIcon>
        <ListItemText primary="Relatórios" />
        {openRelatorio ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openRelatorio} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/relatorio/contabil")}
          >
            <ListItemIcon>
              <AttachMoneyRounded color="primary" />
            </ListItemIcon>
            <ListItemText primary="Contábil" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={openRelatorio} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => browserHistory.push("/dashboard/relatorio/analise")}
          >
            <ListItemIcon>
              <Ballot color="primary" />
            </ListItemIcon>
            <ListItemText primary="Análises" />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={openRelatorio} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              browserHistory.push("/dashboard/relatorio/recebimentos")
            }
          >
            <ListItemIcon>
              <AssignmentIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Mapa Coletas" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        onClick={() => browserHistory.push("/dashboard/definicoes")}
      >
        <ListItemIcon>
          <Settings color="primary" />
        </ListItemIcon>
        <ListItemText primary="Definições" />
      </ListItem>
    </div>
  );
};
