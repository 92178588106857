import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
    TextField,
} from '@material-ui/core'
import CustomDatePicker from '../default/CustomDatePicker'


const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,

    },
    form: {
        minHeight: 100,
        maxHeight: 100,
        minWidht: 300,
        maxWidht: 300
    },
    formControl: {
        marginTop: 25,
    },

})

class FormCloseFolha extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_final: ""
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount() {
        this.setState({
            data_final: this.props.data ? moment(this.props.data.data_inicio).add(14, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        })
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action() {
        if (this.props.handleFormState) {
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    render() {
        const { classes } = this.props
        return (
            <form className={
                classes.form}
                noValidate
                onSubmit={e => { e.preventDefault() }}
            >
                <CustomDatePicker
                    disableToolbar
                    fullWidth
                    label="Data de Fechamento"
                    variant="inline"
                    format="DD/MM/YYYY"
                    name="data_final"
                    value={this.state.data_final}
                    onChange={this.handleChange}
                />
            </form>
        )

    }
}

export default withStyles(styles)(FormCloseFolha)