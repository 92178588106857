import { getAxiosInstance } from '../services/service'

export const bonificacaoService = {
    getFatorBonificacoes,
    getElementosBonificacao,
    createFatorBonificacao,
    deleteFatorBonificacao,
    deleteFatorBonificacoes
}

async function deleteFatorBonificacao(id, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/fatorBonificacao/${id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deleteFatorBonificacoes(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/fatorBonificacao/disable').put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function createFatorBonificacao(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/definir/fatorBonificacao').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getFatorBonificacoes(handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/fatorBonificacao/status/1').get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}


async function getElementosBonificacao(handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/elementos/status/-1').get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}


