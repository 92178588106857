
import React from 'react'
import {
    Container,
    Grid,
    TextField,
    Button,
    Paper,
    IconButton,
    Typography
} from '@material-ui/core'
import { EditRounded } from '@material-ui/icons'
import { definicoesService } from '../../services/definicoes_service'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'

class FormAtualizacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ultima_atualizacao: "",
            editing: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.handleUpdateResponse = this.handleUpdateResponse.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleGetResponse(response) {
        if (validateShowResponseToast(response, undefined, false)) {
            this.setState({
                ultima_atualizacao: moment(response.data.ultima_atualizacao)
            })
        }
    }

    handleUpdateResponse(response) {
        if (validateShowResponseToast(response, 'Atualização', true, true, "Ultima Atualização salva com sucesso!")) {
            this.setState({
                editing: false
            })
        }
    }

    componentWillMount() {
        definicoesService.getDefinicoes(this.handleGetResponse, undefined)
    }

    action() {
        const data = { ultima_atualizacao: this.state.ultima_atualizacao.format('YYYY-MM-DD') }
        definicoesService.updateDefinicoes(data, this.handleUpdateResponse)
    }

    handleClick(e) {
        this.action()
    }

    handleAction(e) {
        if (e.key == 'Enter') {
            this.action()
        }
    }

    handleEdit(e) {
        this.setState({
            editing: !this.state.editing

        })
    }

    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <Paper>
                <Typography>
                    Ultima Atualização
                </Typography>
                <Grid
                    container
                    direction="column-reverse"
                    justify="flex-start"
                    alignItems="flex-end">
                    <IconButton onClick={this.handleEdit}>
                        <EditRounded color={this.state.editing ? 'secondary' : 'primary'} />
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <form className={{ display: 'flex', flexWrap: 'wrap', }}
                        noValidate
                        onSubmit={e => { e.preventDefault() }}
                    >
                        <Container>
                            <KeyboardDatePicker
                                disableToolbar
                                fullWidth
                                variant="inline"
                                disabled={!this.state.editing}
                                format="DD/MM/YYYY"
                                id="ultima_atualizacao"
                                value={this.state.ultima_atualizacao}
                                onChange={date => this.handleChangeDate(date, 'ultima_atualizacao')}
                                KeyboardButtonProps={{
                                    'aria-label': 'Mudar data',
                                }}
                            />
                        </Container>
                    </form>
                    <Grid style={{ marginTop: 20 }} >
                        <Button
                            onClick={this.handleClick}
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )

    }
}

export default FormAtualizacao




