import React from 'react'
import { 
    AddCircleOutlineRounded, 
    EditRounded, 
    SearchRounded,
    HomeRounded,
    ClearRounded,
    RefreshRounded
} from '@material-ui/icons'

import {headRowsProdutores} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { produtorService } from '../../services/produtor_service'
import { centroProdutorService } from '../../services/centro_produtor_service'
import {
    makeObjectListDialog,
    makeActionFormDialog,
    makeProgressDialog,
    closeProgressDialog
} from '../dialogs/DialogRefInterface'
import TableCentroProdutor from '../_centro_produtor/TableCentroProdutor';
import FilterProdutor from './FilterProdutor';
import FormProdutor from './FormProdutor'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import Paginate from '../table/Paginate'

class TableProdutor extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            paginate: new Paginate(this.handleForceUpdate)
        }

        this.handleGetFilters = this.handleGetFilters.bind(this)
        this.handleCreateResponse = this.handleCreateResponse.bind(this)
    }

    handleForceUpdate = () => {
        this.forceUpdate()
        if(this.state.paginate.objectTableRef.current){
            this.state.paginate.objectTableRef.current.updateTable()
        }
    }

    refresh = () => {
        produtorService.getProdutores({}, this.state.paginate.handleGet)

    }

    componentWillMount(){
        this.refresh()
    }

    selectedToolActions = [

        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        },
    ] 

    toolActions = [
        {
            title: 'Pesquisar', 
            label: 'Pesquisar',
            MaterialIcon: SearchRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterProdutor, "Pesquisar Produtores", 
                undefined, undefined, "Pesquisar", this.handleGetFilters).current.open()
            },
        },
        {
            title: 'Atualizar', 
            label: 'Atualizar',
            MaterialIcon: RefreshRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.refresh()
            },
        },
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormProdutor, "Cadastro de Produtor", undefined, undefined, "Salvar", (data) => {
                    if(data.id > 0){
                        produtorService.updateProdutor(data, this.handleCreateResponse)
                    }else{
                        produtorService.createProdutor(data, this.handleCreateResponse)
                    }
                }).current.open()
            },
        }
    ]


    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormProdutor, "Cadastro de Produtor", undefined, selected, "Salvar", (data) => {
                    produtorService.updateProdutor(data, this.handleCreateResponse)
                }).current.open()
            }
        },

        {
            title: 'Centro Produtores', 
            label: 'Centro Produtores',
            MaterialIcon: HomeRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeObjectListDialog(TableCentroProdutor, "Centro Produtores", undefined, selected, undefined).current.open()
            }
        },
    ]

    handleCreateResponse(response){
        if(validateShowResponseToast(response, "Produtor")){
            this.state.paginate.updateRow('id', response.data)
            console.log(response)
        }
    }

    handleGetFilters(filters){
        makeProgressDialog().current.open()
        this.state.paginate.setAttribute('isNew', true)
        produtorService.getProdutores(filters, this.state.paginate.handleGet, closeProgressDialog)
    }
   

    render(){
        return(
            <ObjectTable 
                rowId='id'
                tableName='Produtores' 
                headRows={headRowsProdutores} 
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowSize={20}
                rowsOptions={[10, 20, 40]}
               
                ref={this.state.paginate.objectTableRef}
                rows={this.state.paginate.rows}
                count={this.state.paginate.count}
                handleLoadPage={this.state.paginate.handleLoadPage}
                perPage={this.state.paginate.perPage}
                from={this.state.paginate.from}
                to={this.state.paginate.to}
                />
        )
    }
}

export default TableProdutor