import React from "react";
import {
  AddCircleOutlineRounded,
  ClearRounded,
  PrintRounded,
  CloudDownloadRounded,
} from "@material-ui/icons";
import { headRowsMapaColetas } from "../table/HeadRows";
import ObjectTable from "../table/ObjectTable";
import {
  closeProgressDialog,
  makeActionFormDialog,
  makeProgressDialog,
} from "../dialogs/DialogRefInterface";
import moment from "moment";
import "moment/locale/pt-br";
import { formatDecimal } from "../../util/utils";
import FilterRelatorioColeta from "./FilterRelatorioColeta";
import { folhaService } from "../../services/folha_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";

class TableMapaColeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      lastSearch: [],
      rowsSum: [],
      headRows: headRowsMapaColetas,
      dataInicial: null,
      dataFinal: null,
    };

    this.objectTableRef = React.createRef();
  }

  getTitle = () => {
    const { dataInicial, dataFinal } = this.state;

    if (dataInicial && dataFinal) {
      const month = `${moment(dataInicial).locale("pt-BR").format("MMMM")}`;
      const year = `${moment(dataInicial).format("YYYY")}`;
      return `${dataInicial.split("-")[2]} até ${
        dataFinal.split("-")[2]
      } de ${month} de ${year}`;
    }

    return "";
  };

  getSeleteds = (selected) => {
    return this.state.rows.filter((row) => {
      if (selected.indexOf(row.centro_produtor) > -1) {
        return row;
      }
    });
  };

  getHeadRows = (params) => {
    return this.state.headRows.map((hrc) => {
      const newHcr = { ...hrc };
      newHcr.label = params[hrc.id]
        ? `${hrc.label} ${formatDecimal(params[hrc.id], -1)}%`
        : hrc.label;
      return newHcr;
    });
  };

  handleUpdateHeadRows = (filters, dataInicial, dataFinal) => {
    try {
      const firstDay = Number(dataInicial.split("-")[2]);
      const lastDay = Number(dataFinal.split("-")[2]);
      const extraHeadRows = [];
      for (let i = firstDay; i <= lastDay; i++) {
        extraHeadRows.push({
          id: `day_${i}`,
          numeric: true,
          disablePadding: false,
          label: `${i}`,
        });
      }
      const newHeadRows = [...headRowsMapaColetas, ...extraHeadRows];

      this.setState(
        {
          headRows: newHeadRows,
          rowsSum: ["total_litro", ...extraHeadRows.map((row) => row.id)],
          dataInicial,
          dataFinal,
        },
        () => {
          makeProgressDialog(null, "Criando Relatório...").current.open();
          this.handleLoadData(filters, dataInicial, dataFinal);
        }
      );
    } catch (err) {}
  };

  handleLoadData = (filters, dataInicial, dataFinal) => {
    const startDay = Number(dataInicial.split("-")[2]);
    const endDay = Number(dataFinal.split("-")[2]);
    folhaService.getFolhas(
      filters,
      (response) => {
        if (validateShowResponseToast(response)) {
          const data = response.data.data;
          const formatedData = data.map((folha) => {
            const result = {};
            result.total_litro = folha.total_litro;
            result.produtor = folha.produtor;
            result.centro_produtor = folha.numero_centro_produtor;

            for (let i = startDay; i <= endDay; i++) {
              result[`day_${i}`] = 0;
            }
            folha.folha_coletas.forEach((fc) => {
              const dayNumber = Number(fc.data_coleta.split("-")[2]);
              result[`day_${dayNumber}`] = fc.qtd_litros;
            });

            return result;
          });

          this.setState({
            rows: formatedData,
          });
        } else {
          closeProgressDialog();
        }
      },
      closeProgressDialog
    );
  };

  actions = [];

  selectedToolActions = [
    {
      title: "Limpar Seleção",
      label: "Limpar",
      MaterialIcon: ClearRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.updateTable({ selected: [] });
      },
      color: "secondary",
    },
  ];

  toolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportPDF(
          this.getHeadRows(this.state.lastSearch),
          false,
          `Mapa de recebimento de leite da quinzena ${this.getTitle()}`,
          "l",
          `Mapa de recebimento de leite da quinzena ${this.getTitle()}`,
          this.state.rowsSum,
          "centro_produtor"
        );
      },
      color: "primary",
    },
    {
      title: "Exportar Excel",
      label: "Exportar",
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportCSV(
          this.getHeadRows(this.state.lastSearch).map((hr) => {
            return { label: hr.label, key: hr.id };
          }),
          true,
          false,
          this.state.rowsSum
        );
      },
    },
    {
      title: "Criar Relatório",
      label: "Criar",
      MaterialIcon: AddCircleOutlineRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FilterRelatorioColeta,
          "Gerar Relatório",
          null,
          null,
          "Gerar",
          (data) => {
            const { data_inicial, data_final } = data;
            let filters = { data_inicial, data_final, status: 0, update: 1 };
            this.handleUpdateHeadRows(filters, data_inicial, data_final);
          }
        ).current.open();
      },
    },
  ];

  render() {
    return (
      <div>
        <ObjectTable
          tableName={`Mapa de Recebimento de Leite ${
            this.state.dataFinal ? this.getTitle() : ""
          }`}
          headRows={this.state.headRows}
          rows={this.state.rows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.toolActions}
          rowId="centro_produtor"
          ref={this.objectTableRef}
          rowSize={60}
          rowsOptions={[20, 40, 60]}
        />
      </div>
    );
  }
}

export default TableMapaColeta;
