import { getAxiosInstance } from './service'

export const pagamentoService = {
    getMeiosPagamento,
    createMeioPagamento,
    updateMeioPagamento
}

async function getMeiosPagamento(handleResponse, handleAction, status=-1){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/tipoPagamento/status/${status}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createMeioPagamento(data, handleResponse, handleAction, status=-1){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/definir/tipoPagamento`).post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateMeioPagamento(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/definir/tipoPagamento/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}