import React from 'react'
import { AddCircleOutlineRounded, EditRounded, ClearRounded} from '@material-ui/icons'

import {headRowsCentroProdutores} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import FormCentroProdutor from './FormCentroProdutor';
import Paginate from '../table/Paginate';
import { centroProdutorService } from '../../services/centro_produtor_service';
import { toast } from 'react-toastify';
import { validateShowResponseToast } from '../../helpers/toast_alerts'


class TableCentroProdutor extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
            produtor_id: this.props.data ? this.props.data.id : 0,
            matricula_status: this.props.data.matricula_status 
        }

        this.handleCreateResponse = this.handleCreateResponse.bind(this)
        this.handleUpdateResponse = this.handleUpdateResponse.bind(this)
    }

    componentWillMount(){
        centroProdutorService.getCentroProdutores({produtor_id: this.state.produtor_id}, this.state.paginate.handleGet)
    }

    handleForceUpdate = () => {
        this.forceUpdate()
        if(this.state.paginate.objectTableRef.current){
            this.state.paginate.objectTableRef.current.updateTable()
        }
    }

    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        },   
    ] 

    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(this.state.matricula_status === 1){
                    makeActionFormDialog(FormCentroProdutor, "Cadatro de Centro Produtor", undefined, undefined, "Salvar", (data) => {
                        data.produtor_id = this.state.produtor_id
                        centroProdutorService.createCentroProdutor(data, this.handleCreateResponse)
                    }).current.open()
                }else{
                    toast.warn("Este produtor está INATIVO, para adicionar C.P. primeiro você deve ativá-lo")
                }
            }
        }
    ]

    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                 if(this.state.matricula_status === 1){
                    makeActionFormDialog(FormCentroProdutor, "Editar Centro Produtor", undefined, selected, "Salvar", (data) => {
                        data.produtor_id = this.state.produtor_id
                        centroProdutorService.udpateCentroProdutor(data, this.handleUpdateResponse)
                    }).current.open()
                }else{
                    toast.warn("Este produtor está INATIVO, para editar C.P. primeiro você deve ativá-lo")
                }
            }
        }
    ]

    handleUpdateResponse(response){
        if(validateShowResponseToast(response, 'Centro Produtor', true, true, "Centro Produtor atualizado com sucesso!")){
            this.state.paginate.updateRow('id', response.data)
        }
    }

    handleCreateResponse(response){
        if(validateShowResponseToast(response, 'Centro Produtor')){
            this.state.paginate.updateRow('id', response.data)
            console.log(response)
        }
    }

    render(){
        return(
            <ObjectTable 
                headRows={headRowsCentroProdutores} 
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'
                
                ref={this.state.paginate.objectTableRef}
                rows={this.state.paginate.rows || []}
                count={this.state.paginate.count}
                handleLoadPage={this.state.paginate.handleLoadPage}
                perPage={this.state.paginate.perPage}
                from={this.state.paginate.from}
                to={this.state.paginate.to}
                />
        )
    }
}

export default TableCentroProdutor