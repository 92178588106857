import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {FormControl, TextField, Container, InputLabel, NativeSelect} from '@material-ui/core'
import { convertStatusToNumber } from '../../util/utils'
import { makeAlertDialog } from '../dialogs/DialogRefInterface';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: '200px',
      maxHeight: '200px',
      minWidht:  '400px',
      maxWidht:  '400px'
  },
  formControl: {
    minWidth: "200px"
  },
  container: {
      marginTop: "30px"
  }
})

class FormAnaliseFile extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data.id,
            descricao: this.props.data ? this.props.data.descricao || "" : "",
            status: this.props.data.status,

            isUpdate: this.props.data.id > 0 
        }

        this.handleFocus  = this.handleFocus.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleFocus(e){
        if(this.state.isUpdate){
            makeAlertDialog(()=>{this.setState({isUpdate: false})}, 
            "Atenção", "Atenção! Alterar o número de um Centro Produtor pode afetar coletas futuras e consequentemente a Folha de Pagamento!")
            .current.open()
        }
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <Container className={classes.container}>
                    <TextField
                        id="descricao"
                        label="Descrição"
                        name="descricao"
                        fullWidth
                        type="text"
                        value={this.state.descricao}
                        onChange={this.handleChange}
                        >
                    </TextField>
                </Container>
                <Container className={classes.container} >
                    <NativeSelect
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                        >
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                    </NativeSelect>
                </Container>
           </FormControl>
         </form>
    )
        
    }
}

export default withStyles(styles)(FormAnaliseFile)