import React from 'react'
import {DeleteRounded, AddCircleOutlineRounded, EditRounded, RepeatOneSharp} from '@material-ui/icons'

import {headRowsBonificacao} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { bonificacaoService } from '../../services/bonificacao_service';
import { folhaService } from '../../services/folha_service';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import FormFolhaBonificacao from './FormFolhaBonificacao';

class TableFolhaBonificacao extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: this.props.rows
        }
    }

    action = (handleSave) => {
        handleSave(this.state.rows)
    }

    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaBonificacao, "Editar Bonificação", null, selected, "Salvar", (data) => {
                    folhaService.updateBonificacao(data, (response)=>{
                        if(validateShowResponseToast(response)){
                            let nRows = this.state.rows.map(row =>{ 
                                if(row.id == response.data.id){
                                    row = response.data
                                }
                                return row
                            })
                            this.setState({
                                rows: nRows
                            })
                        }
                    })
                }).current.open()
            },
            color: 'primary'
        }, 
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                folhaService.deleteBonificacao(selected.id, (response) => {
                    if(validateShowResponseToast(response)){
                        let newRows = this.state.rows.filter(v => v.id != selected.id)
                        this.setState({
                            rows: newRows
                        })
                    }
                })
            },
            color: 'secondary'
        }
    ]

    toolActions = [
   
    ]

    selectedToolActions = [

    ]

    

    render(){
        return(
            <ObjectTable 
                tableName='Bonificações' 
                headRows={headRowsBonificacao} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='fator_bonificacao_id'/>
        )
    }
}

export default TableFolhaBonificacao