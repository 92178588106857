import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    OutlinedInput, 
    TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { userService } from '../../services/user_service'
import { validateShowResponseToast } from '../../helpers/toast_alerts'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 500,
      maxHeigth: 500,
      maxWidth:  400,
  },
  formControl: {
      marginTop: "20px",
  },
  
})

class FormUsuario extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nome: this.props.data ? this.props.data.nome || "" : "",
            usuario: this.props.data ? this.props.data.usuario || "" : "",
            senha: "",
            confirma_senha: "",
            email: this.props.data ? this.props.data.email || "" : "",
            permissao: this.props.data ? this.props.data.permissao || "" : "",
            status: this.props.data ? this.props.data.status : 1,

            isUpdateSelf: false,
            isNivelAlto: false,
            isUpdate: this.props.data.usuario ? true : false
        }

        this.handleSave   = this.handleSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleGetUsuario = this.handleGetUsuario.bind(this)
    }

    componentWillMount(){
        userService.getUsuario(this.handleGetUsuario)
    }

    handleGetUsuario(response){
        if(validateShowResponseToast(response, undefined, false, true)){
            if(this.props.data){
                if(this.props.data.id === response.data.id){
                    this.setState({
                        isUpdateSelf: true,
                        isNivelAlto: response.data.permissao === 3
                    })
                }
            }
        }
    }

    handleSave(e){
        console.log("saved")
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.state.confirma_senha === this.state.senha){
            if(this.props.handleFormState){
                this.props.handleFormState(this.state)
            }
        }else{
            toast.warn('Senhas não conferem')
        }
        
    }

 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth >
                <TextField
                    id="nome"
                    label="Nome Completo"
                    required={true}
                    name="nome"
                    fullWidth
                    type="text"
                    value={this.state.nome}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl>   
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="usuario"
                    required={true}
                    label="Nome de Usuário"
                    name="usuario"
                    fullWidth
                    type={this.state.isUpdate && !this.state.isUpdateSelf ? 'password' : 'text'}
                    disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                    value={this.state.usuario}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl> 
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="email"
                    label="Email"
                    required={true}
                    name="email"
                    fullWidth
                    disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                    type='text'
                    value={this.state.email}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl>
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="senha"
                    label="Senha"
                    required={true}
                    name="senha"
                    fullWidth
                    type="password"
                    disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                    value={this.state.senha}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl>
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="confirma_senha"
                    label="Confirmar Senha"
                    required={true}
                    name="confirma_senha"
                    fullWidth
                    disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                    type="password"
                    value={this.state.confirma_senha}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel permissao
                    required
                   >
                    Nível de Permissão
                </InputLabel>
                <Select
                value={this.state.permissao}
                onChange={this.handleChange}
                label="Nível de Permissão"
                required={true}
                disabled={(this.state.isUpdateSelf && this.state.isUpdate)}
                fullWidth
                input={
                    <OutlinedInput
                      labelWidth={150}
                      name="permissao"
                    />
                  }>
                    <MenuItem value={1}>BAIXO</MenuItem>
                    <MenuItem value={2}>MÉDIO</MenuItem>
                    <MenuItem value={3}>ALTO</MenuItem>
                </Select>
           </FormControl>
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel 
                    required
                    >
                    Status
                </InputLabel>
                <Select
                value={this.state.status}
                onChange={this.handleChange}
                label="Status"
                disabled={(this.state.isUpdateSelf && this.state.isUpdate)}
                required
                fullWidth
                input={
                    <OutlinedInput
                      labelWidth={80}
                      name="status"
                    />
                  }>
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={0}>Inativo</MenuItem>
                </Select>
           </FormControl>
      
         </form>
    )
        
    }
}

export default withStyles(styles)(FormUsuario)