import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

function ObjectTableToolbar(props) {
  const classes = useToolbarStyles();
  const { selected, numSelected, selectedToolActions, toolActions, tableName } =
    props;

  function ActionTooltip(props) {
    const { title, label, handleClick, MaterialIcon, color } = props;
    return (
      <Tooltip title={title}>
        <IconButton
          aria-label={label}
          onClick={(e) => handleClick(e, selected)}
        >
          <MaterialIcon color={color || "primary"} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          numSelected > 1 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} Selecionados
            </Typography>
          ) : (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} Selecionado
            </Typography>
          )
        ) : (
          <Typography variant="h6" id="tableTitle">
            {tableName}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Toolbar>
            {selectedToolActions.map((selActs) => {
              return (
                <ActionTooltip
                  title={selActs.title}
                  label={selActs.label}
                  handleClick={(e) => selActs.handleClick(e, selected)}
                  MaterialIcon={selActs.MaterialIcon}
                  color={selActs.color}
                />
              );
            })}
          </Toolbar>
        ) : (
          <Toolbar>
            {toolActions.map((acts) => {
              return (
                <ActionTooltip
                  title={acts.title}
                  label={acts.label}
                  handleClick={acts.handleClick}
                  MaterialIcon={acts.MaterialIcon}
                  color={acts.color}
                />
              );
            })}
          </Toolbar>
        )}
      </div>
    </Toolbar>
  );
}

ObjectTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default ObjectTableToolbar;
