import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import "moment/locale/pt-br";
import {
  formatMoney,
  formatDecimal,
  getFullElementoName,
  formatElemento,
} from "../../util/utils";
import { makeTable } from "./jspdf_override";
import { makeRows, makeSubCols, makeCols, fillCols } from "./template_analise";

(function (API) {
  API.writeText = function (txt, options, x, y) {
    options = options || {};
    var fontSize = this.internal.getFontSize();
    var pageWidth = this.internal.pageSize.width;
    let txtWidth =
      (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

    if (options.align == "center") {
      x = (pageWidth - txtWidth) / 2;
    } else if (options.align == "left") {
      x = 2;
    } else if (options.align == "right") {
      x = pageWidth - txtWidth - 2;
    }
    this.text(txt, x, y);
    return x + txtWidth;
  };
})(jsPDF.API);

(function (API) {
  const newLocal = (API.makeHorizontalLine = function (initVerticalLine) {
    this.line(
      0,
      initVerticalLine,
      this.internal.pageSize.width,
      initVerticalLine
    );
  });
})(jsPDF.API);

const defaultParams = {
  initialLine: 0,

  smallVerticalSpace: 2,
  mediumVerticalSpace: 5,
  bigVerticalSpace: 10,

  lineVerticalSapace: 2,
  lineHorizontalSpace: 2,

  smallHorizontalSpace: 2,
  mediumHorizontalSpace: 5,
  bigHorizontalSpace: 10,

  tableSize: 20,
  smallFont: 8,
  mediumFont: 12,
  bigFont: 24,
  fontColorPrimary: [0, 0, 0],
  fontColorSecondary: [0, 0, 255],
  fontColorTertiary: [255, 0, 0],

  headerTitle: "LATICÍNIOS PALMITAL LTDA",
  subtitle: "Palmital Município de Cabeceira Grande - MG",

  labelPeriodo: "Pagamento referente ao período de",
  labelFornecedor: "Fornecedor :",
  labelMatricula: "Matrícula Nº :",
  labelTotalLitros: "TOTAL DE LEITE ENTREGUE >>> ",
  labelLitros: "Litros",
  labelReais: "Reais",
  labelTableDias: "RELAÇÃO DA QUANTIDADE DE LEITE ENTREGUE",
  labelDia: "Dia",
  lableLeite: "Leite",
  labelComposicao: "COMPOSIÇÂO DO PREÇO DO LEITE",
  labelPrecoBase: "PREÇO BASE",
  labelPrecoExcesso: "PREÇO DE EXCESSO",
  labelQualidadeLeite: "RESUMO BONIFICAÇÕES / PENALIDADES",
  labelPrecoMedioLitro: "PREÇO MÉDIO DO LITRO >>>",
  labelPrecoTotal: "PREÇO TOTAL DO LEITE >>>",
  labelValorTotal: "Valor total do leite",
  labelDescontos: "DESCONTOS",
  labelTotalDescontos: "TOTAL DOS DESCONTOS >>>",
  labelValorTotalReceber: "VALOR TOTAL A RECEBER >>>",
  labelMediaGeometrica:
    "PROGRAMA NACIONAL DE QUALIDADE DO LEITE (Média Geométrica das últimas 3 análises)",
  labelLegenda:
    "Valores fora dos parâmetros estão em negrito. Valores com -> indicam ajustes, apenas como quesito de melhoria no preço do leite",
};

function makeTableFolha(doc, cols = [], rows = [], initVerticalLine) {
  doc.autoTable({
    head: [cols],
    body: [rows],
    theme: "grid",
    headStyles: {},
    styles: { halign: "center" },
    startY: initVerticalLine,
  });
}

function makeComposicaoDesconto(folha) {
  let taxas = folha.folha_taxas;
  let valores = folha.folha_valores;
  let composicoes = [
    ["Preço Base", formatMoney(folha.valor_total)],
    [
      "Qualidade do leite (Bonificações / Penalidades)",
      formatMoney(folha.total_bonificacao),
    ],
  ];
  let totalComposicao =
    Number(folha.valor_total) + Number(folha.total_bonificacao);

  let descontos = [];
  let totalDescontos = 0;
  taxas.map((taxa) => {
    let valor = folha.valor_total * (taxa.porcentagem / 100);
    let vet = [`${taxa.descricao} (${taxa.porcentagem}%)`, formatMoney(valor)];
    if (taxa.porcentagem >= 0) {
      composicoes.push(vet);
      totalComposicao += valor;
    } else {
      descontos.push(vet);
      totalDescontos += valor;
    }
  });
  valores.map((val) => {
    const valor = Number(val.valor);
    let vet = [val.descricao, formatMoney(valor)];
    if (val.valor >= 0) {
      composicoes.push(vet);
      totalComposicao += valor;
    } else {
      descontos.push(vet);
      totalDescontos += valor;
    }
  });

  return {
    composicao: composicoes,
    totalComposicao: totalComposicao,
    desconto: descontos,
    totalDesconto: totalDescontos,
  };
}

function makeColetas(folha) {
  let cols = [];
  let rows = [];
  let cont = 0;
  let cls = ["Dia"];
  let rws = ["Leite"];
  const coletas = folha.folha_coletas.sort((a, b) => {
    if (moment(a.data_coleta).isAfter(b.data_coleta)) {
      return 1;
    }
    if (moment(a.data_coleta).isBefore(b.data_coleta)) {
      return -1;
    }
    return 0;
  });

  coletas.map((coleta) => {
    if (cont > 0 && cont % 12 == 0) {
      cols.push(cls);
      rows.push(rws);
      cls = ["Dia"];
      rws = ["Leite"];
    }
    cls.push(moment(coleta.data_coleta).format("DD/MM"));
    rws.push(coleta.qtd_litros);
    cont++;
  });
  if (cls.length > 1) {
    cols.push(cls);
    rows.push(rws);
  }
  return { cols: cols, rows: rows };
}

function makePeriodoFolha(folha) {
  if (
    moment(folha.data_inicio).format("M") !==
    moment(folha.data_final).format("M")
  ) {
    return `${moment(folha.data_inicio)
      .locale("pt-BR")
      .format("LL")} a ${moment(folha.data_final)
      .locale("pt-BR")
      .format("LL")}`;
  } else {
    return `${moment(folha.data_inicio).format("D")} a ${moment(
      folha.data_final
    )
      .locale("pt-BR")
      .format("LL")}`;
  }
}

export function template(folhas = [], configParams = {}, colsData = []) {
  let params = defaultParams;
  for (const key in configParams) {
    params[key] = configParams[key];
  }
  let cont = 0;
  let doc = new jsPDF("p", "mm", "a4");
  doc.setFont("times");
  folhas.map((folha) => {
    if (cont > 0) {
      doc.addPage();
    }

    const labelPeriodo = makePeriodoFolha(folha);
    let coletas = makeColetas(folha);
    let composicaoDescontos = makeComposicaoDesconto(folha);
    let composicao = composicaoDescontos.composicao;
    let valorBruto = composicaoDescontos.totalComposicao;
    let desconto = composicaoDescontos.desconto;
    let totalDesconto = composicaoDescontos.totalDesconto;
    let precoMedio = folha.total_litro > 0 ? valorBruto / folha.total_litro : 0;

    let initVerticalLine = params.initialLine + params.bigVerticalSpace;
    let horizontalLine = params.smallHorizontalSpace;

    // Titulo e subtitulo
    doc.setFontSize(params.bigFont);
    doc.writeText(params.headerTitle, { align: "center" }, 0, initVerticalLine);
    doc.setFontSize(params.mediumFont);
    initVerticalLine += params.mediumVerticalSpace;
    doc.writeText(params.subtitle, { align: "center" }, 0, initVerticalLine);
    initVerticalLine += params.mediumVerticalSpace;
    doc.makeHorizontalLine(initVerticalLine);
    // Fim Titulo

    // Cabecalho
    initVerticalLine += params.bigVerticalSpace;
    doc.setTextColor(0, 0, 0);
    horizontalLine = doc.writeText(
      params.labelPeriodo,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc.setFontType("bold");
    doc.writeText(
      labelPeriodo,
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.bigHorizontalSpace;
    doc.setFontType("normal");
    horizontalLine = doc.writeText(
      params.labelFornecedor,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc
      .setFontSize(params.mediumFont)
      .setTextColor(...params.fontColorSecondary);
    horizontalLine = doc.writeText(
      folha.produtor,
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorPrimary);
    horizontalLine = doc.writeText(
      params.labelMatricula,
      {},
      horizontalLine + params.bigHorizontalSpace,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorSecondary);
    doc.writeText(
      String(folha.numero_centro_produtor),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    doc.makeHorizontalLine(initVerticalLine);
    // Fim Cabecalho

    // Tabela Relacao de dias
    initVerticalLine += params.mediumVerticalSpace;
    doc.setFontSize(params.smallFont);
    doc.setTextColor(...params.fontColorPrimary);
    doc.writeText(
      params.labelTableDias,
      { align: "center" },
      0,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    for (let i = 0; i < coletas.cols.length; i++) {
      makeTableFolha(doc, coletas.cols[i], coletas.rows[i], initVerticalLine);
      initVerticalLine += params.tableSize;
    }
    initVerticalLine += params.mediumHorizontalSpace;
    doc.setFontSize(params.mediumFont);
    doc.setTextColor(...params.fontColorPrimary);
    horizontalLine = doc.writeText(
      params.labelTotalLitros,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorSecondary);
    horizontalLine = doc.writeText(
      formatDecimal(folha.total_litro, 0),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorPrimary);
    doc.writeText(
      params.labelLitros,
      {},
      horizontalLine + params.smallHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    doc.makeHorizontalLine(initVerticalLine);
    // Fim Tabela

    // Composicao preco leite
    initVerticalLine += params.mediumVerticalSpace;
    doc.setFontSize(params.smallFont);
    doc.writeText(
      params.labelComposicao,
      { align: "center" },
      0,
      initVerticalLine
    );
    initVerticalLine += params.mediumHorizontalSpace;
    doc.autoTable({
      head: [["Descrição", "Valor (R$)"]],
      body: composicao,
      theme: "grid",
      headStyles: {},
      styles: { halign: "left" },
      columnStyles: {
        0: { halign: "left", minCellWidth: 90, maxCellWith: 90 },
        1: { minCellWidth: 10, maxCellWith: 10 },
      },
      startY: initVerticalLine,
      didDrawPage: (HookData) => {
        initVerticalLine += HookData.table.height;
      },
    });
    initVerticalLine += params.bigVerticalSpace;
    doc.setFontSize(params.mediumFont);
    horizontalLine = doc.writeText(
      params.labelPrecoTotal,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorSecondary);
    horizontalLine = doc.writeText(
      formatMoney(valorBruto),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorPrimary);
    doc.writeText(
      params.labelReais,
      {},
      horizontalLine + params.smallHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace + params.smallVerticalSpace;
    horizontalLine = doc.writeText(
      params.labelPrecoMedioLitro,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorSecondary);
    doc.writeText(
      formatMoney(precoMedio),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    doc.makeHorizontalLine(initVerticalLine);
    // Composicao

    // Descontos
    initVerticalLine += params.mediumVerticalSpace;
    doc.setTextColor(...params.fontColorPrimary);
    doc.setFontSize(params.smallFont);
    doc.writeText(
      params.labelDescontos,
      { align: "center" },
      0,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    if (totalDesconto < 0) {
      doc.autoTable({
        head: [["Descrição", "Valor (R$)"]],
        body: desconto,
        theme: "grid",
        headStyles: {},
        columnStyles: {
          0: { halign: "left", minCellWidth: 90, maxCellWith: 90 },
          1: { minCellWidth: 10, maxCellWith: 10 },
        },
        startY: initVerticalLine,
        didDrawPage: (HookData) => {
          initVerticalLine += HookData.table.height;
        },
      });
    }
    initVerticalLine += params.bigVerticalSpace;
    doc.setFontSize(params.mediumFont);
    horizontalLine = doc.writeText(
      params.labelTotalDescontos,
      { align: "left" },
      0,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorTertiary);
    horizontalLine = doc.writeText(
      formatMoney(totalDesconto),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      initVerticalLine
    );
    doc.setTextColor(...params.fontColorPrimary);
    doc.writeText(
      params.labelReais,
      {},
      horizontalLine + params.smallHorizontalSpace,
      initVerticalLine
    );
    initVerticalLine += params.mediumVerticalSpace;
    doc.makeHorizontalLine(initVerticalLine);
    //

    if (folha.analises.length > 0) {
      // Inicio Media Geometrica
      const values = {
        gordura: 3.4,
        proteina: 3.2,
        esd: 8.4,
        ccs: 500,
        ufc_curva: 300,
      };
      const simbols = {
        gordura: "",
        proteina: "",
        esd: "",
        ccs: "",
        ufc_curva: "",
      };
      colsData.map((dt) => {
        values[dt.elemento] = dt.valor;
        simbols[dt.elemento] = dt.simbolo;
      });

      initVerticalLine += params.mediumVerticalSpace;
      doc.setTextColor(...params.fontColorPrimary);
      doc.setFontSize(params.smallFont);
      doc.writeText(
        params.labelMediaGeometrica,
        { align: "center" },
        0,
        initVerticalLine
      );
      initVerticalLine += params.mediumVerticalSpace;
      const tableSize = makeTable(
        doc,
        fillCols(
          makeCols(
            values.gordura,
            values.proteina,
            values.esd,
            values.ccs,
            values.ufc_curva
          ),
          makeSubCols()
        ),
        makeRows(folha.analises),
        initVerticalLine,
        8,
        {
          1: { simbol: simbols.gordura, value: values.gordura },
          3: { simbol: simbols.proteina, value: values.proteina },
          7: { simbol: simbols.esd, value: values.esd },
          8: { simbol: simbols.ccs, value: values.ccs },
          10: { simbol: simbols.ufc_curva, value: values.ufc_curva },
        }
      );
      initVerticalLine += tableSize + params.mediumVerticalSpace;
      doc.setTextColor(...params.fontColorPrimary);
      doc.setFontSize(params.smallFont);
      doc.writeText(
        params.labelLegenda,
        { align: "left" },
        0,
        initVerticalLine
      );
      // Fim Media Geometrica
    }

    initVerticalLine += params.mediumVerticalSpace;
    doc.setTextColor(...params.fontColorPrimary);
    doc.setFontSize(params.mediumFont);
    doc.makeHorizontalLine(
      doc.internal.pageSize.height - params.bigVerticalSpace
    );
    horizontalLine = doc.writeText(
      params.labelValorTotalReceber,
      { align: "left" },
      0,
      doc.internal.pageSize.height - params.mediumVerticalSpace
    );
    doc.setTextColor(...params.fontColorSecondary);
    doc.setFontType("");
    horizontalLine = doc.writeText(
      formatMoney(folha.valor_liquido, 2),
      {},
      horizontalLine + params.mediumHorizontalSpace,
      doc.internal.pageSize.height - params.mediumVerticalSpace
    );
    doc.setTextColor(...params.fontColorPrimary);
    doc.writeText(
      params.labelReais,
      {},
      horizontalLine + params.smallHorizontalSpace,
      doc.internal.pageSize.height - params.mediumVerticalSpace
    );
    doc.makeHorizontalLine(
      doc.internal.pageSize.height - params.smallVerticalSpace
    );

    cont++;
  });

  /** 

	*/

  return doc;
}
