const ID = "id";

export const headRowsFolha = [
  {
    id: "produtor",
    numeric: false,
    disablePadding: false,
    label: "Produtor",
    type: "name",
  },
  {
    id: "numero_centro_produtor",
    numeric: true,
    disablePadding: false,
    label: "Centro Produtor",
  },
  {
    id: "data_inicio",
    numeric: true,
    disablePadding: false,
    label: "Data Inicial",
    type: "date",
  },
  {
    id: "data_final",
    numeric: true,
    disablePadding: false,
    label: "Data Final",
    type: "date",
  },
  {
    id: "media_litro",
    numeric: true,
    disablePadding: false,
    label: "Média Diária(Lts.)",
    type: "precision2",
  },
  {
    id: "valor_litro",
    numeric: true,
    disablePadding: false,
    label: "Valor Litro",
    type: "money2",
  },
  {
    id: "bonus_litro",
    numeric: true,
    disablePadding: false,
    label: "Bônus Litro",
    type: "money",
  },
  {
    id: "valor_total",
    numeric: true,
    disablePadding: false,
    label: "Valor Base",
    type: "money",
  },
  {
    id: "valor_liquido",
    numeric: true,
    disablePadding: false,
    label: "Valor Líquido",
    type: "money",
  },
];

export const headRowsFolhaSimple = [
  {
    id: "produtor",
    numeric: false,
    disablePadding: false,
    label: "Produtor",
    type: "name",
  },
  {
    id: "data_inicio",
    numeric: true,
    disablePadding: false,
    label: "Data Inicial",
    type: "date",
  },
  {
    id: "valor_total",
    numeric: true,
    disablePadding: false,
    label: "Valor Base",
    type: "money",
  },
  {
    id: "valor_liquido",
    numeric: true,
    disablePadding: false,
    label: "Valor Líquido",
    type: "money",
  },
];

export const headRowsAnalises = [
  { id: "id", numeric: true, disablePadding: false, label: ID },
  {
    id: "nome_produtor",
    numeric: true,
    disablePadding: false,
    label: "Produtor",
  },
  {
    id: "centro_produtor_id",
    numeric: true,
    disablePadding: false,
    label: "Centro Produtor",
  },
  {
    id: "cod_recepcao",
    numeric: true,
    disablePadding: false,
    label: "Cod. Recepção",
  },
  {
    id: "cod_setor",
    numeric: true,
    disablePadding: false,
    label: "Cod. Setor",
  },
  {
    id: "data_resultado",
    numeric: true,
    disablePadding: false,
    label: "Data Resultado",
    type: "date",
  },
  { id: "protocolo", numeric: true, disablePadding: false, label: "Protocolo" },
  { id: "amostra1", numeric: true, disablePadding: false, label: "Amostra 1" },
  { id: "amostra2", numeric: true, disablePadding: false, label: "Amostra 2" },
  { id: "cqam1", numeric: true, disablePadding: false, label: "CQAM-1" },
  { id: "cqam2", numeric: true, disablePadding: false, label: "CQAM-2" },
  { id: "cqam3", numeric: true, disablePadding: false, label: "CQAM-3" },
  {
    id: "gordura",
    numeric: false,
    disablePadding: false,
    label: "Gordura",
    type: "precision2",
  },
  {
    id: "proteina",
    numeric: true,
    disablePadding: false,
    label: "Proteína",
    type: "precision2",
  },
  {
    id: "lactose",
    numeric: false,
    disablePadding: false,
    label: "Lactose",
    type: "precision2",
  },
  {
    id: "solidos",
    numeric: false,
    disablePadding: false,
    label: "Sólidos",
    type: "precision2",
  },
  { id: "ccs", numeric: true, disablePadding: false, label: "CCS" },
  {
    id: "esd",
    numeric: false,
    disablePadding: false,
    label: "ESD",
    type: "precision2",
  },
  {
    id: "urea",
    numeric: false,
    disablePadding: false,
    label: "Ureia",
    type: "precision2",
  },
  {
    id: "caseina",
    numeric: false,
    disablePadding: false,
    label: "Caseina",
    type: "precision2",
  },
  { id: "ufc_curva", numeric: true, disablePadding: false, label: "CBT" },
  {
    id: "crioscopia",
    numeric: true,
    disablePadding: false,
    label: "Crioscopia",
  },
];

export const headRowsAnalisesSimple = [
  { id: "id", numeric: true, disablePadding: false, label: ID },
  {
    id: "nome_produtor",
    numeric: false,
    disablePadding: false,
    label: "Produtor",
  },
  {
    id: "data_resultado",
    numeric: true,
    disablePadding: false,
    label: "Data Resultado",
    type: "date",
  },
  {
    id: "gordura",
    numeric: false,
    disablePadding: false,
    label: "Gordura",
    type: "precision2",
  },
  {
    id: "proteina",
    numeric: true,
    disablePadding: false,
    label: "Proteína",
    type: "precision2",
  },
  {
    id: "lactose",
    numeric: false,
    disablePadding: false,
    label: "Lactose",
    type: "precision2",
  },
  { id: "ccs", numeric: false, disablePadding: false, label: "CCS" },
  {
    id: "esd",
    numeric: false,
    disablePadding: false,
    label: "ESD",
    type: "precision2",
  },
  { id: "ufc_curva", numeric: false, disablePadding: false, label: "CBT" },
  {
    id: "crioscopia",
    numeric: false,
    disablePadding: false,
    label: "Crioscopia",
    type: "precision2",
  },
];

export const headRowsFormAnalises = [
  {
    id: "nome_produtor",
    numeric: true,
    disablePadding: false,
    label: "Centro Produtor",
  },
  {
    id: "produtor",
    numeric: true,
    disablePadding: false,
    label: "Cod. Produtor",
  },
  //{ id: 'filial', numeric: true, disablePadding: false, label: 'Filial'},
  //{ id: 'recepcao', numeric: true, disablePadding: false, label: 'Recepção'},
  //{ id: 'cod_recepcao', numeric: true, disablePadding: false, label: 'Cod. Recepção'},
  //{ id: 'setor', numeric: true, disablePadding: false, label: 'Setor'},
  //{ id: 'cod_setor', numeric: true, disablePadding: false, label: 'Cod. Setor'},
  {
    id: "data_resultado",
    numeric: true,
    disablePadding: false,
    label: "Data Resultado",
    type: "date",
  },
  //{ id: 'protocolo', numeric: true, disablePadding: false, label: 'Protocolo'},
  //{ id: 'amostra1', numeric: true, disablePadding: false, label: 'Amostra 1'},
  //{ id: 'amostra2', numeric: true, disablePadding: false, label: 'Amostra 2'},
  //{ id: 'cqam1', numeric: true, disablePadding: false, label: 'CQAM-1'},
  //{ id: 'cqam2', numeric: true, disablePadding: false, label: 'CQAM-2'},
  //{ id: 'cqam3', numeric: true, disablePadding: false, label: 'CQAM-3'},
  {
    id: "gordura",
    numeric: true,
    disablePadding: false,
    label: "Gordura",
    type: "precision2",
  },
  {
    id: "proteina",
    numeric: true,
    disablePadding: false,
    label: "Proteína",
    type: "precision2",
  },
  {
    id: "lactose",
    numeric: true,
    disablePadding: false,
    label: "Lactose",
    type: "precision2",
  },
  {
    id: "solidos",
    numeric: true,
    disablePadding: false,
    label: "Sólidos",
    type: "precision2",
  },
  { id: "ccs", numeric: true, disablePadding: false, label: "CCS" },
  {
    id: "esd",
    numeric: true,
    disablePadding: false,
    label: "ESD",
    type: "precision2",
  },
  {
    id: "urea",
    numeric: true,
    disablePadding: false,
    label: "Ureia",
    type: "precision2",
  },
  {
    id: "caseina",
    numeric: false,
    disablePadding: false,
    label: "Caseina",
    type: "precision2",
  },
  { id: "ufc_curva", numeric: true, disablePadding: false, label: "UFC Curva" },
  {
    id: "crioscopia",
    numeric: true,
    disablePadding: false,
    label: "Crioscopia",
    type: "precision2",
  },
  {
    id: "numero_cp",
    numeric: true,
    disablePadding: false,
    label: "Cod. Produtor(Corrigido)",
  },
];

export const headRowsFormAnalisesSimple = [
  {
    id: "nome_produtor",
    numeric: true,
    disablePadding: false,
    label: "Nome do Produtor",
  },
  {
    id: "data_resultado",
    numeric: true,
    disablePadding: false,
    label: "Data Resultado",
    type: "date",
  },
  {
    id: "produtor",
    numeric: true,
    disablePadding: false,
    label: "Cod. Produtor(Análise)",
  },
  {
    id: "numero_cp",
    numeric: true,
    disablePadding: false,
    label: "Cod. Produtor(Corrigido)",
  },
];

export const headRowsAnaliseFile = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "data_analise",
    numeric: false,
    disablePadding: false,
    label: "Data da Análise",
    type: "date",
  },
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
];

export const headRowsBonificacao = [
  {
    id: "fator_bonificacao_id",
    numeric: false,
    disablePadding: false,
    label: ID,
  },
  {
    id: "analise_file",
    numeric: false,
    disablePadding: false,
    label: "Análise",
  },
  {
    id: "elemento",
    numeric: false,
    disablePadding: false,
    label: "Elemento",
    type: "element",
  },
  {
    id: "taxa_elemento",
    numeric: true,
    disablePadding: true,
    label: "Taxa do Elemento",
    type: "precision2",
  },
  {
    id: "valor_bonus",
    numeric: true,
    disablePadding: false,
    label: "Valor do Bônus",
    type: "money",
  },
];

export const headRowsFolhaValores = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "valor",
    numeric: false,
    disablePadding: false,
    label: "Valor Total",
    type: "money",
  },
  {
    id: "multiplicador",
    numeric: false,
    disablePadding: false,
    label: "Multiplicador",
    type: "custom",
    handleCustom: (value) => {
      if (value === 1) return "Nenhum";
      else if (value == -1) return "Litros de Leite";
    },
  },
];

export const headRowsFolhaTaxas = [
  { id: "taxa_impactante_id", numeric: true, disablePadding: false, label: ID },
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "porcentagem",
    numeric: true,
    disablePadding: false,
    label: "Porcentagem",
  },
];

export const headRowsColetas = [
  { id: "id", numeric: true, disablePadding: false, label: ID },
  { id: "produtor", numeric: false, disablePadding: false, label: "Produtor" },
  {
    id: "numero_centro_produtor",
    numeric: true,
    disablePadding: false,
    label: "Centro Produtor",
  },
  {
    id: "data_coleta",
    numeric: false,
    disablePadding: false,
    label: "Data da Coleta",
    type: "date",
  },
  {
    id: "qtd_litros",
    numeric: true,
    disablePadding: false,
    label: "Qtd. de Litros",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
  {
    id: "relatorio_status",
    numeric: false,
    disablePadding: false,
    label: "Mostrar no Relatório",
    type: "status",
  },
  {
    id: "observacao",
    numeric: false,
    disablePadding: false,
    label: "Observação",
    type: "text",
  },
];

export const headRowsProdutores = [
  { id: "id", numeric: false, disablePadding: true, label: ID },
  { id: "nome", numeric: false, disablePadding: false, label: "Nome" },
  {
    id: "matricula",
    numeric: true,
    disablePadding: false,
    label: "Matrícula no Laticínio",
  },
  {
    id: "inscricao_estadual",
    numeric: false,
    disablePadding: false,
    label: "Inscrição Estadual",
  },
  {
    id: "matricula_status",
    numeric: true,
    disablePadding: false,
    label: "Situação da Matrícula",
    type: "status",
  },
];

export const headRowsCentroProdutores = [
  { id: "id", numeric: false, disablePadding: true, label: ID },
  {
    id: "nome_fazenda",
    numeric: false,
    disablePadding: false,
    label: "Nome da Fazenda",
  },
  {
    id: "distancia_km",
    numeric: true,
    disablePadding: false,
    label: "Distância(Km)",
  },
  {
    id: "numero",
    numeric: true,
    disablePadding: false,
    label: "Número do C.P.",
  },
  { id: "ordenha", numeric: true, disablePadding: false, label: "Ordenha" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Situação",
    type: "status",
  },
];

export const headRowsPagamentos = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "data_pagamento",
    numeric: false,
    disablePadding: true,
    label: "Data do Pagamento",
    type: "date",
  },
  {
    id: "descricao",
    numeric: false,
    disablePadding: true,
    label: "Tipo de Pagamento",
  },
  {
    id: "valor",
    numeric: false,
    disablePadding: true,
    label: "Valor",
    type: "money",
  },
];

export const headRowsTiposPagamento = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  { id: "descricao", numeric: true, disablePadding: false, label: "Descrição" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
];

export const headRowsUsuarios = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  { id: "nome", numeric: false, disablePadding: false, label: "Nome" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "permissao",
    numeric: false,
    disablePadding: false,
    label: "Nível de Permissão",
    type: "custom",
    handleCustom: (value) => {
      if (value == 1) return "BAIXO";
      else if (value == 2) return "MEDIO";
      else if (value == 3) return "ALTO";
    },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
];

export const headRowsFatorBonificacoes = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "elemento",
    numeric: false,
    disablePadding: false,
    label: "Elemento",
    type: "element",
  },
  {
    id: "resultado_min",
    numeric: false,
    disablePadding: false,
    label: "Resultado Mínimo",
  },
  {
    id: "resultado_max",
    numeric: false,
    disablePadding: false,
    label: "Resultado Máximo",
  },
  {
    id: "valor_bonus",
    numeric: false,
    disablePadding: false,
    label: "Valor do Bônus",
    type: "money",
  },
];

export const headRowsTaxaImpactantes = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "porcentagem",
    numeric: false,
    disablePadding: false,
    label: "Porcentagem",
    type: "signed_number",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
];

export const headRowsValorImpactantes = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
];

export const headRowsPrecoBase = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "qtd_minima",
    numeric: false,
    disablePadding: false,
    label: "Quantidade Mínima (Lts/dia)",
  },
  {
    id: "qtd_maxima",
    numeric: false,
    disablePadding: false,
    label: "Quantidade Máxima (Lts/dia)",
  },
  {
    id: "valor_litro",
    numeric: false,
    disablePadding: false,
    label: "Valor do Litro",
    type: "money2",
  },
];

export const headRowsAdminLaticinio = [
  { id: "id", numeric: false, disablePadding: false, label: ID },
  {
    id: "nome",
    numeric: false,
    disablePadding: false,
    label: "Nome do Laticínio",
  },
  { id: "cnpj", numeric: false, disablePadding: false, label: "CNPJ" },
  {
    id: "razao_social",
    numeric: false,
    disablePadding: false,
    label: "Razão Social",
  },
  { id: "cidade", numeric: false, disablePadding: false, label: "Cidade" },
  { id: "cep", numeric: false, disablePadding: false, label: "CEP" },
  { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
  { id: "endereco", numeric: false, disablePadding: false, label: "Endereço" },
  {
    id: "complemento",
    numeric: false,
    disablePadding: false,
    label: "Complemento",
  },
  {
    id: "telefone1",
    numeric: false,
    disablePadding: false,
    label: "Telefone1",
  },
  {
    id: "telefone2",
    numeric: false,
    disablePadding: false,
    label: "Telefone2",
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    type: "status",
  },
  {
    id: "aprovacao_status",
    numeric: false,
    disablePadding: false,
    label: "Status da Aprovação",
    type: "custom",
    handleCustom: (value) => {
      if (value == 0) return "AGUARDANDO";
      else if (value == 1) return "APROVADO";
      else if (value == 2) return "REGISTRADO";
    },
  },
];

export const headRowsContabil = [
  {
    id: "centro_produtor",
    numeric: false,
    disablePadding: false,
    label: "Centro Produtor",
  },
  {
    id: "nome_fazendeiro",
    numeric: false,
    disablePadding: false,
    label: "Produtor",
  },
  {
    id: "nome_fazenda",
    numeric: false,
    disablePadding: false,
    label: "Fazenda",
  },
  {
    id: "inscricao_estadual",
    numeric: false,
    disablePadding: false,
    label: "Inscrição Estadual",
  },
  { id: "cpf", numeric: false, disablePadding: false, label: "CPF" },
  {
    id: "total_litros",
    numeric: false,
    disablePadding: false,
    label: "Total de Litros",
  },
  {
    id: "preco_unitario",
    numeric: false,
    disablePadding: false,
    label: "Preço Unitário(l)",
    type: "money2",
  },
  {
    id: "valor_base",
    numeric: false,
    disablePadding: false,
    label: "Valor Base",
    type: "money",
  },
  {
    id: "ip",
    numeric: false,
    disablePadding: false,
    label: "I.P.",
    type: "money",
  },
  {
    id: "valor_total",
    numeric: false,
    disablePadding: false,
    label: "Valor Total",
    type: "money",
  },
  {
    id: "inss",
    numeric: false,
    disablePadding: false,
    label: "INSS",
    type: "money",
  },
  {
    id: "valor_liquido",
    numeric: false,
    disablePadding: false,
    label: "Valor Líquido",
    type: "money",
  },
  {
    id: "icms",
    numeric: false,
    disablePadding: false,
    label: "ICMS",
    type: "money",
  },
];

export const headRowsMapaColetas = [
  { id: "produtor", numeric: false, disablePadding: false, label: "Produtor" },
  {
    id: "total_litro",
    numeric: false,
    disablePadding: false,
    label: "Total(L)",
  },
  {
    id: "centro_produtor",
    numeric: false,
    disablePadding: false,
    label: "Matr",
  },
];

export function getLabelAnalise(elemento) {
  let label = "";
  headRowsAnalises.map((hra) => {
    if (hra.id === elemento) {
      label = hra.label;
    }
  });
  return label;
}
