import axios from 'axios'
import { getAxiosInstance } from '../services/service'
import moment from 'moment'

export const coletaService = {
    getColetas,
    updateColeta,
    updateColetas,
    uploadColetas,
    getPerPage
}

export function makeColeta(centro_produtor, data_coleta, qtd_litros, id_externo){
    return {
        centro_produtor, 
        data_coleta: moment(data_coleta).format('YYYY-MM-DD'), 
        qtd_litros, 
        id_externo}
}

async function updateColeta(data, handleResponse=undefined, handleAction=undefined){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/coleta/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateColetas(data, handleResponse=undefined, handleAction=undefined){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/coleta/updateColetas`).post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function uploadColetas(url, handleResponse=undefined, handleAction=undefined){
    let coletas = []
    let error = undefined
    let response = 
    await axios.get(`${url}`)
    .catch(function (e) {
        error = e
        if(handleAction) handleAction()
    })
    if(response && response.data && response.data.data){
        response.data.data.forEach(dt => {
            let coleta = makeColeta(dt.codprodutor, dt.datahorachegada, dt.volumetotal, dt.id)
            coletas.push(coleta)
        })
        response = await getAxiosInstance('/coleta/uploadColeta').post(undefined, {data: coletas}).catch(e =>{
            error = e
            if(handleAction) handleAction() 
        })
    }
    if(! error){
        if(handleResponse) handleResponse(response)
        if(handleAction) handleAction()
    }else{
        if(handleResponse) handleResponse(error.response)
        if(handleAction) handleAction()
    }
  
}

async function getPerPage(pageUrl, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance().get(pageUrl).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getColetas(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/coleta/getColetas').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

