import React from 'react'
import {DeleteRounded, AddCircleOutlineRounded, EditRounded} from '@material-ui/icons'

import {headRowsFolhaTaxas} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { folhaService } from '../../services/folha_service';
import { validateShowResponseToast } from '../../helpers/toast_alerts';



class TableFolhaTaxa extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: this.props.rows
        }
    }

    action = (handleSave) => {
        handleSave(this.state.rows)
    }

    actions = [
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                folhaService.deleteTaxa(selected.id, (response) => {
                    if(validateShowResponseToast(response)){
                        let newRows = this.state.rows.filter(v => v.id != selected.id)
                        this.setState({
                            rows: newRows
                        })
                    }
                })
            },
            color: 'secondary'
        }
    ]
    
    selectedToolActions = [
       
    ] 
    
    toolActions = [
       
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Taxas' 
                headRows={headRowsFolhaTaxas} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='taxa_impactante_id'/>
        )
    }
}

export default TableFolhaTaxa