import { makeTable } from './jspdf_override'
import jsPDF  from './jspdf_override'
import 'moment/locale/pt-br'
import { formatValueByType } from '../../util/utils'
import { makeSum } from '../../util/relatorio_util';

function makeColsAndRows(data, filterRows){
	let ret = {cols: [], rows: []}
	data.map(dt => {
		let row = []
		filterRows.map(fr => {
			let value = formatValueByType(dt[fr['id']], fr.type)
			if(value === 'NaN') value = '----'
			row.push(value)
		})
		ret.rows.push(row)
	})
	ret.cols = filterRows.map(fr => {return fr.label})
	return ret
}

export function template_table(data=[], filterRows=[], pdfOrientation='p', title='', rowsSum=[], rowId=''){
	let doc = new jsPDF(pdfOrientation, 'mm', 'a4')
	doc.setFont('times')
	if(rowsSum.length > 0){
		data.push(makeSum(data, rowsSum, filterRows, rowId))
	}
	const colsAndRows = makeColsAndRows(data, filterRows)
	let initVerticalLine = 5
	doc.writeText(title, {align: 'center'}, 0, initVerticalLine)
	initVerticalLine += 5
	makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine)
	return doc
}