import { getAxiosInstance } from './service'

export const taxaService = {
    getTaxas,
    createTaxas,
    updateTaxas
}

async function getTaxas(handleResponse, handleAction, status=-1){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/taxaImpactante/status/${status}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createTaxas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/definir/taxaImpactante').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateTaxas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/definir/taxaImpactante/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
