import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { getPerPage } from "../../services/service";
import {
  closeProgressDialog,
  makeProgressDialog,
} from "../dialogs/DialogRefInterface";
import React from "react";

class Paginate {
  constructor(handleForceUpdate) {
    this.lastSearch = {};
    this.handleForceUpdate = handleForceUpdate;
    this.rows = [];
    this.count = 0;
    this.perPage = 0;
    this.from = 0;
    this.to = 0;
    this.isNew = true;
    this.nextPageUrl = undefined;
    this.prevPageUrl = undefined;

    this.handleGet = this.handleGet.bind(this);
    this.handleLoadPage = this.handleLoadPage.bind(this);
    this.setAttribute = this.setAttribute.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.objectTableRef = React.createRef();
  }

  setAttribute(attr, value) {
    if (attr) {
      this[attr] = value;
      this.handleForceUpdate();
    }
  }

  updateRow(id, value) {
    let isUpdate = false;
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i][id] === value[id]) {
        this.rows[i] = value;
        isUpdate = true;
        break;
      }
    }
    if (!isUpdate) this.rows.push(value);
    this.handleForceUpdate();
  }

  handleLoadPage(direction) {
    this.isNew = false;
    if (direction == 1 && this.nextPageUrl) {
      makeProgressDialog().current.open();
      getPerPage(this.nextPageUrl, this.handleGet, closeProgressDialog);
    } else if (direction == -1 && this.prevPageUrl) {
      makeProgressDialog().current.open();
      getPerPage(this.prevPageUrl, this.handleGetColetas, closeProgressDialog);
    }
  }

  handleGet(response) {
    let cont = response ? response.data.total : 0;
    if (
      validateShowResponseToast(
        response,
        undefined,
        true,
        true,
        `${cont} registros encontrados`
      )
    ) {
      this.rows = response.data.data;
      this.count = response.data.total;
      this.perPage = response.data.per_page;
      this.from = response.data.from;
      this.to = response.data.to;
      this.nextPageUrl = response.data.next_page_url;
      this.prevPageUrl = response.data.prev_page_url;
      this.handleForceUpdate();
    }
    closeProgressDialog();
  }
}

export default Paginate;
