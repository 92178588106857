import jsPDF from 'jspdf'
import 'jspdf-autotable'

(function(API){
    API.writeText = function(txt, options, x, y) {
		options = options ||{};
		var fontSize = this.internal.getFontSize();
		var pageWidth = this.internal.pageSize.width;
		let txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

        if( options.align === "center" ){
            x = ( pageWidth - txtWidth ) / 2;
        }else if(options.align === 'left'){
			x = 2
		}else if(options.align === 'right'){
			x = (pageWidth - txtWidth - 2)
		}
		this.text(txt,x,y);
		return x + txtWidth
    }
})(jsPDF.API);

(function(API){
    const newLocal = API.makeHorizontalLine = function (initVerticalLine) {
		this.line(0, initVerticalLine, this.internal.pageSize.width, initVerticalLine);
	};
})(jsPDF.API);

(function(API){
    const newLocal = API.makeHorizontalLine = function (initVerticalLine) {
		this.line(0, initVerticalLine, this.internal.pageSize.width, initVerticalLine);
	};
})(jsPDF.API);

export function makeTable(doc, cols=[], rows=[], initVerticalLine, fontSize=7, custom={}){
	let tableSize = 0
	doc.autoTable({
		head: cols,
		body: rows,
		theme: 'grid',
		headStyles: {},
		styles: {halign: 'center', fontSize},
		margin: 2,
		startY: initVerticalLine,
		didDrawPage: (HookData) => {
			tableSize = HookData.table.height
		},
		willDrawCell: (hook) => {
			if(hook.cell.section === 'body'){
					let rowValue = custom[hook.column.dataKey]
					if(hook.cell.raw === 'NaN'){
						hook.cell.text[0] = '----'
					}
					if(rowValue){
						const strValue = hook.cell.raw.replace('\.', '')
						if (`${strValue}`.indexOf('->') > -1){
							hook.doc.setTextColor(255, 0, 0)
							hook.doc.setFontType('bold')
						}
						else if(rowValue.simbol === '>'){
							const number = Number(strValue.replace(',', '\.'))
							if(number < rowValue.value){
								hook.doc.setTextColor(255, 0, 0)
								hook.doc.setFontType('bold')
								hook.doc.setFontSize(fontSize+1)
							}
						}else{
							const number = Number(strValue.replace(',', '\.'))
							if(number >= rowValue.value){
								hook.doc.setTextColor(255, 0, 0)
								hook.doc.setFontType('bold')
								hook.doc.setFontSize(fontSize+1)
							}
						}
					}
			}
		}
	})
	return tableSize
}
export default jsPDF