import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    FormControl, 
    TextField
} from '@material-ui/core'
import { bonificacaoService } from '../../services/bonificacao_service'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: 200,
      maxHeight: 200,
      minWidht:  200,
      maxWidht:  200
  },
  formControl: {
      marginTop: "25px",
  },
  
})

class FormFolhaBonificacao extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            taxa_elemento: this.props.data ? this.props.data.taxa_elemento || "" : "",
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    componentWillMount(){
        bonificacaoService.getElementosBonificacao(this.handleGetElementosResponse)
    }


 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="taxa_elemento"
                    label="Taxa do Elemento"
                    name="taxa_elemento"
                    fullWidth
                    type="number"
                    value={this.state.taxa_elemento}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
         </form>
    )
        
    }
}

export default withStyles(styles)(FormFolhaBonificacao)