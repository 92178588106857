import { getAxiosInstance } from '../services/service'

export const folhaService = {
    getFolhas,
    createFolhas,
    closeFolhas,
    updateFolhas,
    addBonificacoes,
    updateBonificacao,
    deleteBonificacao,
    addValor,
    deleteValor,
    addTaxa,
    deleteTaxa,
    addPagamento,
    deletePagamento,
    deleteFolhas,
}

async function getFolhas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/getFolhas').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createFolhas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateFolhas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/updateFolhas').put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function closeFolhas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento').put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deleteFolhas(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/remove').put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}


async function addBonificacoes(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/bonificacao').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateBonificacao(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/folhaPagamento/bonificacao/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deleteBonificacao(id, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/folhaPagamento/bonificacao/${id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function addValor(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/valor').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deleteValor(id, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/folhaPagamento/valor/${id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function addTaxa(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/taxa').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deleteTaxa(id, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/folhaPagamento/taxa/${id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function addPagamento(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/folhaPagamento/pagamento').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function deletePagamento(id, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/folhaPagamento/pagamento/${id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
