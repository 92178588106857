import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, Container, NativeSelect, InputLabel } from '@material-ui/core'
import { makeAlertDialog } from '../dialogs/DialogRefInterface';
import CustomDatePicker from '../default/CustomDatePicker';
import moment from 'moment';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,

    },
    form: {
        minHeigth: '300px',
        maxHeigth: '300px',
        minWidth: '400px',
        maxWidth: '400px'
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "30px"
    }
})

class FormFolhaColeta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ids_coletas: this.props.data ? this.props.data.ids_coletas || [] : [],
            observacao: this.props.data ? this.props.data.observacao || "" : "",
            qtd_litros: this.props.data ? this.props.data.qtd_litros || "" : "",
            data_coleta: this.props.data ? this.props.data.data_coleta || moment().format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD'),
            status: this.props.data.status,
            relatorio_status: this.props.data.relatorio_status !== undefined ? this.props.data.relatorio_status : 1,
            prevent: true,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePrevent = this.handlePrevent.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action() {
        let retData = {}
        retData.ids_coletas = this.state.ids_coletas
        retData.observacao = this.state.observacao
        retData.status = this.state.status;
        retData.relatorio_status = this.state.relatorio_status;
        if (!this.state.prevent) {
            retData.data_coleta = this.state.data_coleta
            retData.qtd_litros = this.state.qtd_litros
        }
        if (this.props.handleFormState) {
            this.props.handleFormState(retData)
        }
    }

    handlePrevent(e) {
        if (this.state.prevent)
            makeAlertDialog(() => { this.setState({ prevent: false }) },
                "Atenção",
                "Deseja realmente editar os campos protegidos, você precisa ter nível de permissão ALTO para isso!").current.open()
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        return (
            <form className={
                classes.form}
                noValidate
                onSubmit={e => { e.preventDefault() }}
            >
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Container className={classes.container}>
                        <TextField
                            id="observacao"
                            multiline={true}
                            label="Observação"
                            name="observacao"
                            fullWidth
                            type="text"
                            value={this.state.observacao}
                            onChange={this.handleChange}
                        >
                        </TextField>
                    </Container>
                    <Container className={classes.container} >
                        Mostrar no Relatório
                    <NativeSelect
                            fullWidth
                            value={this.state.relatorio_status}
                            name='relatorio_status'
                            onChange={this.handleChange}
                        >
                            <option value={1}>Ativo</option>
                            <option value={0}>Inativo</option>
                        </NativeSelect>
                    </Container>
                    <Container className={classes.container} >
                        Status
                    <NativeSelect
                            fullWidth
                            value={this.state.status}
                            name='status'
                            label="Status"
                            onChange={this.handleChange}
                        >
                            <option value={1}>Ativa</option>
                            <option value={0}>Inativa</option>
                        </NativeSelect>
                    </Container>
                    { this.state.ids_coletas.length === 0 ? 
                        <Container className={classes.container}>
                            <TextField
                                id="qtd_litros"
                                multiline={true}
                                label="Qtd. de Litros"
                                name="qtd_litros"
                                fullWidth
                                disabled={this.state.prevent}
                                type="text"
                                onClick={this.handlePrevent}
                                value={this.state.qtd_litros}
                                onChange={this.handleChange}
                            >
                            </TextField>
                        </Container>  : 
                        <Container/>
                    }
                    <Container className={classes.container}>
                        <CustomDatePicker
                            disabled={this.state.prevent}
                            onClick={this.handlePrevent}
                            disableToolbar
                            label="Data da Coleta"
                            variant="inline"
                            format="DD/MM/YYYY"
                            name="data_coleta"
                            value={this.state.data_coleta}
                            onChange={this.handleChange}
                        />
                    </Container>
                </FormControl>
            </form>
        )

    }
}

export default withStyles(styles)(FormFolhaColeta)