import React from 'react'
import {DialogContent, DialogActions, DialogContentText,
    Button, DialogTitle, Dialog, Slide, ListItem, ListItemIcon, Checkbox, ListItemText,
    ListItemSecondaryAction, TextField, List, Grid, Typography, Container, FormControlLabel,
    IconButton, Box, FormControl} from '@material-ui/core'
import {ClearRounded, Minimize, MinimizeRounded} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import Comment from '@material-ui/icons'

import {
  TITLE_ABRIR_FOLHA,
  BTN_CONCLUIR
} from "../../constants/msgs"
import { makeAlertDialog, makeProgressDialog } from '../dialogs/DialogRefInterface';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { centroProdutorService } from '../../services/centro_produtor_service'
import { folhaService } from '../../services/folha_service';
import { formatName } from '../../util/utils'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeight: '800px',
    maxHeight: '800px',
    minWidht:  '800px',
    maxWidht:  '800px'
  },
})

function createValue(nome, numero, data_inicial){
  return {nome, numero, data_inicial}
}

class FilterRelatorioAnalise extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          open : this.props.open || false,
          selected : [],
          bloqued : true,
          limit: 10,
          all  : false,
          rows : []
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.handleChangeAll = this.handleChangeAll.bind(this)
        this.handleChangeBloqued = this.handleChangeBloqued.bind(this)
        this.handleChangeRowDate = this.handleChangeRowDate.bind(this)
        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.handleCreateResponse = this.handleCreateResponse.bind(this)
    }

    componentDidMount() {
      centroProdutorService.getCentroProdutores({produtor_id: -1}, this.handleGetResponse)
    }

    handleGetResponse(response){
      if(validateShowResponseToast(response, "", false)){
        let lRows = []
        response.data.data.map(value => {
          lRows.push(createValue(value.produtor.nome, value.numero, ""))
        })
        this.setState({
          rows : lRows
        })
      }
    }

    handleCreateResponse(response){
      if(validateShowResponseToast(response)){
        if(this.props.handleFormState){
          this.props.handleFormState(response.data)
        }
      }
    }

   // Necessario em todos os form abertos em ActionFormDialog
   action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleCancel(e){
        this.setState({open: false, all: false, bloqued: true, selected: []})
    }

    open(e){
        this.setState({open: true})
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    handleChangeAll(e){
      this.setState({all: e.target.checked})
      if (e.target.checked) {
          const newSelecteds = this.state.rows.map(n => n.numero);
          this.setState({selected : newSelecteds})
          return;
        }
        this.setState({selected: []});
    }

    handleChangeBloqued(e){
      this.setState({bloqued: e.target.checked})
      if(e.target.checked){
        this.state.rows.map(value => {
          value.data_inicial = this.state.date
        })
        this.forceUpdate()
      }
    }

    handleChangeRowDate(e, value){
        value.data_inicial = e.target.value
        this.forceUpdate()
    }

    handleToggle(event, value){
      event.stopPropagation()
      const selectedIndex = this.state.selected.indexOf(value);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, value);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1),
        );
      }
      this.setState({selected: newSelected});
    };

    render(){
        const {classes} = this.props
        const style={  marginBottom: 20, minWidth: 140, maxWidth: 140}
        const headStyle = {checkbox: {marginBottom: "30px", marginTop: "20px"}}
            return (
            <form className={classes.form} noValidate onSubmit={e => { e.preventDefault()}}>
                <Container>
                <FormControlLabel
                  control={
                    <Checkbox checked={true} 
                    checked={this.state.all}
                    onChange={this.handleChangeAll}
                    style={{...headStyle.checkbox, marginLeft: '15px'}}/>}
                    label="Selecionar Todos"
                />
               
                <TextField
                    id="limit"
                    type="number"
                    label="Qtd. de Análises"
                    name="limit"
                    value={this.state.limit}
                    onChange={this.handleChange}
                    style={{...headStyle.checkbox, marginLeft:'30px', maxWidth: 160}}
                />
               </Container>
                <Container>
                  <List> {this.state.rows.map(value => {
                      const labelId = `checkbox-list-label-${value.numero}`;
                      return (
                        <ListItem 
                        style={style}
                        key={value.numero} 
                        role={undefined} button
                         onClick={(e) => this.handleToggle(e, value.numero)}>
                            <Checkbox
                              edge="start"
                              checked={this.state.selected.indexOf(value.numero) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          <ListItemText id={labelId} primary={`${formatName(value.nome)}`} />
                          <ListItemText id={labelId} primary={`${value.numero}`} style={{marginLeft: "220px"}}/>
                        </ListItem>
                        
                      );
                    })}
                  </List>
                  </Container>
        </form>)
         
    }


}

export default withStyles(styles)(FilterRelatorioAnalise)