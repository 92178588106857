import { getAxiosInstance } from './service'

export const valorService = {
    getValores,
    createValores,
    updateValores
}

async function getValores(handleResponse, handleAction, status=-1){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/valorImpactante/status/${status}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createValores(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/definir/valorImpactante').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateValores(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/definir/valorImpactante/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

