import React from 'react'
import {headRowsAdminLaticinio} from '../../table/HeadRows'
import ObjectTable from '../../table/ObjectTable'
import { validateShowResponseToast } from '../../../helpers/toast_alerts';
import { adminService } from '../../../services/admin_service';
import { DoneOutline, DoneRounded, EditRounded } from '@material-ui/icons';
import { toast } from 'react-toastify'
import { makeAlertDialog, makeProgressDialog, closeProgressDialog } from '../../dialogs/DialogRefInterface';


class TableAdminLaticinio extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: []
        }
    }

    refresh = () => {
        adminService.getLaticinios((response) => {
            if(validateShowResponseToast(response)){
                this.setState({
                    rows: response.data
                })
            }
        })
    }

    componentDidMount(){
       this.refresh();
    }

    actions = [
        {
            title: 'Aprovar', 
            label: 'Aprovar',
            MaterialIcon: DoneRounded,
            handleClick: (e, selected) => {
                if(selected.aprovacao_status == 0){
                    makeAlertDialog(() => {
                        let base_url = window.location.protocol + '//' + window.location.host;
                        const data = {id: selected.id, url: base_url}
                        makeProgressDialog().current.open()
                        adminService.aprovarLaticinio(data, (response) => {
                            if(validateShowResponseToast(response)){
                                this.refresh();
                            }
                        }, 
                        closeProgressDialog)

                    }, 
                    "Atenção!", 
                    "Deseja realmente aprovar o cadastro deste laticínio e enviar um email de cadastro do primeiro usuário?").current.open()
                }else{
                    toast.warn('Este Laticínio já está aprovado')
                }
                e.stopPropagation()
            },
        },
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
            },
        },
    ]
    
    selectedToolActions = [
       
    ] 
    
    toolActions = [
      
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Laticínios' 
                headRows={headRowsAdminLaticinio} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'/>
        )
    }
}

export default TableAdminLaticinio