import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  NativeSelect,
  Typography,
  Grid,
  Link,
  Button,
  Input,
} from "@material-ui/core";
import { clearAllMask } from "../../util/utils";
import { makeAlertDialog } from "../dialogs/DialogRefInterface";
import NumberFormat from "react-number-format";
import { laticinioService } from "../../services/laticinio_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    marginTop: 30,
  },
  formControl: {
    minWidth: 600,
    maxWidth: 600,
  },
  container: {
    marginTop: 20,
  },
  submit: {
    marginTop: 20,
  },
});

const estadosBrasil = [
  { sigla: "AC", nome: "Acre" },
  { sigla: "AL", nome: "Alagoas" },
  { sigla: "AP", nome: "Amapá" },
  { sigla: "AM", nome: "Amazonas" },
  { sigla: "BA", nome: "Bahia" },
  { sigla: "CE", nome: "Ceará" },
  { sigla: "DF", nome: "Distrito Federal" },
  { sigla: "ES", nome: "Espírito Santo" },
  { sigla: "GO", nome: "Goías" },
  { sigla: "MA", nome: "Maranhão" },
  { sigla: "MT", nome: "Mato Grosso" },
  { sigla: "MS", nome: "Mato Grosso do Sul" },
  { sigla: "MG", nome: "Minas Gerais" },
  { sigla: "PA", nome: "Pará" },
  { sigla: "PB", nome: "Paraíba" },
  { sigla: "PR", nome: "Paraná" },
  { sigla: "PE", nome: "Pernambuco" },
  { sigla: "PI", nome: "Piauí" },
  { sigla: "RJ", nome: "Rio de Janeiro" },
  { sigla: "RN", nome: "Rio Grande do Norte" },
  { sigla: "RS", nome: "Rio Grande do Sul" },
  { sigla: "RO", nome: "Rondônia" },
  { sigla: "RR", nome: "Roraíma" },
  { sigla: "SC", nome: "Santa Catarina" },
  { sigla: "SP", nome: "São Paulo" },
  { sigla: "SE", nome: "Sergipe" },
  { sigla: "TO", nome: "Tocantins" },
];

class FormLaticinio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      cnpj: "",
      cidade: "",
      cep: "",
      estado: "",
      endereco: "",
      complemento: "",
      razao_social: "",
      telefone1: "",
      telefone2: "",
      email: "",
      estados: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

  handleClick(e) {
    let data = this.state;
    data.cnpj = clearAllMask(data.cnpj);
    data.cep = clearAllMask(data.cep);
    data.telefone1 = clearAllMask(data.telefone1);
    data.telefone2 = clearAllMask(data.telefone2);
    laticinioService.createLaticinio(data, (response) => {
      if (validateShowResponseToast(response)) {
        makeAlertDialog(
          () => {
            this.props.history.push("/");
          },
          "Sucesso!",
          "Registro criado com sucesso, em breve enviaremos um email de cadastro!"
        ).current.open();
      }
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Typography color="primary" variant="h4">
          Registro de Laticínio
        </Typography>
        <FormControl className={classes.formControl}>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                id="nome"
                label="Nome do Laticínio"
                name="nome"
                fullWidth
                type="text"
                value={this.state.nome}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={8}>
              <TextField
                id="razao_social"
                label="Razão Social"
                name="razao_social"
                fullWidth
                type="text"
                value={this.state.razao_social}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                customInput={TextField}
                format="##.###.###/####-##"
                id="cnpj"
                label="CNPJ"
                name="cnpj"
                fullWidth
                type="text"
                required
                value={this.state.cnpj}
                onChange={this.handleChange}
              ></NumberFormat>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={8}>
              <TextField
                id="cidade"
                label="Cidade"
                name="cidade"
                type="text"
                fullWidth
                value={this.state.cidade}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
            <Grid item xs={4} style={{ display: "flex" }}>
              <NativeSelect
                fullWidth
                value={this.state.estado}
                name="estado"
                onChange={this.handleChange}
                input={<Input name="estado" id="estado-native" />}
              >
                {estadosBrasil.map((estado) => {
                  return <option value={estado.sigla}>{estado.nome}</option>;
                })}
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={8}>
              <TextField
                id="endereco"
                label="Endereço"
                name="endereco"
                fullWidth
                type="text"
                value={this.state.endereco}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                customInput={TextField}
                format="#####-###"
                id="cep"
                label="CEP"
                name="cep"
                fullWidth
                required
                type="text"
                value={this.state.cep}
                onChange={this.handleChange}
              ></NumberFormat>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                id="complemento"
                label="Complemento"
                name="complemento"
                fullWidth
                type="text"
                value={this.state.complemento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={6}>
              <NumberFormat
                customInput={TextField}
                format="(##)#####-####"
                id="telefone1"
                label="Telefone Principal"
                name="telefone1"
                fullWidth
                type="text"
                required
                value={this.state.telefone1}
                onChange={this.handleChange}
              ></NumberFormat>
            </Grid>
            <Grid item xs={6}>
              <NumberFormat
                customInput={TextField}
                format="(##)#####-####"
                id="telefone2"
                label="Telefone 2"
                name="telefone2"
                fullWidth
                type="text"
                value={this.state.telefone2}
                onChange={this.handleChange}
              ></NumberFormat>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                name="email"
                fullWidth
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
          </Grid>
          <Button
            fullWidth
            onClick={this.handleClick}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrar
          </Button>
          <Grid container className={classes.container}>
            <Grid item>
              <Link href="/" variant="body2">
                Ir para Login
              </Link>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(FormLaticinio);
