import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    OutlinedInput, 
    TextField
} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 350,
      maxHeigth: 350,
      minWidth:  300,
      maxWidth:  300
  },
  formControl: {
      marginTop: "25px",
  },
  
})

class FormPrecoBase extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            valor_litro: this.props.data ? this.props.data.valor_litro || 0 : 0,
            qtd_minima: this.props.data ? this.props.data.qtd_minima || 0 : 0,
            qtd_maxima: this.props.data ? this.props.data.qtd_maxima || 0 : 0,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    componentWillMount(e){
       
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="qtd_minima"
                    label="Quantidade Mínima"
                    name="qtd_minima"
                    fullWidth
                    type="number"
                    value={this.state.qtd_minima}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
           <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="qtd_maxima"
                    label="Quantidade Máxima"
                    name="qtd_maxima"
                    fullWidth
                    type="number"
                    value={this.state.qtd_maxima}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>  
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="valor_litro"
                    label="Valor do Litro"
                    name="valor_litro"
                    fullWidth
                    type="number"
                    value={this.state.valor_litro}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl> 
            
           
         </form>
    )
        
    }
}

export default withStyles(styles)(FormPrecoBase)