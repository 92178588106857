import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    OutlinedInput, 
    TextField,
    Button,
    Container,
    Grid,
    Typography
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { userService } from '../../services/user_service'
import { validateShowResponseToast } from '../../helpers/toast_alerts'


const styles = (theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    
    },
    form: {
      marginTop: 30,
    },
    formControl: {
      minWidth: 500,
      maxWidth: 500
    },
    container: {
        marginTop: 20
    },
    submit: {
        marginTop: 20
    }
  })

class FormUsuario extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nome: "",
            usuario: "",
            senha: "",
            confirma_senha: "",
            email: "",
            token: ""
        }

        this.handleSave   = this.handleSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        this.setState({
            token: params.token
        })
    }


    handleSave(e){
        userService.registerUsuario(this.state, (response) => {
            if(validateShowResponseToast(response)){
                this.props.history.push("/")
            }
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

 
    render(){
      const {classes} = this.props
      return (
         <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
            >
            <Typography color="primary" variant='h4'>Registro de Usuário</Typography>
            <FormControl className={classes.formControl}>
                <Grid container  justify="center" className={classes.container} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="nome"
                            label="Nome Completo"
                            required={true}
                            name="nome"
                            fullWidth
                            type="text"
                            value={this.state.nome}
                            onChange={this.handleChange}
                            >
                    </TextField>
                    </Grid>
                </Grid>
                <Grid container  justify="center" className={classes.container} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="usuario"
                            required={true}
                            label="Nome de Usuário"
                            name="usuario"
                            fullWidth
                            type={this.state.isUpdate && !this.state.isUpdateSelf ? 'password' : 'text'}
                            disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                            value={this.state.usuario}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container  justify="center" className={classes.container} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                                id="email"
                                label="Email"
                                required={true}
                                name="email"
                                fullWidth
                                disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                                type='text'
                                value={this.state.email}
                                onChange={this.handleChange}
                                >
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container  justify="center" className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="senha"
                            label="Senha"
                            required={true}
                            name="senha"
                            fullWidth
                            type="password"
                            disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                            value={this.state.senha}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="confirma_senha"
                            label="Confirmar Senha"
                            required={true}
                            name="confirma_senha"
                            fullWidth
                            disabled={! this.state.isUpdateSelf && this.state.isUpdate}
                            type="password"
                            value={this.state.confirma_senha}
                            onChange={this.handleChange}
                            >
                        </TextField>
                    </Grid>
                </Grid>
                
                <Button
                    fullWidth
                    onClick={this.handleSave}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
              Registrar
            </Button>
       
           </FormControl>
         </form>
    )
        
    }
}

export default withStyles(styles)(FormUsuario)