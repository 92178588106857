import React from 'react'
import { EditRounded, SearchRounded, ClearRounded, RefreshRounded, CloudDownloadRounded, PrintRounded} from '@material-ui/icons'

import {headRowsColetas} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { coletaService } from '../../services/coleta_service';
import { makeActionFormDialog, closeProgressDialog, makeProgressDialog, makeAlertDialog } from '../dialogs/DialogRefInterface';
import FormFolhaColeta from './FormFolhaColeta';
import FilterColeta from './FilterColeta';
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import moment from 'moment'
import Paginate from '../table/Paginate';

class TableColeta extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
            lastSelectedEdit: {},
            lastSelectedsEdit: [],
            lastSearch: {}
        }

        this.handleGetFilters = this.handleGetFilters.bind(this)
        this.handleSave       = this.handleSave.bind(this)
        this.handleUpdateColeta = this.handleUpdateColeta.bind(this)
        this.handleUpdateColetas = this.handleUpdateColetas.bind(this)
    }

    handleForceUpdate = () => {
        this.forceUpdate()
    }

    getExportTitle = () => {
        return `Coletas ${
            this.state.lastSearch.data_inicio && this.state.lastSearch.data_final ?
            moment(this.state.lastSearch.data_inicio).format('DD/MM/YYYY').concat(' a ').
            concat(moment(this.state.lastSearch.data_final).format('DD/MM/YYYY')) : 
           moment().format('DD/MM/YYYY')}`
    }

    refresh = () => {
        makeProgressDialog(null, "Carregando Coletas").current.open()
        const dataInicio = this.props.customTable ? moment().format('YYYY-MM-DD') :
            moment().subtract(15,'d').format('YYYY-MM-DD')
        this.state.lastSearch = {data_inicio: dataInicio}
        coletaService.getColetas(this.state.lastSearch, this.state.paginate.handleGet, closeProgressDialog)
    }

    reloadAfterUpdate = () => {
        makeProgressDialog(null, "Carregando Coletas").current.open()
        coletaService.getColetas(this.state.lastSearch, this.state.paginate.handleGet, closeProgressDialog)
    }

    getRenderActionsCustom = () => {
        if(! this.props.customTable){
            return [
                {
                    title: 'Filtrar Coletas',
                    label: 'Filtro',
                    MaterialIcon: SearchRounded,
                    handleClick: (e, selected) => {
                        e.stopPropagation()
                        makeActionFormDialog(FilterColeta, "Filtrar Coletas", undefined, undefined, "Filtrar", this.handleGetFilters).current.open()
                    }
                },
            ]
        }else{
            return []
        }
    }

    componentDidMount(){
        this.refresh()
    }

    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaColeta, "Coleta", undefined, selected, "Salvar",  (data) => {
                    this.handleSave(selected, data)
                }).current.open()
            }
        },
    ]

    selectedToolActions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaColeta, "Coleta", undefined, {ids_coletas: selected}, "Salvar",  (data) => {
                    makeAlertDialog(() => {
                        coletaService.updateColetas({ids: selected, 
                            observacao: data.observacao, 
                            status: data.status,
                            data_coleta:  data.data_coleta,
                            relatorio_status: data.relatorio_status
                    }, this.handleUpdateColetas)
                    }   ,
                        "Atenção", 
                        "Deseja realmente atualizar todas as coletas selecionadas? Caso tenha mudado o status para INATIVO elas não irão aparecer na Folha de Pagamento"
                    ).current.open()
                    
                }).current.open()
            }
        },
        {
            title: 'Imprimir', 
            label: 'Imprimir',
            MaterialIcon: PrintRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.exportPDF(headRowsColetas,
                 true,`coletas_${moment().format('DD_MM_YYYY')}`, 'l', this.getExportTitle())
            },
            color: 'primary'
        },
        {
            title: 'Exportar Excel', 
            label: 'Exportar',
            MaterialIcon: CloudDownloadRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.exportCSV(undefined, true, true)
            }
        },
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 
    

    toolActions = [
        ...this.getRenderActionsCustom(),
        {
            title: 'Imprimir', 
            label: 'Imprimir',
            MaterialIcon: PrintRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.exportPDF(headRowsColetas,
                 false,`coletas_${moment().format('DD_MM_YYYY')}`, 'l', this.getExportTitle())
            },
            color: 'primary'
        },
        {
            title: 'Exportar Excel', 
            label: 'Exportar',
            MaterialIcon: CloudDownloadRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.
                current.exportCSV(headRowsColetas.map(hr => {return{label: hr.label, key: hr.id}}))
            }
        },
        {
            title: 'Atualizar', 
            label: 'Atualizar',
            MaterialIcon: RefreshRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.refresh()
            },
        },
     ]

    handleSave(selected, data){
        this.setState({lastSelectedEdit: selected})
        coletaService.updateColeta({id: selected.id, ...data}, this.handleUpdateColeta)
    }

    handleGetFilters(filters){
        makeProgressDialog().current.open()
        this.state.paginate.setAttribute('isNew', true)
        this.state.lastSearch = filters
        coletaService.getColetas(filters, this.state.paginate.handleGet, closeProgressDialog)
    }

    handleUpdateColetas(response){
        const total = response ? response.data.total || 0 : 0
        const salvos = response ? response.data.salvos || 0 : 0
        if(validateShowResponseToast(response, undefined, true, true, `${salvos} de ${total} editados`)){
           this.reloadAfterUpdate()
        }
    }

    handleUpdateColeta(response){
        if(validateShowResponseToast(response, undefined, true, true, "Coleta Atualizada com sucesso!")){
           this.reloadAfterUpdate()
        }
    }


    render(){
        return(
            <ObjectTable
                ref={this.state.paginate.objectTableRef}
                tableName={this.props.tableName || 'Coletas'} 
                rowId='id'
                headRows={headRowsColetas} 
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowSize={20}
                rowsOptions={[10, 20, 40]}
                
                rows={this.state.paginate.rows}
                count={this.state.paginate.count}
                handleLoadPage={this.state.paginate.handleLoadPage}
                perPage={this.state.paginate.perPage}
                from={this.state.paginate.from}
                to={this.state.paginate.to}
                />
        )
    }
}

export default TableColeta