import React from 'react'
import {DeleteRounded,  EditRounded, AddCircleOutlineRounded, ClearRounded} from '@material-ui/icons'

import {headRowsFatorBonificacoes} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { bonificacaoService } from '../../services/bonificacao_service';
import { makeActionFormDialog, makeAlertDialog } from '../dialogs/DialogRefInterface';
import FormFatorBonificacao from './FormFatorBonificacao';
import { validateShowResponseToast } from '../../helpers/toast_alerts';

class TableFatorBonificacao extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: []
        }

        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.handleCreateResponse = this.handleCreateResponse.bind(this)
        this.handleDeleteResponse = this.handleDeleteResponse.bind(this)

        this.objectTableRef = React.createRef()
    }

    componentDidMount(){
        bonificacaoService.getFatorBonificacoes(this.handleGetResponse)
    }

    handleGetResponse(response){
        if(validateShowResponseToast(response, null, false)){
            this.setState({
                rows: response.data
            })
        }
    }

    handleCreateResponse(response){
        if(validateShowResponseToast(response, "Fator de Bonificação")){
            bonificacaoService.getFatorBonificacoes(this.handleGetResponse)
        }
    }

    handleDeleteResponse(response){
        if(validateShowResponseToast(response)){
            bonificacaoService.getFatorBonificacoes(this.handleGetResponse)
        }
    }

    actions = [
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    bonificacaoService.deleteFatorBonificacao(selected.id, this.handleDeleteResponse)
                    }, 
                    "Atenção!", 
                    "Deseja realmente excluir este Fator de Bonificação? Não esqueça de cadastrar um novo valor").current.open()
            },
            color: "secondary"
        },
    ]

    selectedToolActions = [
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    bonificacaoService.deleteFatorBonificacoes({ids: selected}, this.handleDeleteResponse)
                    }, 
                    "Atenção!", 
                    "Deseja realmente excluir os Fatores de Bonificações selecionados? Não esqueça de cadastrar um novo valor").current.open()
            },
            color: "secondary"
        },
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 

    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFatorBonificacao, "Novo Fator Bonificação", undefined,
                    undefined, "Salvar", (data) => {
                        bonificacaoService.createFatorBonificacao(data, this.handleCreateResponse)
                    }).current.open()
            }
        }
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Fatores de Bonificação' 
                headRows={headRowsFatorBonificacoes} 
                rows={this.state.rows || []}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'
                ref={this.objectTableRef}
                />
        )
    }
}

export default TableFatorBonificacao