import { formatCPF, formatDecimal } from "./utils";


function createRelatorio(centro_produtor, total_litros, preco_unitario, valor_base, valor_total, valor_liquido, inss, ip, icms, 
        nome_fazendeiro, cpf, inscricao_estadual, nome_fazenda){
    return {centro_produtor, total_litros, preco_unitario, valor_base, valor_total, valor_liquido, inss, ip, icms, 
        nome_fazendeiro, cpf, inscricao_estadual, nome_fazenda}
}

export function makeComposicaoDesconto(folha){
	let taxas = folha.folha_taxas
	let valores = folha.folha_valores
	let composicoes = [
		['Preço Base', folha.valor_total],
		['Qualidade do leite (Bonificações / Penalidades)', folha.total_bonificacao]
	]
	let totalComposicao = Number(folha.valor_total) + Number(folha.total_bonificacao)
	let descontos = []
	let totalDescontos = 0
	taxas.map(taxa => {
		let valor = Number(folha.valor_total) * (taxa.porcentagem / 100)
		let vet = [`${taxa.descricao} (${taxa.porcentagem}%)`, valor]
		if(taxa.porcentagem >= 0){
			composicoes.push(vet)
			totalComposicao += valor
		}else{
			descontos.push(vet)
			totalDescontos += valor
		}
	})
	valores.map(val => {
		let vet = [val.descricao, val.valor]
		if(val.valor >= 0){
			composicoes.push(vet)
			totalComposicao += val.valor
		}else{
			descontos.push(vet)
			totalDescontos += val.valor
		}
	})
	return {
		composicao: composicoes, totalComposicao: totalComposicao,
		desconto: descontos, totalDesconto: totalDescontos
	}
}

function makeTotalLitros(coletas){
    let totalLitros = 0
    coletas.map(coleta =>{
        if(coleta.relatorio_status == 1){
            totalLitros += Number(coleta.qtd_litros)
        }
    })
    return totalLitros
}

export function makeRelatorioData(data, ip, inss, icms){
    let relatorios = []
    data.map(dt => {
        const composicao = makeComposicaoDesconto(dt)
        let totalLitros = makeTotalLitros(dt.folha_coletas)
        let precoUnitario = Number(dt.total_litro) > 0 ? composicao.totalComposicao / dt.total_litro : 0
        let valorBase = totalLitros * precoUnitario
        let valorIP = valorBase * ip / 100
        let valorTotal = valorBase + valorIP
        let valorINSS = valorBase * inss / 100
        let valorICMS = valorBase * icms / 100
        let valorLiquido = valorTotal - valorINSS

        let relatorio = null
        relatorios.map(rel => {
            if(dt.numero_centro_produtor == rel.centro_produtor){
				relatorio = rel
                rel.total_litros += totalLitros
                if(precoUnitario > 0 && precoUnitario < rel.preco_unitario){
					rel.preco_unitario = precoUnitario
				}
				rel.valor_base = rel.total_litros * rel.preco_unitario
				rel.inss = rel.valor_base * inss / 100
				rel.icms = rel.valor_base * icms / 100
				rel.ip = rel.valor_base * ip / 100
				rel.valor_total = rel.valor_base + rel.ip
				rel.valor_liquido = rel.valor_total - rel.inss
			}
			return rel
		})
		if(! relatorio){
			relatorio = createRelatorio(dt.numero_centro_produtor, totalLitros, precoUnitario, valorBase, 
				valorTotal, valorLiquido, valorINSS, valorIP, valorICMS, 
				dt.centro_produtor.produtor.nome, formatCPF(dt.centro_produtor.produtor.cpf), 
				dt.centro_produtor.produtor.inscricao_estadual, dt.centro_produtor.nome_fazenda)
			relatorios.push(relatorio)
		}
	})
	return relatorios
}

export function makeSum(rows, sumCols=[], headRows=[], rowId){
	let ret = {}
	headRows.map(hr => {
		if(sumCols.indexOf(hr.id) > -1){
			ret[hr.id] = 0
		}else{
			ret[hr.id] = '----'
		}
	})
	ret[rowId] = "Totais"
	rows.map(row => {
		sumCols.map(sc => {
			ret[sc] += row[sc] 
		})
	})
	return ret
}

export function calculateMediaGeometrica(value, rows){
	return formatDecimal(Math.pow(value, 1/rows.length))
}