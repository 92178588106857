import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    FormControl, 
    TextField
} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 350,
      maxHeigth: 350,
      minWidth:  300,
      maxWidth:  300
  },
  formControl: {
      marginTop: "25px",
  },
  
})

class FormMultPrecoBase extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: 0
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    componentWillMount(e){
       
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="tf_value"
                    label="Valor Adicional"
                    name="value"
                    fullWidth
                    type="number"
                    value={this.state.value}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
           
         </form>
    )
        
    }
}

export default withStyles(styles)(FormMultPrecoBase)