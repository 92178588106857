import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {FormControl, TextField, Container,  NativeSelect} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { convertStatusToNumber } from '../../util/utils'
import { produtorService } from '../../services/produtor_service'
import { clearCPF } from '../../util/utils'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { makeAlertDialog } from '../dialogs/DialogRefInterface'
import { toast } from 'react-toastify'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 600,
      maxHeigth: 600,
      maxWidth:  400,
      minWidth:  400
  },
  formControl: {
    minWidth: "200px"
  },
  container: {
      marginTop: "30px"
  }
})



class FormProdutor extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            matricula: this.props.data ? this.props.data.matricula || 0 : 0,
            nome: this.props.data ? this.props.data.nome || "" : "",
            cpf: this.props.data ? this.props.data.cpf || "" : "",
            inscricao_estadual: this.props.data ? this.props.data.inscricao_estadual || "" : "",
            matricula_status: this.props.data.matricula_status == 0 ? 0 : 1,
         
            isUpdate: this.props.data.cpf ? true : false,
            disableName: this.props.data.id > 0,
            disableCreate: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCPF = this.handleChangeCPF.bind(this)
        this.handleSearchResponse = this.handleSearchResponse.bind(this)
        this.handleClick = this.handleClick.bind(this)

    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            if( ! this.state.disableCreate){
                this.props.handleFormState(this.state)
            }else{
                toast.warn('Este Produtor já possui Matrícula!')
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    searchProdutor = (cpf) => {
        produtorService.getByCPF({cpf: clearCPF(cpf)}, this.handleSearchResponse)
    }

    handleSearchResponse(response){
        if(validateShowResponseToast(response, undefined, false, false)){
            this.setState({
                id: response.data.id,
                nome: response.data.nome,
                disableName: true,
                matricula: response.data.matricula,
                matricula_status: response.matricula_status,
                disableCreate: response.data.matricula > 0,
            })
            toast.info("Este Produtor já está cadastrado! Faça a Matricula")
        }
    }

    handleChangeCPF(e){
        const { name, value } = e.target;
        const lastChar = value[value.length -1];
        if(lastChar !== " "){
            this.searchProdutor(value)
        }else{
            this.setState({
                nome: "",
                matricula: 0,
                disableName: false
            })
        }
        this.setState({ [name]: value });
    }

    handleClick(e){
        if(this.state.disableName){
            makeAlertDialog(()=>{
                this.setState({
                    disableName: false
                })
            },
            'Atenção',
            "Deseja realmente alterar o nome desse produtor?").current.open()
        }
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <Container  className={classes.container}>
                    <NumberFormat
                        customInput={TextField}
                        format="###.###.###-##"
                        id="cpf"
                        label="CPF do Produtor"
                        name="cpf"
                        fullWidth
                        type="text"
                        disabled={this.state.isUpdate}
                        value={this.state.cpf}
                        onChange={this.handleChangeCPF}
                        >
                    </NumberFormat>
                </Container>
                <Container  className={classes.container}>
                    <NumberFormat
                        customInput={TextField}
                        format="###.###.###.##.##"
                        id="inscricao_estadual"
                        label="Inscrição Estadual"
                        name="inscricao_estadual"
                        fullWidth
                        type="text"
                        value={this.state.inscricao_estadual}
                        onChange={this.handleChange}
                        >
                    </NumberFormat>
                </Container>
                <Container className={classes.container}>
                    <TextField
                        id="nome"
                        label="Nome do Produtor"
                        name="nome"
                        fullWidth
                        disabled={this.state.disableName}
                        type="text"
                        value={this.state.nome}
                        onClick={this.handleClick}
                        onChange={this.handleChange}
                        >
                    </TextField>
                </Container>
                
                <Container  className={classes.container}>
                    <TextField
                        id="matricula"
                        label="Número da Matrícula"
                        name="matricula"
                        fullWidth
                        type="number"
                        disabled={this.state.disableCreate}
                        value={this.state.matricula}
                        onChange={this.handleChange}
                        >
                    </TextField>
                </Container>
                <Container className={classes.container} >
                    <NativeSelect
                        fullWidth
                        value={this.state.matricula_status}
                        name='matricula_status'
                        onChange={this.handleChange}
                        disabled={this.state.disableCreate}
                        >
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                    </NativeSelect>
                </Container>
               
           </FormControl>
         </form>
    )
        
    }
}

export default withStyles(styles)(FormProdutor)