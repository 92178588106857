import React from 'react'
import { 
    ListRounded, ClearRounded
} from '@material-ui/icons'

import { headRowsAnalisesSimple, headRowsAnalises } from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import Paginate from '../table/Paginate'
import { analiseService } from '../../services/analise_service';



class TableAnalise extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
            headRows: headRowsAnalisesSimple,
            analiseFileId: this.props.data.id
         }

    }


    handleForceUpdate = () => {
        this.forceUpdate()
        if(this.state.paginate.objectTableRef.current){
            this.state.paginate.objectTableRef.current.updateTable()
        }
    }

  
    componentDidMount(){
        analiseService.getAnalises(
            {id: this.state.analiseFileId},
            this.state.paginate.handleGet)
    }

    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.state.paginate.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 

    toolActions = [     
        {
            title: "Ver", 
            label: 'Ver',
            MaterialIcon: ListRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(this.state.headRows === headRowsAnalises){
                    this.setState({
                        headRows: headRowsAnalisesSimple,
                    })
                }else{
                    this.setState({
                        headRows: headRowsAnalises,
                    })
                }
            },
        },
    ]
    actions = []


    render(){
        return(
            <div>
                <input 
                    type="file" 
                    id="file" 
                    ref="fileUploader"
                    style={{display: "none"}}
                    accept=".txt"
                    onChange={this.handleInputChange}
                />
                <ObjectTable 
                    rowId='id'
                    tableName='Análises' 
                    headRows={this.state.headRows} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={40}
                    rowsOptions={[20, 40, 80]}
                
                    ref={this.state.paginate.objectTableRef}
                    rows={this.state.paginate.rows || []}
                    count={this.state.paginate.count}
                    handleLoadPage={this.state.paginate.handleLoadPage}
                    perPage={this.state.paginate.perPage}
                    from={this.state.paginate.from}
                    to={this.state.paginate.to}
                />
            </div>
            )
    }
}

export default TableAnalise