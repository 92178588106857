import { getAxiosInstance } from '../services/service'

export const analiseService = {
    createAnalise,
    getAnalisesFile,
    updateAnalise,
    getAnalises,
    getAllAnalises,
    getDefaultBonificacao
}

async function createAnalise(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/analise').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function getAnalisesFile(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/analise/getAnalises').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateAnalise(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const id = data.id || 0
    response = await getAxiosInstance(`/analise/${id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getAnalises(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const id = data.id || 0
    response = await getAxiosInstance(`/analise/${id}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}


async function getAllAnalises(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const id = data.id || 0
    response = await getAxiosInstance(`/analise/getAllAnalises`).post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getDefaultBonificacao(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/analise/getDefaultBonificacao').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
