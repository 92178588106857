import React from 'react'
import {DialogContent, DialogActions, Button, DialogTitle, Dialog, Slide, IconButton, Box} from '@material-ui/core'
import {ClearRounded} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import {
  BTN_CONCLUIR
} from "../../constants/msgs"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  
  },
  dialog: {
  
  }
})

class ObjectListDialog extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          ObjectTable : this.props.ObjectTable ? this.props.ObjectTable : "<div/>",
          open : this.props.open ? this.props.open : false,
          handleAfterClose : this.props.handleAfterClose || undefined,
          handleSave: this.props.handleSave || undefined,
          data: props.data || [],
          rows : props.rows || []
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSave   = this.handleSave.bind(this)

        this.objectTableRef = React.createRef()
    }

    componentWillMount(){
    }

    update_ref_state(ObjectTable, title="", handleAfterClose=undefined, data=undefined, rows=[], handleSave=undefined){
        this.setState({
          title:title, 
          handleSave: handleSave,
          handleAfterClose: handleAfterClose, 
          data: data,
          rows: rows,
          ObjectTable: ObjectTable
        })
    }

    handleCancel(){
        this.setState({open: false})
    }

    open(){
        this.setState({open: true})
    }

    handleSave(event){   
      if(this.state.handleSave){
        if(this.objectTableRef){
          this.objectTableRef.current.action(this.state.handleSave)
        }
      }
    }
   
    render(){
      const {classes} = this.props
      return (
            <Dialog
              maxWidth={false}
              disableBackdropClick={true}
              disableEscapeKeyDown={false}
              TransitionComponent={Transition}
              open={this.state.open}
              onClose={this.handleCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
          
              <Box display="flex" flexDirection="row-reverse" bgcolor="background.paper">
                <Box>
                  <DialogActions >
                    <IconButton aria-label="Sair" onClick={this.handleCancel}>
                      <ClearRounded color='secondary'/>
                    </IconButton>
                  </DialogActions>
                </Box>
                <Box display="flex" justifyContent="center"  flexGrow={1}> 
                    <DialogTitle id="form-dialog-title">{
                      `${this.state.title}`
                      }
                    </DialogTitle>
                </Box>
              </Box>
           
              <DialogContent className={classes.dialog}>
                { this.state.ObjectTable ?
                    (< this.state.ObjectTable
                      ref={this.objectTableRef}
                      rows={this.state.rows}
                      data={this.state.data}
                      />) :
                    (<div></div>)
                  }
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleSave} color="primary">
                  {BTN_CONCLUIR}
                </Button>
              </DialogActions>
            </Dialog>      
        )
        
    }


}

export default withStyles(styles)(ObjectListDialog)