import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {FormControl, TextField, Container,  NativeSelect} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: '100px',
      maxHeight: '100px',
      minWidht:  '400px',
      maxWidht:  '400px'
  },
  formControl: {
    minWidth: "200px"
  },
  container: {
      marginTop: "30px"
  }
})

class FormEditAnalise extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            numero_cp: this.props.data.numero_cp || 0
        }

        this.handleChange = this.handleChange.bind(this)

    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
  
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Container className={classes.container}>
                <TextField
                    id="numero_cp"
                    label="Número do Centro Produtor"
                    name="numero_cp"
                    fullWidth
                    type="number"
                    value={this.state.numero_cp}
                    onChange={this.handleChange}
                    >
                </TextField>
                </Container>
           </FormControl>
         </form>
    )
        
    }
}

export default withStyles(styles)(FormEditAnalise)