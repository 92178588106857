import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import {
  makeProgressDialog,
  closeProgressDialog,
} from "../dialogs/DialogRefInterface"
import TableColeta from '../_coleta/TableColeta'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { coletaService } from '../../services/coleta_service'
import { definicoesService } from '../../services/definicoes_service'

export default function Main() {
    const [ultimaAtualizacao, setUltimaAtualizacao] = React.useState()
    const [urlColetas, setUrlColetas] = React.useState(undefined)
    function handleShowStatusUpdate(response) {
      let salvos = 0
      let total = 0
      if (response) {
        salvos = response.data ? response.data.salvos || 0 : 0
        total = response.data ? response.data.total_registros || 0 : 0
      }
      if (validateShowResponseToast(response, undefined, undefined, undefined,
        `${salvos} Coletas Atualizadas de ${total}`)) {
        definicoesService.updateDefinicoes({})
      }
    }
    function handleUltimaAtualizacao(response) {
      if (!response) return
      if (response.status === 200) {
        if (response.data.ultima_atualizacao) {
          setUltimaAtualizacao(response.data.ultima_atualizacao)
        } else {
          let dataAtual = moment().format('YYYY-MM-DD');;
          setUltimaAtualizacao(dataAtual)
          definicoesService.updateDefinicoes()
        }
      }
    }
  
    function handleResponseUrls(response) {
      if (validateShowResponseToast(response, undefined, false, true)) {
        setUrlColetas(response.data[0].coleta_url)
      }
    }
  
    function handleShowUltimaAtualizacao(e) {
      definicoesService.getDefinicoes(handleUltimaAtualizacao)
      definicoesService.getUrlDados(handleResponseUrls)
    }
  
    function handleUpdateData(event, lastDate = undefined) {
      let dataAtual = moment().format('YYYY-MM-DD')
      let url = urlColetas ? urlColetas.replace('{}', moment(lastDate ? lastDate : ultimaAtualizacao).subtract(30, 'days').
        format('YYYY-MM-DD')).replace('{}', dataAtual) : ""
      coletaService.uploadColetas(url, handleShowStatusUpdate, closeProgressDialog)
      makeProgressDialog(e => console.log("finalizado"), "Buscando Dados! Aguarde...").current.open()
    }
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={9}>
              <TableColeta
                customTable={true}
                tableName={`Coletas recebidas hoje: ${moment().format("DD/MM/YYYY")}`}
              />
          </Grid>
          <Grid item xs={3}>
            <Typography color='primary' style={{ fontSize: 20 }}>Atualizar Coletas, busca dos ultimos 30 dias. Ultima Atualização em: {moment(ultimaAtualizacao).
              format("DD/MM/YYYY")}</Typography>
            <IconButton
              color="inherit"
              onClick={(e) => handleUpdateData(e)}
              onMouseEnter={handleShowUltimaAtualizacao}
            >
              <CloudDownloadOutlined color='primary' style={{ fontSize: 250 }} />
            </IconButton>
          </Grid>
        </Grid>
  
      </div>
    )
  }