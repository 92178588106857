import { getAxiosInstance } from '../services/service'
import { clearCPF } from '../util/utils';

export const produtorService = {
    getProdutores,
    getByCPF,
    createProdutor,
    updateProdutor
}

async function getProdutores(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/produtor/getProdutores').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function getByCPF(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/produtor/getByCPF').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function createProdutor(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    data.cpf = clearCPF(data.cpf)
    response = await getAxiosInstance('/produtor').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}

async function updateProdutor(data, handleResponse, handleAction) {
    let response = undefined
    let errorResponse = undefined
    data.cpf = clearCPF(data.cpf)
    response = await getAxiosInstance(`/produtor/${data.id}`).put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()  
}




