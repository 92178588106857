import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {  DateRangeRounded } from '@material-ui/icons'
import moment from 'moment'


export default class CustomDatePicker extends React.Component{
    constructor(props){
        super(props)
    }

    handleChangeDate = (value, name) => {
       return {
           target: {
               value: moment(value).format('YYYY-MM-DD'),
               name
           }
       }
    }

    render(){
        const color = this.props.iconColor || 'primary' 
        return <KeyboardDatePicker
            {...this.props}
            onChange={(e) => this.props.onChange(this.handleChangeDate(e, this.props.name))}
            autoOk={true}
            minDateMessage="Data está fora do valor mínimo permitido"
            maxDateMessage="Data está fora do valor máximo permitido"
            invalidLabel="Inválido"
            invalidDateMessage="Data é inválida"
            keyboardIcon={<DateRangeRounded color={color} style={{color: color}}/>}
        >
        </KeyboardDatePicker>
    }
}