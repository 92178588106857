import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputLabel,
  FormControl,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { valorService } from "../../services/valor_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minWidth: 300,
    maxWidth: 300,
  },
  formControl: {
    minWidth: 300,
    maxWidth: 300,
  },
});

class FormFolhaValor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valor_impactante_id: 0,
      valor: 0,
      multiplicador: 1,
      labelWidht: 0,
      valores: [],
      impacto: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
    this.handleGetValores = this.handleGetValores.bind(this);
  }

  componentDidMount() {
    valorService.getValores(this.handleGetValores, undefined, 1);
  }

  handleGetValores(response) {
    if (validateShowResponseToast(response, null, false)) {
      this.setState({
        valores: response.data,
      });
    }
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

  handleChangeMultiplicador = (e) => {
    const value = e.target.value;
    this.setState({
      multiplicador: value,
    });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeCurrency(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSelectChange(e) {
    this.setState({
      valor_impactante_id: e.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel required>Descrição</InputLabel>
          <Select
            value={this.state.valor_impactante_id}
            onChange={this.handleSelectChange}
            label="Descrição"
            required
            fullWidth
          >
            {this.state.valores.map((valor) => {
              return <MenuItem value={valor.id}>{valor.descricao}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <TextField
          margin="normal"
          required
          className={classes.formControl}
          name="valor"
          label="Valor (R$)"
          type="number"
          id="valor"
          onChange={this.handleChange}
          value={this.state.valor}
        ></TextField>
        <FormControl fullWidth>
          <InputLabel impacto required>
            Multiplicar Por
          </InputLabel>
          <Select
            value={this.state.multiplicador}
            onChange={this.handleChangeMultiplicador}
            label="Multiplicar Por "
            required
            fullWidth
            name="multiplicador"
          >
            <MenuItem value={1}>Nenhum</MenuItem>
            <MenuItem value={-1}>Multiplicar por Litros de Leite</MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(FormFolhaValor);
