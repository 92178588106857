
import React from 'react'
import {
    InputLabel,
    Container,
    Grid,
    NativeSelect,
    TextField
} from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/pt-br'
import CustomDatePicker from '../default/CustomDatePicker'


class FilterFolha extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_inicial: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            data_final: moment().format('YYYY-MM-DD'),
            nome_produtor: '',
            status_folha: -1,
            update: 1
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
    }

    action() {
        if (this.props.handleFormState) this.props.handleFormState(this.state)
    }

    handleAction(e) {
        if (e.key == 'Enter') {
            this.action()
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const style = { marginBottom: 20, minWidth: 150 }
        return (
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <form className={{ display: 'flex', flexWrap: 'wrap', }}
                    noValidate
                    onSubmit={e => { e.preventDefault() }}
                >
                    <Container style={{ marginTop: 20 }} >
                        <TextField
                            onKeyDown={this.handleAction}
                            id="nome_produtor"
                            label="Nome do Produtor"
                            name="nome_produtor"
                            fullWidth
                            type="text"
                            value={this.state.nome_produtor}
                            onChange={this.handleChange}
                        >
                        </TextField>
                    </Container>
                    <Container style={{ marginTop: 20 }} >
                        <CustomDatePicker
                            disableToolbar
                            fullWidth
                            label="Data Inicial"
                            variant="inline"
                            format="DD/MM/YYYY"
                            name="data_inicial"
                            value={this.state.data_inicial}
                            onChange={this.handleChange}
                        />
                    </Container>
                    <Container style={{ marginTop: 20 }}>
                        <CustomDatePicker
                            disableToolbar
                            fullWidth
                            label="Data Final"
                            variant="inline"
                            format="DD/MM/YYYY"
                            name="data_final"
                            value={this.state.data_final}
                            onChange={this.handleChange}
                        />
                    </Container>
                    <Container style={{ marginTop: 20 }} >
                        <InputLabel shrink htmlFor="native-label-placeholder">
                            Situação
                        </InputLabel>
                        <NativeSelect
                            fullWidth
                            value={this.state.status_folha}
                            name='status_folha'
                            onChange={this.handleChange}
                            style={style}
                        >
                            <option value={-1}>TODOS</option>
                            <option value={1}>ABERTA</option>
                            <option value={0}>FECHADA</option>
                        </NativeSelect>
                    </Container>

                </form>
            </Grid>
        )

    }
}

export default (FilterFolha)




