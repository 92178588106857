import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
    TextField, FormControl, NativeSelect, Checkbox, Grid, FormControlLabel,
} from '@material-ui/core'
import CustomDatePicker from '../default/CustomDatePicker'


const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,

    },
    form: {
        minHeight: 200,
        maxHeight: 200,
        minWidht: 300,
        maxWidht: 300
    },
    formControl: {
        marginTop: 25,
    },

})

class FormEditFolha extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            autopreco: true,
            valor_litro: ""
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount() {
        this.setState({
            ...this.props.data
        })
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action() {
        if (this.props.handleFormState) {
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeAutoPreco = (event) => {
        this.setState({ autopreco: event.target.checked })
      }

    render() {
        const { classes } = this.props
        return (
            <form className={classes.form}
                noValidate
                onSubmit={e => { e.preventDefault() }}
            >
                <Grid container justify="flex-start" alignItems="center">
                        <FormControlLabel
                            className={classes.formControl}
                            control={
                                <Checkbox
                                    checked={this.state.autopreco}
                                    onChange={this.handleChangeAutoPreco}
                                />}
                            label="Preço base automático"
                        />
                        <TextField
                            className={classes.formControl}
                            id="valor_litro"
                            label="Valor do Litro"
                            name="valor_litro"
                            fullWidth
                            type="number"
                            disabled={this.state.autopreco}
                            value={this.state.valor_litro}
                            onChange={this.handleChange}>
                        </TextField>
                </Grid>

            </form>
        )

    }
}

export default withStyles(styles)(FormEditFolha)