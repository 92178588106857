import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    InputLabel,
    FormControl, 
    Select, 
    MenuItem, 
    OutlinedInput, 
    TextField
} from '@material-ui/core'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { bonificacaoService } from '../../services/bonificacao_service'
import { getLabelAnalise } from '../table/HeadRows';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeight: '350px',
      maxHeight: '350px',
      minWidht:  '500px',
      maxWidht:  '500px'
  },
  formControl: {
      marginTop: "25px",
  },
  
})

class FormFatorBonificacao extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data ? this.props.data.id || 0 : 0,
            elemento_bonificacao_id: 0,
            valor_bonus: this.props.data ? this.props.data.valor_bonus || 0 : 0,
            resultado_min: this.props.data ? this.props.data.resultado_min || 0 : 0,
            resultado_max: this.props.data ? this.props.data.resultado_max || 0 : 0,
            elementos: []
        }

        this.handleSave   = this.handleSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleGetElementosResponse = this.handleGetElementosResponse.bind(this)
    }

    handleSave(e){
        
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

    componentWillMount(){
        bonificacaoService.getElementosBonificacao(this.handleGetElementosResponse)
    }


    handleGetElementosResponse(response){
        if(validateShowResponseToast(response, "", null)){
            this.setState({
                elementos: response.data,
            }, () => {
                if(this.props.data){
                    if(this.props.data.elemento){
                        let elem_id = 0
                        this.state.elementos.map( el => {
                            if(el.descricao === this.props.data.elemento){
                                elem_id = el.id
                            }
                            this.setState({
                                elemento_bonificacao_id: elem_id
                            })
                        })
                    }
                }
            })
        }
      
    }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel 
                    impacto
                    required
                   >
                    Elemento
                </InputLabel>
                <Select
                value={this.state.elemento_bonificacao_id}
                onChange={this.handleChange}
                label="Impacto"
                required
                fullWidth
                input={
                    <OutlinedInput
                      labelWidth={100}
                      name="elemento_bonificacao_id"
                    />
                  }>
                    {  
                        this.state.elementos.map(elem => {
                            return <MenuItem value={elem.id}>{getLabelAnalise(elem.descricao)}</MenuItem>
                        })
                    }
                </Select>
           </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="resultado_min"
                    label="Resultado Mínimo"
                    name="resultado_min"
                    fullWidth
                    type="number"
                    value={this.state.resultado_min}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
           <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <TextField
                    id="resultado_max"
                    label="Resultado Máximo"
                    name="resultado_max"
                    fullWidth
                    type="number"
                    value={this.state.resultado_max}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>  
           <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                    id="valor_bonus"
                    label="Valor do Bônus"
                    name="valor_bonus"
                    fullWidth
                    type="number"
                    value={this.state.valor_bonus}
                    onChange={this.handleChange}
                    >
                </TextField>
           </FormControl> 
            
           
         </form>
    )
        
    }
}

export default withStyles(styles)(FormFatorBonificacao)