import { getAxiosInstance } from '../services/service'

export const laticinioService = {
    createLaticinio,
    updateLaticinio,
    getLaticinio
}

async function getLaticinio(handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/laticinio/laticinio/status/1`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createLaticinio(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateLaticinio(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio').put(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
