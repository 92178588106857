import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { 
    FormControl, 
    TextField,
    Container,
    NativeSelect
} from '@material-ui/core'


const styles = (theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    
    },
    form: {
        minHeigth: 500,
        maxHeigth: 500,
        maxWidth:  400,
        maxWidth:  400
    },
    formControl: {
        marginTop: "20px",
    },
    
  })

class FormValorImpactante extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: props.data ? props.data.id || 0 : 0,
            descricao: props.data ? props.data.descricao || "" : "",
            status: props.data.status
        }

        this.handleChange = this.handleChange.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
        if(this.props.handleFormState){
            this.props.handleFormState(this.state)
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
     }

 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            <FormControl variant="outlined" fullWidth >
                <TextField
                    id="descricao"
                    label="Descrição"
                    name="descricao"
                    fullWidth
                    type="text"
                    value={this.state.descricao}
                    onChange={this.handleChange}>
                </TextField>
           </FormControl>   
           <FormControl style={{marginTop: 20}} variant="outlined" fullWidth >
                <NativeSelect
                    fullWidth
                    value={this.state.status}
                    name='status'
                    onChange={this.handleChange}
                    >
                    <option value={1}>Ativo</option>
                    <option value={0}>Inativo</option>
                </NativeSelect>
            </FormControl>   
         </form>
    )
        
    }
}

export default withStyles(styles)(FormValorImpactante)