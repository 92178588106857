import React from 'react'
import {EditRounded, AddCircleOutlineRounded, ClearRounded} from '@material-ui/icons'

import {headRowsTiposPagamento} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { taxaService } from '../../services/taxa_service';
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { pagamentoService } from '../../services/pagamento_service';
import FormMeioPagamento from './FormMeioPagamento';




class TableMeioPagamento extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: []
        }
        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.objectTableRef = React.createRef()
    }

    componentDidMount(){
       pagamentoService.getMeiosPagamento(this.handleGetResponse)
    }

    handleGetResponse(response){
        if(validateShowResponseToast(response, '', false)){
            this.setState({
                rows: response.data
            })
        }
    }

    actions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormMeioPagamento, "Editar Meios de Pagamento", undefined, selected, 'Salvar', (data) => {
                    pagamentoService.updateMeioPagamento(data, (response) => {
                        if(validateShowResponseToast(response)){
                            pagamentoService.getMeiosPagamento(this.handleGetResponse)
                        }
                    })
                }).current.open()
            }
        },
    ]
    
    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 
    
    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormMeioPagamento, "Editar Meios de Pagamento", undefined, selected, 'Salvar', (data) => {
                    pagamentoService.createMeioPagamento(data, (response) => {
                        if(validateShowResponseToast(response)){
                            pagamentoService.getMeiosPagamento(this.handleGetResponse)
                        }
                    })
                }).current.open()
            }
        }
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Meios de Pagamento' 
                headRows={headRowsTiposPagamento} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'
                ref={this.objectTableRef}/>
        )
    }
}

export default TableMeioPagamento