import React from 'react'
import { EditRounded, AddCircleOutlineRounded, DeleteRounded, ClearRounded } from '@material-ui/icons'

import {headRowsPrecoBase} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { precoBaseService } from '../../services/preco_base';
import { makeActionFormDialog, makeAlertDialog } from '../dialogs/DialogRefInterface';
import FormPrecoBase from './FormPrecoBase';
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import FormMultPrecoBase from './FormMultPrecoBase';

class TablePrecoBase extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: []
        }
        this.handleCreateResponse = this.handleCreateResponse.bind(this)
        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.handleUpdateResponse = this.handleUpdateResponse.bind(this)
        this.handleDeleteResponse = this.handleDeleteResponse.bind(this)

        this.objectTableRef = React.createRef()
    }

    componentDidMount(){
       precoBaseService.getPreco(this.handleGetResponse)
    }

    handleGetResponse(response){
        if(validateShowResponseToast(response, '', false, true)){
            this.setState({
                rows: response.data
            })
        }
    }

    handleCreateResponse(response){
        if(validateShowResponseToast(response, 'Preço Base', true, true)){
            precoBaseService.getPreco(this.handleGetResponse)
        }
    }

    handleUpdateResponse(response){
        if(validateShowResponseToast(response, 'Preço Base')){
            precoBaseService.getPreco(this.handleGetResponse)
        }
    }
    
    handleDeleteResponse(response){
        if(validateShowResponseToast(response)){
            precoBaseService.getPreco(this.handleGetResponse)
        }
    }

    actions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormPrecoBase, "Editar Preço Base", 
                    undefined, selected, "Salvar", (data) => {
                        precoBaseService.updatePreco(data, this.handleUpdateResponse)
                    }).current.open()
            }
        },
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    precoBaseService.deletePrecos({ids: [selected.id]}, this.handleDeleteResponse)
                },
                "Atenção",
                "Deseja realmente excluir esse Preço Base? Caso positivo, não esqueça de cadastrar um novo preço nesse intervalo!"
                ).current.open()
            },
            color: "secondary"
        }
    ]
    
    selectedToolActions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormMultPrecoBase, "Adicionar Valor", 
                    undefined, selected, "Adicionar", (data) => {
                        const value = Number(data.value)
                        const selectedRows = this.state.rows.filter(row => selected.indexOf(row.id) > -1)
                        selectedRows.map(selRow => {
                            selRow.valor_litro += value
                            precoBaseService.updatePreco(selRow, response => {
                                if(validateShowResponseToast(response, "", false)){
                                    
                                }
                            })
                        })
                        this.setState({...this.state})
                    }).current.open()
            }
        },
        {
            title: 'Excluir', 
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    precoBaseService.deletePrecos({ids: selected}, this.handleDeleteResponse)
                },
                "Atenção",
                "Deseja realmente excluir todos preços selecionados? Caso positivo, não esqueça de cadastrar novos valores!"
                ).current.open()
            },
            color: "secondary"
        },
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 
    
    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormPrecoBase, "Novo Preço Base", 
                    undefined, undefined, "Salvar", (data) => {
                        precoBaseService.createPreco(data, this.handleCreateResponse)
                    }).current.open()
            }
        }
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Preço Base por litro em relação a média diária' 
                headRows={headRowsPrecoBase} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowSize={20}
                rowsOptions={[10, 20, 40]}
                rowId='id'
                ref={this.objectTableRef}
                />
        )
    }
}

export default TablePrecoBase