import React from 'react'
import { RepeatOneSharp, EditRounded } from '@material-ui/icons'

import {headRowsColetas} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { coletaService } from '../../services/coleta_service';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import FormFolhaColeta from './FormFolhaColeta';

class TableFolhaColeta extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: this.props.rows
        }
    }

    action = (handleSave) => {
        handleSave(this.state.rows)
    }

    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaColeta, "Coleta", undefined, selected, "Salvar",  (data) => {
                    coletaService.updateColeta({id: selected.id, observacao: data.observacao, status: data.status, 
                    relatorio_status: data.relatorio_status, qtd_litros: data.qtd_litros}, (response) => {
                        if(validateShowResponseToast(response)){
                            let nRows = this.state.rows.map(row => {
                                if(row.id == response.data.id){
                                    row = response.data
                                }
                                return row
                            })
                            this.setState({
                                rows: nRows
                            })
                        }
                    })
                }).current.open()
            }
        },
    ]

    selectedToolActions = []
    toolActions = []

    
    render(){
        return(
            <ObjectTable 
                tableName='Folha Coletas' 
                headRows={headRowsColetas} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'/>
        )
    }
}

export default TableFolhaColeta