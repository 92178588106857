import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { HomeRounded } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function switch_route(browserHistory, route){
    browserHistory.push(route)
}

export const MainListItems = (browserHistory) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return(
  <div>
    <ListItem button onClick={() => switch_route(browserHistory, "/admin/dashboard")}>
      <ListItemIcon>
        <DashboardIcon color="primary"/>
      </ListItemIcon>
      <ListItemText primary="Principal" />
    </ListItem>
    <ListItem button onClick={() => switch_route(browserHistory, "/admin/dashboard/laticinios")}>
      <ListItemIcon>
        <HomeRounded color="primary"/>
      </ListItemIcon>
      <ListItemText primary="Principal" />
    </ListItem>
  </div>
)}
