import React from "react";
import {
  AddCircleOutlineRounded,
  ClearRounded,
  PrintRounded,
  ImportExportRounded,
  CloudDownloadRounded,
} from "@material-ui/icons";
import { headRowsContabil } from "../table/HeadRows";
import ObjectTable from "../table/ObjectTable";
import {
  makeActionFormDialog,
  makeProgressDialog,
  closeProgressDialog,
} from "../dialogs/DialogRefInterface";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import FilterRelatorio from "./FilterRelatorio";
import { folhaService } from "../../services/folha_service";
import { makeRelatorioData, makeSum } from "../../util/relatorio_util";
import moment from "moment";
import "moment/locale/pt-br";
import { formatDecimal } from "../../util/utils";

class TableContabil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      lastSearch: [],
      rowsSum: [
        "ip",
        "icms",
        "inss",
        "total_litros",
        "valor_base",
        "valor_liquido",
        "valor_total",
      ],
    };

    this.objectTableRef = React.createRef();
  }

  getTitle = () => {
    if (
      moment(this.state.lastSearch.data_inicial).format("M") !==
      moment(this.state.lastSearch.data_final).format("M")
    ) {
      return `${moment(this.state.lastSearch.data_inicial)
        .locale("pt-BR")
        .format("LL")} a ${moment(this.state.lastSearch.data_final)
        .locale("pt-BR")
        .format("LL")}`;
    } else if (
      this.state.lastSearch.data_inicial &&
      this.state.lastSearch.data_final
    ) {
      return `${moment(this.state.lastSearch.data_inicial).format(
        "D"
      )} a ${moment(this.state.lastSearch.data_final)
        .locale("pt-BR")
        .format("LL")}`;
    } else {
      return `${moment().format("DD/MM/YYYY")}`;
    }
  };

  getSeleteds = (selected) => {
    return this.state.rows.filter((row) => {
      if (selected.indexOf(row.centro_produtor) > -1) {
        return row;
      }
    });
  };

  getHeadRows = (params) => {
    return headRowsContabil.map((hrc) => {
      const newHcr = { ...hrc };
      newHcr.label = params[hrc.id]
        ? `${hrc.label} ${formatDecimal(params[hrc.id], -1)}%`
        : hrc.label;
      return newHcr;
    });
  };

  actions = [];

  selectedToolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportPDF(
          this.getHeadRows(this.state.lastSearch),
          true,
          `relatorio_${moment().format("DD_MM_YYYY")}`,
          "l",
          `Relatório de ${this.getTitle()}`,
          this.state.rowsSum,
          "centro_produtor"
        );
      },
      color: "primary",
    },
    {
      title: "Exportar Excel",
      label: "Exportar",
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportCSV(
          this.getHeadRows(this.state.lastSearch).map((hr) => {
            return { label: hr.label, key: hr.id };
          }),
          true,
          true,
          this.state.rowsSum
        );
      },
    },
    {
      title: "Limpar Seleção",
      label: "Limpar",
      MaterialIcon: ClearRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.updateTable({ selected: [] });
      },
      color: "secondary",
    },
  ];

  toolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportPDF(
          this.getHeadRows(this.state.lastSearch),
          false,
          `relatorio_${moment().format("DD_MM_YYYY")}`,
          "l",
          `Relatório de ${this.getTitle()}`,
          this.state.rowsSum,
          "centro_produtor"
        );
      },
      color: "primary",
    },
    {
      title: "Exportar Excel",
      label: "Exportar",
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.exportCSV(
          this.getHeadRows(this.state.lastSearch).map((hr) => {
            return { label: hr.label, key: hr.id };
          }),
          true,
          false,
          this.state.rowsSum
        );
      },
    },
    {
      title: "Criar Relatório",
      label: "Criar",
      MaterialIcon: AddCircleOutlineRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FilterRelatorio,
          "Gerar Relatório",
          null,
          null,
          "Gerar",
          (data) => {
            let filter = {
              data_inicial: data.data_inicial,
              data_final: data.data_final,
              status: 0,
              update: 1,
            };
            makeProgressDialog(null, "Criando Relatório...").current.open();
            folhaService.getFolhas(
              filter,
              (response) => {
                if (validateShowResponseToast(response)) {
                  this.setState({
                    rows: makeRelatorioData(
                      response.data.data,
                      data.ip,
                      data.inss,
                      data.icms
                    ),
                    lastSearch: data,
                  });
                } else {
                  closeProgressDialog();
                }
              },
              closeProgressDialog
            );
          }
        ).current.open();
      },
    },
  ];

  render() {
    return (
      <div>
        <ObjectTable
          tableName="Relatório Contábil"
          headRows={this.getHeadRows(this.state.lastSearch)}
          rows={this.state.rows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.toolActions}
          rowId="centro_produtor"
          ref={this.objectTableRef}
          rowSize={60}
          rowsOptions={[20, 40, 60]}
        />
      </div>
    );
  }
}

export default TableContabil;
