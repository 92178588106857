import React, { Component } from 'react';
import './App.css';
import FormLogin from '../authenticate/FormLogin';
import Dashboard from '../dashboard/Dashboard';
import {  Route, Switch, Redirect} from "react-router-dom";
import { userService } from '../../services/user_service';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormLaticinio from '../_laticinio/FormLaticinio';
import FormRegisterUser from '../authenticate/FormRegisterUser';
import LoginAdmin from '../admin/LoginAdmin';
import { adminService } from '../../services/admin_service';
import DashboardAdmin from '../admin/DashboardAdmin';
import AlertDialog from '../dialogs/AlertDialog';
import { makeAlertDialog } from '../dialogs/DialogRefInterface';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'

function MakeQuerablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isResolved) return promise;

  // Set initial state
  var isPending = true;
  var isRejected = false;
  var isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  var result = promise.then(
      function(v) {
          isFulfilled = true;
          isPending = false;
          return v; 
      }, 
      function(e) {
          isRejected = true;
          isPending = false;
          throw e; 
      }
  );

  result.isFulfilled = function() { return isFulfilled; };
  result.isPending = function() { return isPending; };
  result.isRejected = function() { return isRejected; };
  return result;
}


function NotFoundPage() {
  return <h2> 404 - Página Não Encontrada!</h2>
}


function Login({component: Component, authed, ...rest}){
  return (
    <Route
      {...rest}
      render={(props) => localStorage.getItem('user')
        ? <Redirect to={{pathname: '/dashboard', state: {from: props.location}}} />
        : <Component {...props}/>
      }
    />
  )
}

function PrivateRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => localStorage.getItem('user')
        ? <Component {...props} />
        : <Redirect to={{pathname: '/', state: {from: props.location}}} />
      }
    />
  )
}

function AdminLogin({component: Component, authed, ...rest}){
  return (
    <Route
      {...rest}
      render={(props) => localStorage.getItem('admin')
        ? <Redirect to={{pathname: '/admin/dashboard', state: {from: props.location}}} />
        : <Component {...props}/>
      }
    />
  )
}

function AdminRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => localStorage.getItem('admin')
        ? <Component {...props} />
        : <Redirect to={{pathname: '/admin', state: {from: props.location}}} />
      }
    />
  )
}


class App extends Component {
  
  render() {
    return (
      <div className="App">
        <MuiPickersUtilsProvider container libInstance={moment} utils={MomentUtils} locale={'pt-br'}>
          <AlertDialog ref={makeAlertDialog()}/>
          <Switch>
            <Login authed={userService.getAll()} exact path='/' component={FormLogin} />
            <Login authed={userService.getAll()} exact path='/sign' component={FormLaticinio} />
            <Login authed={userService.getAll()} exact path='/register/:token' component={FormRegisterUser} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/folhas' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/produtores' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/coletas' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/definicoes' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/bonificacoes' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/usuarios' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/valores' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/taxas' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/precos' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/analises' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/pagamentos' component={Dashboard} />

            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/relatorio/contabil' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/relatorio/analise' component={Dashboard} />
            <PrivateRoute authed={userService.getAll()} exact path='/dashboard/relatorio/recebimentos' component={Dashboard} />

            <AdminLogin authed={adminService.getAll()} exact path='/admin' component={LoginAdmin} />
            <AdminRoute authed={adminService.getAll()} exact path='/admin/dashboard' component={DashboardAdmin} />
            <AdminRoute authed={adminService.getAll()} exact path='/admin/dashboard/laticinios' component={DashboardAdmin} />
            
            <Route path="*" component={NotFoundPage}/>
          </Switch>
          <ToastContainer autoClose={5000} position='bottom-right' draggable></ToastContainer>
          </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default App;
