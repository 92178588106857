import React from "react";
import {
  Container,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import moment from "moment";
import "moment/locale/pt-br";
import CustomDatePicker from "../default/CustomDatePicker";

class FilterRelatorio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mes: "",
      data_inicial: moment().subtract(30, "days").format("YYYY-MM-DD"),
      data_final: moment().format(),
      ip: 2.5,
      inss: 1.5,
      icms: 12,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
  }

  action() {
    if (this.props.handleFormState) this.props.handleFormState(this.state);
  }

  handleAction(e) {
    if (e.key == "Enter") {
      this.action();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeMonth(e) {
    let year = moment().format("YYYY");
    if (Number(moment().format("MM")) < e.target.value) {
      year -= 1;
    }
    let iDate = `${year}-${moment(e.target.value, "MM").format("MM")}-01`;
    let fDate = `${year}-${moment(e.target.value, "MM").format("MM")}-${moment(
      iDate
    )
      .endOf("month")
      .format("DD")}`;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      data_inicial: iDate,
      data_final: fDate,
    });
  }

  render() {
    const style = { marginBottom: 20, minWidth: 150 };
    return (
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <form
          className={{ display: "flex", flexWrap: "wrap" }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Container style={{ marginTop: 20 }}>
            <InputLabel month>Mês do Relatório</InputLabel>
            <Select
              value={this.state.mes}
              onChange={this.handleChangeMonth}
              fullWidth
              name="mes"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((mes) => {
                return (
                  <MenuItem value={mes}>
                    {moment(mes, "MM").locale("pt-BR").format("MMMM")}
                  </MenuItem>
                );
              })}
            </Select>
          </Container>
          <Container style={{ marginTop: 20 }}>
            <CustomDatePicker
              disableToolbar
              fullWidth
              label="Data Inicial"
              variant="inline"
              format="DD/MM/YYYY"
              name="data_inicial"
              value={this.state.data_inicial}
              onChange={this.handleChange}
            />
          </Container>
          <Container style={{ marginTop: 20 }}>
            <CustomDatePicker
              disableToolbar
              fullWidth
              label="Data Final"
              variant="inline"
              format="DD/MM/YYYY"
              name="data_final"
              value={this.state.data_final}
              onChange={this.handleChange}
            />
          </Container>
          <Container style={{ marginTop: 20 }}>
            <TextField
              id="ip"
              label="Incentivo a Produção"
              name="ip"
              fullWidth
              type="number"
              value={this.state.ip}
              onChange={this.handleChange}
            ></TextField>
          </Container>
          <Container style={{ marginTop: 20 }}>
            <TextField
              id="inss"
              label="INSS"
              name="inss"
              fullWidth
              type="number"
              value={this.state.inss}
              onChange={this.handleChange}
            ></TextField>
          </Container>
          <Container style={{ marginTop: 20 }}>
            <TextField
              id="icms"
              label="ICMS"
              name="icms"
              fullWidth
              type="number"
              value={this.state.icms}
              onChange={this.handleChange}
            ></TextField>
          </Container>
        </form>
      </Grid>
    );
  }
}

export default FilterRelatorio;
