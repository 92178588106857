import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ListItem, Checkbox, ListItemText} from '@material-ui/core'
import { taxaService } from '../../services/taxa_service';
import { validateShowResponseToast } from '../../helpers/toast_alerts';


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 500,
      maxHeigth: 500,
      maxWidth:  400,
      maxWidth:  400
  },
  formControl: {
      marginTop: "20px",
  },
  
})

class FormFolhaTaxa extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            taxas: [],
            selected: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)

    }

    componentDidMount(){
       taxaService.getTaxas((response)=>{
          if(validateShowResponseToast(response, null, false)){
            this.setState({
              taxas: response.data
            })
          }
       }, undefined, 1)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
      if(this.props.handleFormState){
          this.props.handleFormState(this.state)
      }
    }

    handleChange(e, value) {
        let lSelected = this.state.selected
        if(e.target.checked){
            lSelected.push(value.id)
        }else{
            lSelected.pop(value.id)
        }
        this.setState({selected : lSelected})
    }

    handleSelectChange(e) {
        this.setState({
           taxa_impactante_id: e.target.value,
        });
      }
 
    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}
        >
            {
              this.state.taxas.map(taxa => {
              return (
                  <ListItem>
                      <Checkbox
                          onChange={(e) => this.handleChange(e, taxa)}
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                      />
                      <ListItemText primary={`${taxa.descricao} (${taxa.porcentagem})`} />
                      
                  </ListItem>
                );
               })
            }
         </form>
    )
        
    }
}

export default withStyles(styles)(FormFolhaTaxa)