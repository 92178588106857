import { authHeader } from '../helpers/auth_header';
import { BASE_URL } from '../constants/urls'
import { getAxiosInstance } from '../services/service'


require('dotenv').config()


export const userService = {
    login,
    logout,
    getAll,
    getUsuarios,
    createUsuario,
    updateUsuario,
    getUsuario,
    registerUsuario
};

async function getUsuario(handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/usuario').get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getUsuarios(handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/laticinio/usuario/status/-1').get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuario/${data.id}`).put(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function registerUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuario/register/${data.token}`).post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/usuario').post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function login(usuario, senha) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario, senha })
    };
    const response = await fetch(`${BASE_URL}/usuario/login`, requestOptions);
    const user = await handleResponse(response);
    // login successful if there's a user in the response
    if (user) {
        // store user details and basic auth credentials in local storage 
        // to keep user logged in between page refreshes
        user.authdata = window.btoa(usuario + ':' + senha);
        localStorage.setItem('user', JSON.stringify(user));
    }
    return user;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${BASE_URL}/usuario`, requestOptions);
    return handleResponse(response); 
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
