import React from 'react'
import {  AddCircleOutlineRounded, EditRounded, ClearRounded } from '@material-ui/icons'
import {headRowsUsuarios} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { userService } from '../../services/user_service'
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import FormUsuario from './FormUsuario';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { authHeader } from '../../helpers/auth_header';

class TableUsuario extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            rows: props.rows || []
        }
        this.handleGetUsuarios = this.handleGetUsuarios.bind(this)
        this.handleCreateUsuario = this.handleCreateUsuario.bind(this)
        this.handleUpdateUsuario = this.handleUpdateUsuario.bind(this)

        this.objectTableRef = React.createRef();
    }

    actions = [
        {
            title: 'Editar', 
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormUsuario, "Editar Usuário", undefined, selected, "Salvar", (data) => {
                    data.id = selected.id
                    userService.updateUsuario(data, this.handleUpdateUsuario)
                }).current.open()
            },
        }
    ]

    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 

    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormUsuario, "Cadastrar Usuário", undefined, undefined, "Salvar", 
                    (data) => userService.createUsuario(data, this.handleCreateUsuario)
                ).current.open()
            }
        }
    ]

    handleUpdateUsuario(response){
        if(validateShowResponseToast(response, 'Usuário')){
            userService.getUsuarios(this.handleGetUsuarios)
        }
    }

    handleGetUsuarios(response){
        if(validateShowResponseToast(response, null, false)){
            if(response.data){
                this.setState({
                    rows: response.data
                })
            }
        }
    }

    handleCreateUsuario(response){
        if(validateShowResponseToast(response, 'Usuário', true, true)){
            userService.getUsuarios(this.handleGetUsuarios)
        }
    }

    componentDidMount(){
        userService.getUsuarios(this.handleGetUsuarios)
    }

    render(){
        return(
            <ObjectTable 
                ref={this.objectTableRef}
                tableName='Usuários' 
                headRows={headRowsUsuarios} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'/>
        )
    }
}

export default TableUsuario