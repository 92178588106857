import React from 'react'
import { withStyles, useTheme} from '@material-ui/core/styles'
import { ListItemText, InputLabel,FormControl, Select, 
    Checkbox, MenuItem, OutlinedInput, ListItem, Typography} from '@material-ui/core'
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { analiseService } from '../../services/analise_service';
import moment from 'moment'
import { bonificacaoService } from '../../services/bonificacao_service';
import { getLabelAnalise } from '../table/HeadRows';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  
  },
  form: {
      minHeigth: 400,
      maxHeigth: 400,
      minWidth:  400,
      maxWidth:  400
  },
  formControl: {
    minWidth: 120
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


class FormFolhaAnalise extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            analise_id: 0,
            selected: [],
            labelWidht: 0,
            elementos: [],
            analises: []
        }


        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)

        this.handleGetAnalisesFile = this.handleGetAnalisesFile.bind(this)
        this.handleGetElementos = this.handleGetElementos.bind(this)
    }

    // Necessario em todos os form abertos em ActionFormDialog
    action(){
      if(this.props.handleFormState){
          this.props.handleFormState(this.state)
      }
    }

    componentDidMount(){
      analiseService.getAnalisesFile({data_inicio: moment().subtract(12, 'months').format('YYYY-MM-DD'), 
      data_final: moment().format('YYYY-MM-DD'), status: 1}, this.handleGetAnalisesFile)
      bonificacaoService.getElementosBonificacao(this.handleGetElementos)
    }

    handleGetAnalisesFile(response){
      if(validateShowResponseToast(response, null, false)){
         this.setState({
            analises: response.data.data,
            analise_id: response.data.data[0].id
          })
      }
    }

    handleGetElementos(response){
      if(validateShowResponseToast(response, null, false)){
        const lSelected = response.data.filter(dt => {
          return (dt.descricao === 'ccs' || dt.descricao === 'ufc_curva' ||
            dt.descricao === 'proteina' || dt.descricao === 'gordura')
        })
        this.setState({
          elementos: response.data
        })
      }
    }
 
    handleChange(e, value) {
        let lSelected = this.state.selected
        if(e.target.checked){
            lSelected.push(value.id)
        }else{
            lSelected.pop(value.id)
        }
        this.setState({selected : lSelected})
     }

    handleSelectChange(e) {
        this.setState({
           analise_id: e.target.value,
        });
      }

    render(){
      const {classes} = this.props
      return (
        <form className={
            classes.form} 
            noValidate 
            onSubmit={e => { e.preventDefault()}}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel 
                    required
                    htmlFor="outlined-descricao-simple">
                    Selecione a Análise
                </InputLabel>
                <Select
                value={this.state.analise_id}
                onChange={this.handleSelectChange}
                label="Descrição"
                required
                fullWidth
                input={
                    <OutlinedInput
                      labelWidth={160}
                      name="descricao"
                      id="outlined-descricao-simple"
                    />
                  }
                >
                  {this.state.analises.map(analise => {
                    return (<MenuItem value={analise.id}>{analise.descricao}</MenuItem>)
                  })}
                </Select>
           </FormControl>
           <Typography style={{marginTop: 20}}>Selecione os elementos</Typography>
           {
               this.state.elementos.map(element => {
                return (
                    <ListItem>
                        <Checkbox
                            onChange={(e) => this.handleChange(e, element)}
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                        />
                        <ListItemText primary={`${getLabelAnalise(element.descricao)}`} />
                       
                    </ListItem>
                  );
               })
            }
         </form>
    )
        
    }
}

export default withStyles(styles)(FormFolhaAnalise)