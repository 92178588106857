import React from 'react'
import {
    Tooltip,
    Typography,
    Badge,
    IconButton
} from '@material-ui/core'
import {
    MonetizationOnRounded,
    CallMadeRounded,
    TrendingUpRounded,
    AccountBalanceWalletRounded,
    AssignmentRounded,
    ClearRounded,
    AssignmentTurnedInRounded,
    PrintRounded,
    BallotRounded,
    AttachMoneyRounded,
    SearchRounded,
    RemoveRedEyeRounded,
    VisibilityOffRounded,
    AddCircleOutlineRounded,
    RefreshRounded,
    ListRounded,
    DeleteRounded,
    DateRange,
    EditRounded
} from '@material-ui/icons'
import CurrencyFormat from 'react-currency-format'
import { headRowsFolha, headRowsFolhaSimple } from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import {
    makeActionFormDialog,
    closeProgressDialog,
    makeProgressDialog,
    makeAlertDialog,
    makeObjectListDialog,
} from '../dialogs/DialogRefInterface';
import moment from 'moment'
import Paginate from '../table/Paginate';
import { folhaService } from '../../services/folha_service';

import TableFolhaBonificacao from '../_bonificacao/TableFolhaBonificacao'
import TableFolhaValor from '../_valor/TableFolhaValor'
import TableFolhaPagamento from '../_pagamento/TableFolhaPagamento'
import TableFolhaColeta from '../_coleta/TableFolhaColeta'
import TableFolhaTaxa from '../_taxa/TableFolhaTaxa'
import FormFolhaValor from '../_valor/FormFolhaValor';
import FormFolhaTaxa from '../_taxa/FormFolhaTaxa';
import FormFolhaAnalise from '../_analise/FormFolhaAnalise';
import FormFolhaPagamento from '../_pagamento/FormFolhaPagamento';
import FilterFolha from './FilterFolha';
import FormFolha from './FormFolha';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import FormCloseFolha from './FormCloseFolha';
import { template } from '../templates_pdf/template_folha';
import { toast } from 'react-toastify';
import { laticinioService } from '../../services/laticinio_service';
import { analiseService } from '../../services/analise_service';
import { definicoesService } from '../../services/definicoes_service';
import FormEditFolha from './FormEditFolha'

const TABLE_FONT_SIZE = "12pt"
const MONEY_SIMBOL = "R$"


class TableFolha extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            paginate: new Paginate(this.handleForceUpdate),
            headRows: headRowsFolhaSimple,
            showBadge: false
        }
        this.objectTableRef = React.createRef();

        this.handleClickContColetas = this.handleClickContColetas.bind(this)
        this.handleClickContPagamentos = this.handleClickContPagamentos.bind(this)
        this.handleClickContTaxas = this.handleClickContTaxas.bind(this)
        this.handleClickContValores = this.handleClickContValores.bind(this)
        this.handleClickTotalBonificacao = this.handleClickTotalBonificacao.bind(this)

    }

    handleForceUpdate = () => {
        this.forceUpdate()
    }

    refresh = () => {
        makeProgressDialog().current.open()
        folhaService.getFolhas(this.state.paginate.lastSearch, this.state.paginate.handleGet, closeProgressDialog)
    }

    refreshOpened = () => {
        makeProgressDialog().current.open()
        this.state.paginate.lastSearch = { status_folha: 1, update: 1 }
        folhaService.getFolhas(this.state.paginate.lastSearch, this.state.paginate.handleGet, closeProgressDialog)
    }

    componentDidMount() {
        this.refreshOpened()
    }

    handleClickTotalBonificacao(event, data = {}) {
        event.stopPropagation()
        makeObjectListDialog(TableFolhaBonificacao, 'Bonificações', undefined, data, data.bonificacoes, (data) => {
            this.refresh()
        }).current.open()
    }

    handleClickContValores(event, data = {}) {
        event.stopPropagation()
        makeObjectListDialog(TableFolhaValor, 'Valores', undefined, data, data.folha_valores, (data) => {
            this.refresh()
        }).current.open()
    }

    handleClickContTaxas(event, data = {}) {
        event.stopPropagation()
        makeObjectListDialog(TableFolhaTaxa, "Taxas", undefined, data, data.folha_taxas, (data) => {
            this.refresh()
        }).current.open()
    }

    handleClickContPagamentos(event, data = {}) {
        event.stopPropagation()
        makeObjectListDialog(TableFolhaPagamento, "Pagamentos", undefined, data, data.pagamentos, (data) => {
            this.refresh();
        }).current.open()
    }

    handleClickContColetas(event, data = {}) {
        event.stopPropagation()
        makeObjectListDialog(TableFolhaColeta, "Coletas", undefined, data, data.folha_coletas, (_data) => {
            this.refresh()
        }).current.open()
    }

    actions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if (selected.status_folha === 1) {
                    makeActionFormDialog(FormEditFolha, "Editar Folha", null, selected, "Concluir", (data) => {
                        folhaService.updateFolhas({ data: [selected.id], ...data }, response => {
                            if (validateShowResponseToast(response)) {
                                this.refresh()
                            }
                        })
                    }).current.open()
                } else {
                    toast.warn("Folha está fechada!")
                }

            },
            color: 'secondary'
        },
    ]

    selectedToolActions = [
        {
            title: 'Adicionar Valor Impactante',
            label: 'Adicionar',
            MaterialIcon: CallMadeRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaValor, "Adicionar Valores", undefined, selected, "Salvar", (data) => {
                    makeProgressDialog().current.open()
                    folhaService.addValor({
                        folhas: selected, valor_impactante_id: data.valor_impactante_id,
                        valor: data.valor, multiplicador: data.multiplicador
                    }, (response) => {
                        if (validateShowResponseToast(response, null, false)) {
                            this.refresh()
                        } else {
                            closeProgressDialog()
                        }
                    })
                }).current.open()
            },
        },
        {
            title: 'Adicionar Taxa Impactante',
            label: 'Adicionar',
            MaterialIcon: TrendingUpRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaTaxa, "Adicionar Taxas ", undefined, selected, "Salvar", (data) => {
                    makeProgressDialog().current.open();
                    folhaService.addTaxa({ folhas: selected, taxas: data.selected }, (response) => {
                        if (validateShowResponseToast(response)) {
                            this.refresh();
                        } else {
                            closeProgressDialog()
                        }
                    })
                }).current.open()
            },
        },
        {
            title: 'Adicionar Análise',
            label: 'Adicionar',
            MaterialIcon: BallotRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaAnalise, "Adicionar Análise", undefined, undefined, "Salvar", (data) => {
                    makeProgressDialog().current.open()
                    const sendData = { analise_file_id: data.analise_id, folhas: selected, elementos: data.selected }
                    folhaService.addBonificacoes(sendData, (response) => {
                        if (validateShowResponseToast(response)) {
                            this.refresh();
                        } else {
                            closeProgressDialog()
                        }
                    })
                }).current.open()
            },
        },
        {
            title: 'Adicionar Pagamento',
            label: 'Adicionar',
            MaterialIcon: AttachMoneyRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFolhaPagamento, "Adicionar Pagamento", null, selected, "Concluir", (data) => {
                    folhaService.addPagamento({
                        folhas: selected, 
                        restante: data.restante,
                        valor: data.valor, 
                        tipo_pagamento_id: data.tipo_pagamento_id
                    }, (response) => {
                        if (validateShowResponseToast(response)) {
                            this.refresh()
                        }
                    })
                }).current.open()

            },
        },
        {
            title: "Definir Fechamento",
            color: 'primary',
            MaterialIcon: DateRange,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormCloseFolha, "Pré-visualizar folha", null, null, "Concluir", (data) => {
                    folhaService.closeFolhas({ folhas: selected, data_final: data.data_final, close: false }, (response) => {
                        if (validateShowResponseToast(response)) {
                            this.state.paginate.lastSearch = {
                                status: -1, update: 1,
                                data_inicial: this.state.paginate.rows[0].data_inicial,
                                data_final: data.data_final
                            }
                            this.refresh()
                        }
                    })
                }).current.open()
            },
        },
        {
            title: 'Imprimir Folhas',
            label: 'Adicionar',
            MaterialIcon: PrintRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    let folhaAberta = false;
                    const folhas = this.state.paginate.rows.filter(row => {
                        return selected.indexOf(row.id) > -1
                    })
                    if (folhaAberta) {
                        toast.warn("Falha ao imprimir! Uma das folhas selecionadas ainda está aberta!")
                    } else {
                        const centroProdutores = folhas.map(folha => {
                            return folha.centro_produtor.numero
                        })
                        makeProgressDialog(null, 'Imprimindo Folhas').current.open()
                        analiseService.getAllAnalises({ data: centroProdutores, limit: 3 }, (analiseResponse) => {
                            const analises = analiseResponse.data
                            if (validateShowResponseToast(analiseResponse, "", false)) {
                                for (let i = 0; i < folhas.length; i++) {
                                    folhas[i].analises = analises[i]
                                }
                                laticinioService.getLaticinio((response) => {
                                    if (validateShowResponseToast(response, "", false)) {
                                        analiseService.getDefaultBonificacao([], (bonificacaoResponse) => {
                                            template(folhas,
                                                {
                                                    headerTitle: response.data.nome,
                                                    subtitle: `${response.data.cidade} - ${response.data.estado}`
                                                },
                                                bonificacaoResponse.data).save(`folhas_${moment().format('DD_MM_YYYY')}`)
                                        })
                                        closeProgressDialog()
                                    } else {
                                        closeProgressDialog()
                                    }
                                })
                            } else {
                                closeProgressDialog()
                            }
                        }) // Abre o progress dialog no primeiro request
                    }
                },
                    'Impressão',
                    "Deseja Imprimir todas as Folhas selecionadas?").current.open()
            },
        },
        {
            title: 'Fechar Folhas Selecionadas',
            label: 'Fechar',
            MaterialIcon: AssignmentTurnedInRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    definicoesService.getDefinicoes(response => {
                        const ultimaAtualizacao = response.data.ultima_atualizacao
                        if (moment(ultimaAtualizacao).format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY") ) {
                            toast.warn('Atualize as coletas antes de fechar as folhas e verifique se a data e hora do seu computador está correta!')
                        } else {
                            makeActionFormDialog(FormCloseFolha, "Fechar Folhas", null, null, "Concluir", (data) => {
                                folhaService.closeFolhas({ folhas: selected, data_final: data.data_final, close: true }, (response) => {
                                    if (validateShowResponseToast(response)) {
                                        this.state.paginate.lastSearch = {
                                            status: 1,
                                            data_inicial: this.state.paginate.rows[0].data_inicial,
                                            data_final: data.data_final
                                        }
                                        this.refresh()
                                    }
                                })
                            }).current.open()
                        }
                    })
                }, 'Fechar Folhas', "Deseja fechar todas as Folhas selecionadas?").current.open()
            },
        },
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selecteds) => {
                e.stopPropagation()
                makeActionFormDialog(FormEditFolha, "Editar Folha", null, selecteds, "Concluir", (data) => {
                    folhaService.updateFolhas({ data: selecteds, ...data }, response => {
                        if (validateShowResponseToast(response)) {
                            this.refresh()
                        }
                    })
                }).current.open()
            },
            color: 'secondary'
        },
        {
            title: 'Excluir',
            label: 'Excluir',
            MaterialIcon: DeleteRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeAlertDialog(() => {
                    folhaService.deleteFolhas({ folhas: selected }, (response) => {
                        if (validateShowResponseToast(response)) {
                            this.refresh()
                        }
                    })
                },
                    'Excluir Folhas',
                    "Deseja realmente excluir as folhas selecionadas? Esta operação só poderá ser revertida contatando o suporte!").current.open()
            },
            color: 'secondary'
        },
        {
            title: 'Limpar Seleção',
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({ selected: [] })
            },
            color: 'secondary'
        },
    ]

    toolActions() {
        return [
            {
                title: 'Buscar Folhas',
                label: 'Buscar',
                MaterialIcon: SearchRounded,
                handleClick: (e, selected) => {
                    e.stopPropagation()
                    makeActionFormDialog(FilterFolha, "Filtro de Folhas", undefined, [], "Filtrar", data => {
                        makeProgressDialog(undefined, "Atualizando...").current.open()
                        this.state.paginate.lastSearch = data
                        folhaService.getFolhas(data, this.state.paginate.handleGet, closeProgressDialog)
                    }).current.open()
                },
            },

            {
                title: 'Atualizar',
                label: 'Atualizar',
                MaterialIcon: RefreshRounded,
                handleClick: (e, selected) => {
                    e.stopPropagation()
                    this.refreshOpened()
                },
            },

            {
                title: this.state.showBadge ? "Esconder Números" : "Mostrar Números",
                label: 'Mostra badges',
                MaterialIcon: this.state.showBadge ? VisibilityOffRounded : RemoveRedEyeRounded,
                handleClick: (e, selected) => {
                    e.stopPropagation()
                    this.setState({
                        showBadge: !this.state.showBadge
                    })
                },
            },

            {
                title: "Expandir",
                label: 'Expandir',
                MaterialIcon: ListRounded,
                handleClick: (e, selected) => {
                    e.stopPropagation()
                    if (this.state.headRows == headRowsFolha) {
                        this.setState({
                            headRows: headRowsFolhaSimple,
                        })
                    } else {
                        this.setState({
                            headRows: headRowsFolha,
                        })
                    }
                },
            },
            {
                title: 'Abrir Folha de Pagamento',
                label: 'Adicionar',
                MaterialIcon: AddCircleOutlineRounded,
                handleClick: (e, selected) => {
                    e.stopPropagation()
                    makeActionFormDialog(FormFolha, "Abrir Folhas", undefined, undefined, "Abrir Folhas", (data) => {
                        makeProgressDialog().current.open();
                        folhaService.getFolhas({ status: 1 }, this.state.paginate.handleGet, () => {
                            this.refresh()
                        })
                    }).current.open()
                },
            },
        ]
    }

    custom_head_rows = [
        {
            id: 'status_folha', numeric: true, disablePadding: false, label: 'Status Folha', type: 'custom',
            handleCustom: (value) => {
                if (value == 1) return (<Typography style={{ fontSize: TABLE_FONT_SIZE, color: 'green' }}>ABERTA</Typography>)
                else if (value == 0) return (<Typography style={{ fontSize: TABLE_FONT_SIZE, color: 'red' }}>FECHADA</Typography>)
            }
        },
        {
            id: 'coleta', numeric: false, disablePadding: false, label: 'Total Litros', type: 'custom_row',
            handleCustom: (row) => {
                return (
                    <Tooltip title="Listar Coletas">
                        <Badge badgeContent={this.state.showBadge ? row.cont_coletas : 0} color="secondary">
                            <IconButton onClick={e =>
                                this.handleClickContColetas(e, row)}>
                                <AssignmentRounded color='primary' />
                                <Typography style={{ fontSize: TABLE_FONT_SIZE }}>{row.total_litro}</Typography>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                )
            }
        },

        {
            id: 'bonificacao', numeric: false, disablePadding: false, label: 'Listar Bonificações', type: 'custom_row',
            handleCustom: (row) => {
                return (
                    <Tooltip title="Listar Bonificacões">
                        <Badge badgeContent={this.state.showBadge ? row.cont_bonificacoes : 0} color="secondary">
                            <IconButton onClick={e =>
                                this.handleClickTotalBonificacao(e, row)}>
                                <MonetizationOnRounded color='primary' />
                                <Typography style={{ fontSize: TABLE_FONT_SIZE, color: row.total_bonificacao < 0 ? "red" : "green" }}>
                                    <CurrencyFormat
                                        value={row.total_bonificacao || 0}
                                        displayType={'text'}
                                        prefix={MONEY_SIMBOL}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                )
            }
        },
        {
            id: 'valor_impactante', numeric: false, disablePadding: false, label: 'Valores Impactantes', type: 'custom_row',
            handleCustom: (row) => {
                return (
                    <Tooltip title="Listar Valores">
                        <Badge badgeContent={this.state.showBadge ? row.cont_valores : 0} color="secondary">
                            <IconButton
                                onClick={e =>
                                    this.handleClickContValores(e, row)
                                }>
                                <CallMadeRounded color='primary' />
                                <Typography style={{ fontSize: TABLE_FONT_SIZE, color: row.total_valor < 0 ? "red" : "green" }}>
                                    <CurrencyFormat
                                        value={row.total_valor || 0}
                                        displayType={'text'}
                                        prefix={MONEY_SIMBOL}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        fixedDecimalScale />
                                </Typography>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                )
            }
        },
        {
            id: 'taxa_impactante', numeric: false, disablePadding: false, label: 'Taxas Impactantes', type: 'custom_row',
            handleCustom: (row) => {
                return (
                    <Tooltip title="Listar Taxas">
                        <Badge badgeContent={this.state.showBadge ? row.cont_taxas : 0} color="secondary">
                            <IconButton onClick={e =>
                                this.handleClickContTaxas(e, row)}>
                                <TrendingUpRounded color='primary' />
                                <Typography style={{ fontSize: TABLE_FONT_SIZE, color: row.total_taxa < 0 ? "red" : "green" }}>
                                    <CurrencyFormat
                                        value={row.total_taxa || 0}
                                        displayType={'text'}
                                        prefix={MONEY_SIMBOL}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        fixedDecimalScale />
                                </Typography>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                )
            }
        },
        {
            id: 'pagamento', numeric: false, disablePadding: false, label: 'Valor Pago', type: 'custom_row',
            handleCustom: (row) => {
                return (
                    <Tooltip title="Listar Pagamentos">
                        <Badge badgeContent={this.state.showBadge ? row.cont_pagamentos : 0} color="secondary">
                            <IconButton onClick={e =>
                                this.handleClickContPagamentos(e, row)}>
                                <AccountBalanceWalletRounded color='primary' />
                                <Typography style={{ fontSize: TABLE_FONT_SIZE, color: row.valor_pago < 0 ? "red" : "green" }}>
                                    <CurrencyFormat
                                        value={row.valor_pago || 0}
                                        displayType={'text'}
                                        prefix={MONEY_SIMBOL}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        fixedDecimalScale />
                                </Typography>
                            </IconButton>
                        </Badge>
                    </Tooltip>
                )
            }
        }
        ,
    ];

    render() {
        return (
            <ObjectTable
                ref={this.objectTableRef}
                tableName='Folhas de Pagamento'
                cellTitle='produtor'
                rowId='id'
                headRows={(this.state.headRows || []).concat(this.custom_head_rows)}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions()}
                rowSize={60}
                rowsOptions={[20, 40, 60]}

                rows={this.state.paginate.rows}
                count={this.state.paginate.count}
                handleLoadPage={this.state.paginate.handleLoadPage}
                perPage={this.state.paginate.perPage}
                from={this.state.paginate.from}
                to={this.state.paginate.to}
            />
        )
    }
}

export default TableFolha