import React from "react";
import {
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import moment from "moment";
import "moment/locale/pt-br";
import CustomDatePicker from "../default/CustomDatePicker";

class FilterRelatorioColeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mes: "",
      quinzena: 0,
      data_inicial: moment().subtract(30, "days").format("YYYY-MM-DD"),
      data_final: moment().format(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.handleChangeQuinzena = this.handleChangeQuinzena.bind(this);
  }

  action() {
    if (this.props.handleFormState) this.props.handleFormState(this.state);
  }

  handleAction(e) {
    if (e.key == "Enter") {
      this.action();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeMonth(e) {
    let year = moment().format("YYYY");
    if (Number(moment().format("MM")) < e.target.value) {
      year -= 1;
    }
    let iDate = `${year}-${moment(e.target.value, "MM").format("MM")}-01`;
    let fDate = `${year}-${moment(e.target.value, "MM").format("MM")}-15`;
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
      quinzena: 1,
      data_inicial: iDate,
      data_final: fDate,
    });
  }

  handleChangeQuinzena(e) {
    const { name, value } = e.target;

    let year = moment().format("YYYY");
    if (Number(moment().format("MM")) < e.target.value) {
      year -= 1;
    }

    let iDate = `${year}-${moment(this.state.mes, "MM").format(
      "MM"
    )}-01`;
    let fDate = `${year}-${moment(this.state.mes, "MM").format(
      "MM"
    )}-15`;

    // segunda quinzena
    if (value === 2) {
      iDate = `${year}-${moment(this.state.mes, "MM").format(
        "MM"
      )}-16`;
      fDate = `${year}-${moment(this.state.mes, "MM").format(
        "MM"
      )}-${moment(iDate).endOf("month").format("DD")}`;
    }

    this.setState({
      ...this.state,
      [name]: value,
      data_inicial: iDate,
      data_final: fDate,
    });
  }

  render() {
    const style = { marginBottom: 20, minWidth: 150 };
    return (
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <form
          className={{ display: "flex", flexWrap: "wrap" }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Container style={{ marginTop: 20 }}>
            <InputLabel month>Mês do Relatório</InputLabel>
            <Select
              value={this.state.mes}
              onChange={this.handleChangeMonth}
              fullWidth
              name="mes"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((mes) => {
                return (
                  <MenuItem value={mes}>
                    {moment(mes, "MM").locale("pt-BR").format("MMMM")}
                  </MenuItem>
                );
              })}
            </Select>
          </Container>
          <Container style={{ marginTop: 20 }}>
            <InputLabel month>Quinzena do Relatório</InputLabel>
            <Select
              value={this.state.quinzena}
              onChange={this.handleChangeQuinzena}
              disabled={this.state.mes === ""}
              fullWidth
              name="quinzena"
            >
              <MenuItem value={1}>Primeira quinzena</MenuItem>
              <MenuItem value={2}>Segunda quinzena</MenuItem>
            </Select>
          </Container>
          <Container style={{ marginTop: 20 }}>
            <CustomDatePicker
              disableToolbar
              fullWidth
              label="Data Inicial"
              variant="inline"
              format="DD/MM/YYYY"
              name="data_inicial"
              value={this.state.data_inicial}
              onChange={this.handleChange}
            />
          </Container>
          <Container style={{ marginTop: 20 }}>
            <CustomDatePicker
              disableToolbar
              fullWidth
              label="Data Final"
              variant="inline"
              format="DD/MM/YYYY"
              name="data_final"
              value={this.state.data_final}
              onChange={this.handleChange}
            />
          </Container>
        </form>
      </Grid>
    );
  }
}

export default FilterRelatorioColeta;
