import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppSharp from "@material-ui/icons/ExitToAppSharp";
import { withRouter } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";

import moment from "moment";
import { validateShowResponseToast } from "../../helpers/toast_alerts";

import Tooltip from "@material-ui/core/Tooltip";
import ProgressDialog from "../dialogs/ProgressDialog";
import ObjectListDialog from "../dialogs/ObjectListDialog";
import {
  makeAlertDialog,
  makeProgressDialog,
  makeObjectListDialog,
  makeActionFormDialog,
  closeProgressDialog,
} from "../dialogs/DialogRefInterface";
import { MainListItems } from "./ListItems";
import TableFolha from "../_folha/TableFolha";
import Definicao from "../_definicoes/Definicao";

import { userService } from "../../services/user_service";
import { createBrowserHistory } from "history";
import ActionFormDialog from "../dialogs/ActionFormDialog";
import TableProdutor from "../_produtor/TableProdutor";
import TableColeta from "../_coleta/TableColeta";
import TableUsuario from "../_usuario/TableUsuario";
import { CloudDownloadOutlined } from "@material-ui/icons";
import TableValorImpactante from "../_valor/TableValorImpactante";
import TableTaxaImpactante from "../_taxa/TableTaxaImpactante";
import TableFatorBonificacao from "../_bonificacao/TableFatorBonificacao";
import TablePrecoBase from "../_preco_base/TablePrecoBase";

import { coletaService } from "../../services/coleta_service";
import { definicoesService } from "../../services/definicoes_service";
import TableAnaliseFile from "../_analise/TableAnaliseFile";
import TableMeioPagamento from "../_pagamento/TableMeioPagamento";
import TableContabil from "../_relatorio/TableContabil";
import TableRelatorioAnalise from "../_relatorio/TableRelatorioAnalise";
import Main from "./Main";
import TableMapaColeta from "../_relatorio/TableMapaColeta";

const drawerWidth = 240;
const browserHistory = createBrowserHistory();
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  tooltip: {
    color: "#f1f1f1",
    rippleBackgroundColor: "blue",
  },
}));

function NotFoundPage() {
  return <h2> 404 - Página Não Encontrada!</h2>;
}

function Folhas() {
  return <TableFolha></TableFolha>;
}

function Produtores() {
  return <TableProdutor></TableProdutor>;
}

function Coletas() {
  return <TableColeta></TableColeta>;
}

function Bonificacoes() {
  return <TableFatorBonificacao></TableFatorBonificacao>;
}

function Valores() {
  return <TableValorImpactante></TableValorImpactante>;
}

function Taxas() {
  return <TableTaxaImpactante></TableTaxaImpactante>;
}

function Usuarios() {
  return <TableUsuario></TableUsuario>;
}

function Precos() {
  return <TablePrecoBase></TablePrecoBase>;
}

function Definicoes() {
  return <Definicao></Definicao>;
}

function Analises() {
  return <TableAnaliseFile></TableAnaliseFile>;
}

function MeiosPagamento() {
  return <TableMeioPagamento></TableMeioPagamento>;
}

function RelatorioContabil() {
  return <TableContabil></TableContabil>;
}

function RelatorioAnalise() {
  return <TableRelatorioAnalise></TableRelatorioAnalise>;
}

function RelatorioRecebimento() {
  return <TableMapaColeta />;
}

function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [ultimaAtualizacao, setUltimaAtualizacao] = React.useState();
  const [urlColetas, setUrlColetas] = React.useState(undefined);

  function handleUltimaAtualizacao(response) {
    if (!response) return;
    if (response.status === 200) {
      if (response.data.ultima_atualizacao) {
        setUltimaAtualizacao(response.data.ultima_atualizacao);
      } else {
        let dataAtual = moment().format("YYYY-MM-DD");
        setUltimaAtualizacao(dataAtual);
        definicoesService.updateDefinicoes();
      }
    }
  }

  function handleResponseUrls(response) {
    if (validateShowResponseToast(response, undefined, false, true)) {
      setUrlColetas(response.data[0].coleta_url);
    }
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleAgree(event) {
    userService.logout();
    props.history.push("/");
  }

  function handleShowStatusUpdate(response) {
    let salvos = 0;
    let total = 0;
    if (response) {
      salvos = response.data ? response.data.salvos || 0 : 0;
      total = response.data ? response.data.total_registros || 0 : 0;
    }
    if (
      validateShowResponseToast(
        response,
        undefined,
        undefined,
        undefined,
        `${salvos} Coletas Atualizadas de ${total}`
      )
    ) {
      definicoesService.updateDefinicoes({});
    }
  }

  function handleShowUltimaAtualizacao(e) {
    definicoesService.getDefinicoes(handleUltimaAtualizacao);
    definicoesService.getUrlDados(handleResponseUrls);
  }

  function handleUpdateData(event, lastDate = undefined) {
    let dataAtual = moment().format("YYYY-MM-DD");
    let url = urlColetas
      ? urlColetas
          .replace(
            "{}",
            moment(lastDate ? lastDate : ultimaAtualizacao)
              .subtract(30, "days")
              .format("YYYY-MM-DD")
          )
          .replace("{}", dataAtual)
      : "";
    coletaService.uploadColetas(
      url,
      handleShowStatusUpdate,
      closeProgressDialog
    );
    makeProgressDialog(
      (e) => console.log("finalizado"),
      "Buscando Dados! Aguarde..."
    ).current.open();
  }

  const handleLogout = () => {
    makeAlertDialog(
      handleAgree,
      "Atenção",
      "Deseja realmente sair da aplicação"
    ).current.open();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Folha de Pagamento
          </Typography>

          <Tooltip
            title={
              <h3>
                {ultimaAtualizacao
                  ? `Ultima Atualização em: ${moment(ultimaAtualizacao).format(
                      "DD/MM/YYYY"
                    )}`
                  : "Atualizar Dados"}
              </h3>
            }
          >
            <IconButton
              color="inherit"
              onClick={handleUpdateData}
              onMouseEnter={handleShowUltimaAtualizacao}
            >
              <Badge badgeContent={0} color="secondary">
                <CloudDownloadOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title={<h3>Sair da Aplicação</h3>}>
            <IconButton color="inherit" onClick={handleLogout}>
              <ExitToAppSharp />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{MainListItems(browserHistory)}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <ProgressDialog ref={makeProgressDialog()} />
          <ObjectListDialog ref={makeObjectListDialog()} />
          <ActionFormDialog ref={makeActionFormDialog()} />
          <Router history={browserHistory}>
            <Switch>
              <Route exact path="/dashboard" component={Main} />
              <Route exact path="/dashboard/folhas" component={Folhas} />
              <Route
                exact
                path="/dashboard/produtores"
                component={Produtores}
              />
              <Route exact path="/dashboard/coletas" component={Coletas} />
              <Route
                exact
                path="/dashboard/definicoes"
                component={Definicoes}
              />
              <Route
                exact
                path="/dashboard/bonificacoes"
                component={Bonificacoes}
              />
              <Route exact path="/dashboard/usuarios" component={Usuarios} />
              <Route exact path="/dashboard/valores" component={Valores} />
              <Route exact path="/dashboard/taxas" component={Taxas} />
              <Route exact path="/dashboard/precos" component={Precos} />
              <Route exact path="/dashboard/analises" component={Analises} />
              <Route
                exact
                path="/dashboard/pagamentos"
                component={MeiosPagamento}
              />
              <Route
                exact
                path="/dashboard/relatorio/contabil"
                component={RelatorioContabil}
              />
              <Route
                exact
                path="/dashboard/relatorio/analise"
                component={RelatorioAnalise}
              />
              <Route
                exact
                path="/dashboard/relatorio/recebimentos"
                component={RelatorioRecebimento}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Dashboard);
