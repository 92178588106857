
import React from 'react'
import { 
    Container,
    Grid,
    TextField
} from '@material-ui/core'
import { getLabelAnalise } from '../table/HeadRows';


class FormConfirmationAnalise extends React.Component{
    constructor(props){
        super(props)
        this.state = {
           data: this.props.data
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
    }

    componentDidMount(){
        if(this.state.data.length == 0){
            this.setState({
                data: [
                    {elemento: 'gordura', simbolo: '>', valor: 3.4},
                    {elemento: 'proteina', simbolo: '>', valor: 3.2},
                    {elemento: 'esd', simbolo: '>', valor: 8.4},
                    {elemento: 'ccs', simbolo: '<', valor: 500},
                    {elemento: 'ufc_curva', simbolo: '<', valor: 300},
                ]
            })
        }
    }

    action(){
        if(this.props.handleFormState) this.props.handleFormState(this.state)
    }

    handleAction(e){
        if(e.key == 'Enter'){
            this.action()
        }
    }
    
    handleChange(e, dt) {
        dt.valor = e.target.value;
        this.forceUpdate()
     }

    render(){
        const style={  marginBottom: 20, minWidth: 150}
        return (
            <Grid item xs={12}   style={{marginTop: '20px'}}>
                    <form className={{display: 'flex',flexWrap: 'wrap',}}
                     noValidate 
                     onSubmit={e => { e.preventDefault()}}
                     >
                    {this.state.data.map(dt => {
                        return <Container style={{marginTop: 20}}>
                            <TextField
                                id={dt.elemento}
                                label={`${getLabelAnalise(dt.elemento)} ${dt.simbolo}`}
                                name={dt.elemento}
                                fullWidth
                                type="number"
                                value={dt.valor}
                                onChange={(e) => {this.handleChange(e, dt)}}>
                            </TextField>
                        </Container>
                    })}
                    </form>
            </Grid>
        )
        
    }
}

export default (FormConfirmationAnalise)
                
                

              
                