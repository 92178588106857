import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppSharp from '@material-ui/icons/ExitToAppSharp'
import { withRouter } from "react-router-dom";
import { Router, Route, Switch} from "react-router-dom";

import Tooltip from '@material-ui/core/Tooltip'
import AlertDialog from '../dialogs/AlertDialog'
import ProgressDialog from '../dialogs/ProgressDialog'
import ObjectListDialog from '../dialogs/ObjectListDialog'
import {
  makeAlertDialog, 
  makeProgressDialog,
  makeObjectListDialog, 
  makeActionFormDialog,
} from "../dialogs/DialogRefInterface"
import {MainListItems} from './ListItemsAdmin'
import { createBrowserHistory } from 'history'
import ActionFormDialog from '../dialogs/ActionFormDialog'
import { adminService } from '../../services/admin_service';
import TableAdminLaticinio from './_laticinio/TableAdminLaticinio';


const drawerWidth = 240;
const browserHistory = createBrowserHistory()
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tooltip: {
    color: '#f1f1f1',
    rippleBackgroundColor: 'blue'
  },
}));


function NotFoundPage() {
  return <h2> 404 - Página Não Encontrada!</h2>
}


function DashboardAdmin(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)

  function Main(){
    return <h2> Main </h2>
  }

  function Laticinios(){
    return <TableAdminLaticinio></TableAdminLaticinio>
  }

  function handleDrawerOpen(){
    setOpen(true);
  }

  function handleDrawerClose(){
    setOpen(false);
  }

  function handleAgree(event){
      adminService.logout()
      props.history.push("/admin")
  }


  const handleLogout = () =>{
    makeAlertDialog(handleAgree, "Atenção", "Deseja realmente sair da aplicação").current.open()
  }

  return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Painel de Administrador
            </Typography>
         
            <Tooltip 
              title={(<h3>Sair da Aplicação</h3>)}
            >
              <IconButton color="inherit" onClick={handleLogout}>
                <ExitToAppSharp/>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{MainListItems(browserHistory)}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
          <AlertDialog ref={makeAlertDialog()}/>
          <ProgressDialog ref={makeProgressDialog()}/>
          <ObjectListDialog ref={makeObjectListDialog()}/>
          <ActionFormDialog ref={makeActionFormDialog()}/>
            <Router history={browserHistory}>
              <Switch>
                <Route exact path='/admin/dashboard' component={Main} />
                <Route exact path='/admin/dashboard/laticinios' component={Laticinios} />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
          </Container>
        </main>
    </div>
  );
}

export default withRouter(DashboardAdmin)