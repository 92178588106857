// Produtor,Filial,Nome Produtor,Recepção,Cod.Recepção,Cod.Setor,Nome Setor,Dt.Result,Amostra1,CQAm1,Gordura,Proteína,Lactose,Sólidos,CCS,ESD,Amostra2,CQAm2,UFC Curva,CQAm3,Crioscopia,Protocolo,Criosc,Caseina,Ureia
import string_similarity from 'string-similarity'

function validateNumber(value){
    try{
        const numberValue = Number(value)
        if(numberValue === 'NaN') return 0
        else return numberValue
    }catch(error){
        return 0
    }
}

function validateDefault(value){
    return value
}

const analiseCols = [
    { attr: 'produtor', desc: ['PRODUTOR'], validate: validateNumber },
    { attr: 'filial', desc: ['Filial'], validate: validateNumber },
    { attr: 'cpf_produtor', desc: ['CPF/CNPJ'], validate: validateDefault},
    { attr: 'recepcao', desc: ['Recepção'], validate: validateNumber  },
    { attr: 'cod_recepcao', desc: ['Cod.Recepção'], validate: validateNumber },
    { attr: 'cod_setor', desc: ['Cod.Setor'], validate: validateNumber },
    { attr: 'nome_setor', desc: ['Nome Setor'], validate: validateNumber },
    { attr: 'data_resultado', desc: ['DATA.Analise'], validate: validateDefault},
    { attr: 'amostra1', desc: ['Amostra1'], validate: validateNumber },
    { attr: 'cqam1', desc: ['CQAm1'], validate: validateNumber },
    { attr: 'gordura', desc: ['%GORD'], validate: validateNumber },
    { attr: 'proteina', desc: ['%PROT'], validate: validateNumber },
    { attr: 'lactose', desc: ['%LAC'], validate: validateNumber },
    { attr: 'solidos', desc: ['%SOL'], validate: validateNumber },
    { attr: 'ccs', desc: ['CCS'], validate: validateNumber },
    { attr: 'esd', desc: ['ESD'], validate: validateNumber },
    { attr: 'amostra2', desc: ['Amostra2'], validate: validateNumber },
    { attr: 'cqam2', desc: ['CQAm2'], validate: validateNumber },
    { attr: 'ufc_curva', desc: ['C.B.T'], validate: validateNumber },
    { attr: 'cqam3', desc: ['CQAm3'], validate: validateNumber },
    { attr: 'crioscopia', desc: ['Crioscopia'], validate: (v) => v * -1},
    { attr: 'protocolo', desc: ['Protocolo'], validate: validateNumber },
    { attr: 'criosc', desc: ['Criosc'], validate: validateNumber},
    { attr: 'caseina', desc: ['CASEINA'], validate: validateNumber },
    { attr: 'urea', desc: ['UREIA', 'ur?ia'], validate: validateNumber },
]

export function makeAnaliseByDescription(values, pCols){
    let analiseResult = {}
    analiseCols.map(col => {
        let cont = 0
        analiseResult[col.attr] = 0
        pCols.map(pCol => {
            col.desc.map(cDesc => {
                if(string_similarity.compareTwoStrings(pCol.toLowerCase(), cDesc.toLowerCase()) > 0.83){
                    analiseResult[col.attr] = col.validate(values[cont])
                }
            })
            cont++
        })
    })
    Object.keys(analiseResult).map(key => {
        console.log(key)
        if(analiseResult[key] === 'NaN'){
            analiseResult[key] = 0
        }
    })
    return analiseResult
}


/* DEPRECATED
export function makeAnaliseFromTxt(produtor, filial, nome_produtor, recepcao, cod_recepcao,
    cod_setor, nome_setor, data_resultado, amostra1, cqam1, gordura, proteina, lactose, solidos,
    ccs, esd, amostra2, cqam2, ufc_curva, cqam3, crioscopia, protocolo, criosc, caseina, urea){
    const analise = {
        produtor, 
        filial: Number(filial), 
        nome_produtor, 
        recepcao : Number(recepcao), 
        cod_recepcao : Number(cod_recepcao),
        cod_setor : Number(cod_setor), 
        nome_setor, 
        data_resultado, 
        amostra1 : Number(amostra1), 
        cqam1 : Number(cqam1), 
        gordura : Number(gordura), 
        proteina : Number(proteina), 
        lactose : Number(lactose), 
        solidos : Number(solidos),
        ccs : Number(ccs), 
        esd : Number(esd), 
        amostra2 : Number(amostra2), 
        cqam2 : Number(cqam2), 
        ufc_curva : Number(ufc_curva), 
        cqam3 : Number(cqam3), 
        crioscopia : Number(crioscopia),
        protocolo : Number(protocolo), 
        criosc : Number(criosc),
        caseina: Number(caseina), 
        urea : Number(urea)
    }
}
*/

