import React from 'react'
import {NotInterestedRounded,  EditRounded, AddCircleOutlineRounded, ClearRounded} from '@material-ui/icons'

import {headRowsTaxaImpactantes} from '../table/HeadRows'
import ObjectTable from '../table/ObjectTable'
import { taxaService } from '../../services/taxa_service';
import { makeActionFormDialog } from '../dialogs/DialogRefInterface';
import FormTaxaImpactante from './FormTaxaImpactante';
import { validateShowResponseToast } from '../../helpers/toast_alerts'




class TableTaxaImpactante extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: []
        }

        this.handleCreateResponse = this.handleCreateResponse.bind(this)
        this.handleGetResponse = this.handleGetResponse.bind(this)
        this.handleUpdateResponse = this.handleUpdateResponse.bind(this)

        this.objectTableRef = React.createRef()
    }

    componentDidMount(){
       taxaService.getTaxas(this.handleGetResponse)
    }

    handleGetResponse(response){
        if(validateShowResponseToast(response, '', false, true)){
            this.setState({
                rows: response.data
            })
        }
    }

    handleCreateResponse(response){
        if(validateShowResponseToast(response, 'Taxa Impactante')){
            taxaService.getTaxas(this.handleGetResponse)
        }
    }

    handleUpdateResponse(response){
        if(validateShowResponseToast(response, 'Taxa Impactante')){
            taxaService.getTaxas(this.handleGetResponse)
        }
    }

    actions = [
        {
            title: 'Editar',
            label: 'Editar',
            MaterialIcon: EditRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormTaxaImpactante, "Editar Taxa Impactante", undefined, selected, "Salvar", (data) => {
                    taxaService.updateTaxas(data, this.handleUpdateResponse)
                }).current.open()
            }
        },
    ]
    
    selectedToolActions = [
        {
            title: 'Limpar Seleção', 
            label: 'Adicionar',
            MaterialIcon: ClearRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.objectTableRef.current.updateTable({selected: []})
            },
            color: 'secondary'
        }, 
    ] 
    
    toolActions = [
        {
            title: 'Adicionar', 
            label: 'Adicionar',
            MaterialIcon: AddCircleOutlineRounded,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormTaxaImpactante, "Nova Taxa", undefined, undefined, "Salvar", (data) => {
                    taxaService.createTaxas(data, this.handleCreateResponse)
                }).current.open()
            }
        }
    ]

    render(){
        return(
            <ObjectTable 
                tableName='Taxas Impactantes' 
                headRows={headRowsTaxaImpactantes} 
                rows={this.state.rows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowId='id'
                ref={this.objectTableRef}/>
        )
    }
}

export default TableTaxaImpactante