import axios from 'axios'
import { authHeader } from '../helpers/auth_header'
import { BASE_URL } from '../constants/urls'

export const getAxiosInstance = (url, auth=authHeader().Authorization) =>{
    return axios.create({
        baseURL: BASE_URL+url,
        headers: {
        Authorization: auth,
        'Content-Type': 'application/json'
        }
    });
}


export async function getPerPage(pageUrl, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance().get(pageUrl).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}